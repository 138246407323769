import React from 'react';
import { styled } from '@mui/material/styles';
import { FormControl, Grid, Box, Paper, InputLabel, NativeSelect, FormHelperText } from '@mui/material';
import { Field, ErrorMessage } from 'formik';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));
export const CategoryInfo = ({ errors, touched }) => (
  <Grid>
    <Item>
      <Grid item xs={12}>
        <FormControl
          variant="standard"
          style={{ width: '100%' }}
          error={Boolean(errors.businessCategory && touched.businessCategory)}
        >
          <Field name="businessCategory" as={NativeSelect} inputProps={{ id: 'business-category' }}>
            <option value="0" disabled>Choose a Business category where you want to deploy your POS</option>
            <option value="1">Supermarket / General Shop</option>
            <option value="2">Pharmacy</option>
            <option value="3">Restaurant / Hotel / Bar</option>
          </Field>
          <ErrorMessage name="businessCategory" component={FormHelperText} />
        </FormControl>
      </Grid>
    </Item>
  </Grid>
);
