import React, { useState, useEffect } from 'react';
import {Stack,Button ,FormControl,InputAdornment,Autocomplete,FormHelperText,
    TextField ,ToggleButton, ToggleButtonGroup, Container, Divider, Box, Grid, Paper, Card, CardContent, CardMedia, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { keyframes } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import PinDropIcon from '@mui/icons-material/PinDrop';
import { Formik, Field, Form, ErrorMessage, useField } from "formik";
import * as Yup from "yup";



const CAutocompleteField = ({ data = [], icon: Icon, ...props }) => {
    const [field, meta, helpers] = useField(props.name);
    const optionData = [{ id: 0, name: '' }, ...data];
    return (
      <FormControl
        variant="standard"
        style={{ width: '100%' }}
        error={Boolean(meta.touched && meta.error)}
      >
        <Autocomplete
          {...props}
          options={optionData}
          getOptionLabel={(option) =>  option?.name || ''}
          value= {field.value}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(event, newValue) => {
            helpers.setValue(newValue ? { id: newValue.id, name: newValue.name } : {"id":0,"name":""} );
            helpers.setTouched(false);
            if (props.setFormData) {
              let fieldName = props.name;
              props.setFormData(prev => ({
                ...prev,
                [fieldName]: newValue ? { id: newValue.id, name: newValue.name } : null
              }));
            }
          }}
          renderInput={(params) => (
            <TextField style={{width:'50%',float:'left',marginLeft:'2%'}}
              {...params}
              variant="standard"
              placeholder={props.placeholder}
              error={Boolean(meta.touched && meta.error)}
              helperText={
                meta.touched && meta.error
                  ? typeof meta.error === 'string'
                    ? meta.error
                    : JSON.stringify(meta.error)
                  : undefined
              }
              InputProps={{
                ...params.InputProps,
                startAdornment: Icon && (
                  <InputAdornment position="start">
                    <Icon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </FormControl>
    );
  };
  

const CondensedButton = styled(Button)(({ theme }) => ({
    fontSize: '12px',
    margin: '0px',
    padding: '5px 0px',
    width: '100px',
}));

const CondensedTypography = styled(Typography)(({ theme }) => ({
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
    height: '30px',
    marginLeft: '5px',
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '14px',
    padding: '0px 8px',
    height: '30px',
    width: '90%',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '1px',
      textAlign: 'left',
    },
  },
  margin: 0,
  '& input': {
    textAlign: 'left',
  },
}));

const CondensedTextField = (props) => {
  const { name, value, onChange, onBlur, ...otherProps } = props;
  return (
    <StyledTextField
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      {...otherProps}
    />
  );
}
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));




const CashAtHand = ({handleClose , user}) => {
    const [data, setData] = useState([]);
    const [accountType, setAccountType] = useState([]);

    const validationSchema = Yup.object().shape({
      faccount_type: Yup.object()
      .nullable()
      .test(
        'faccount_type-check',
        'Account type need to be selected!',
        value => value && value.name
      ),
      faccount_name: Yup.string().required('Account name is required'),
      faccount_number: Yup.string().required('Account number is required'),
      fnote: Yup.string().required('Add a note is required'),
      fbalance:Yup.string().required('Please enter a valid balance.'),
   });
    

    const [formData, setFormData] = useState({
        faccount_type:null,
        faccount_name:user?.name,
        faccount_number:user?.id,
        fnote:"Cash at hand",
      });


    const fetchDataTypes = async () => {
      try {
        const response = await axios.post(`/accountypes`, {
        });
        const transformedData = response.data.map(item => ({
          id: item.id,
          name: item.name
        }));
        setAccountType(transformedData);
        } catch (error) {
          console.error('Error fetching account types:', error);
        } finally {
      }
    }

    useEffect(() => {
      fetchDataTypes();
    }, []);


    const handleFinish = async (validateForm, setTouched, values) => {
      try {
        values.user_id = user?.id;
        values.status = 1;
        const response = await axios.post(`/account/create`, {
          values
        });
        handleClose();
      } catch (error) {
        console.error('Error creating a new account type:', error);
      } finally {
    }
}




    return (
    <Container maxWidth={true} sx={{ width: '100%', textAlign: 'center', minHeight: 'auto', height: 'auto', overflowY: 'auto', overflowX: 'none' }}>
       <Formik
                  initialValues={formData}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    console.log("Form submitted successfully", values);
                  }}
                >
          {({ errors, touched, validateForm, setTouched, values ,  setFieldValue, setFieldTouched , handleBlur }) => (
          <Form>
        
        
        <Grid
                container
                spacing={1}
                direction="column"
                alignItems="left"
                justifyContent="left"
                sx={{ flexGrow: 1 }} 
            >
                <Grid item xs={12} sx={{ width: '100%' }}>
                        <CondensedTypography style={{ fontWeight: '900' , width:'100%' , fontSize:'20px'  }}>Cash at Hand</CondensedTypography>
                </Grid>
        </Grid>

      <Grid
        container
        spacing={1}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ flexGrow: 1 }} 
      >
        <Grid item xs={12} sx={{ width: '100%' }}>
                            <Paper
                                elevation={3}
                                sx={{
                                padding: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                }}
                            >

                                  <Grid container spacing={1} alignItems="left" style={{padding:'2%'}}>
                                            <Grid item xs={12} sx={{ padding: "1px" }}>
                                                 <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
                                                    <CondensedTypography style={{ fontWeight: '900' , width:'20%' }}>Employee:</CondensedTypography>
                                                    
                                                    <FormControl variant="standard" style={{ width: '90%' }} error={Boolean(touched.faccount_name && errors.faccount_name)}>
                                                    <CondensedTextField
                                                              name="faccount_name"
                                                              variant="outlined"
                                                              value={values.faccount_name}
                                                              onChange={(e) => setFieldValue('faccount_name', e.target.value)}
                                                              onBlur={handleBlur}
                                                              InputProps={{
                                                                readOnly: false,
                                                              }}
                                                              fullWidth
                                                       />
                                                         <ErrorMessage name="faccount_name">
                                                {msg => <FormHelperText>{msg}</FormHelperText>}
                                                </ErrorMessage>
                                            </FormControl>
                                                </Stack> 

                                                <Stack direction="row" spacing={2} sx={{ width: '100%',marginTop:'3px',display:'none' }}>
                                                    <CondensedTypography style={{ fontWeight: '900' , width:'20%' }}>Account number:</CondensedTypography>                                                   
                                                        <FormControl variant="standard" style={{ width: '90%' }} error={Boolean(touched.faccount_number && errors.faccount_number)}>
                                                          <CondensedTextField
                                                              name="faccount_number"
                                                              variant="outlined"
                                                              value={values.faccount_number}
                                                              onChange={(e) => setFieldValue('faccount_number', e.target.value)}
                                                              onBlur={handleBlur}
                                                              InputProps={{
                                                                readOnly: false,
                                                              }}
                                                              fullWidth
                                                            />

                                              <ErrorMessage name="faccount_number">
                                                {msg => <FormHelperText>{msg}</FormHelperText>}
                                                </ErrorMessage>
                                            </FormControl>
                                                </Stack>  


                                               <Stack direction="row" spacing={2} sx={{ width: '100%',marginTop:'3px' }}>
                                                    <CondensedTypography style={{ fontWeight: '900' , width:'20%' }}>Account type (Cash):</CondensedTypography>
                                                    <CAutocompleteField
                                                      data={accountType}
                                                      name={'faccount_type'}
                                                      formData={formData}
                                                      setFormData={setFormData}
                                                      placeholder="Select account type"
                                                      icon={PinDropIcon}
                                                   />
                                                </Stack>  



                                                <Stack direction="row" spacing={2} sx={{ width: '100%',marginTop:'3px' }}>
                                                    <CondensedTypography style={{ fontWeight: '900' , width:'20%' }}>Openning balance:</CondensedTypography>
                                                    <FormControl variant="standard" style={{ width: '90%' }} error={Boolean(touched.fbalance && errors.fbalance)}>
                                                    <CondensedTextField
                                                              name="fbalance"
                                                              variant="outlined"
                                                              value={values.fbalance}
                                                              onChange={(e) => setFieldValue('fbalance', e.target.value)}
                                                              onBlur={handleBlur}
                                                              InputProps={{
                                                                readOnly: false,
                                                              }}
                                                              fullWidth
                                                            />

                                                            
                                              <ErrorMessage name="fbalance">
                                                {msg => <FormHelperText>{msg}</FormHelperText>}
                                                </ErrorMessage>
                                            </FormControl>
                                                </Stack>    



                                            </Grid>
                                
                                </Grid>

          </Paper>
        </Grid>


        <Grid item xs={12} sx={{ width: '100%' ,display:'none'  }}>
                <Paper
                    elevation={3}
                    sx={{
                    padding: 0,
                
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    }}
                >
                <Grid container spacing={0} alignItems="left">
                            <Grid item xs={12} sx={{ padding: "1px" }}>
                            <CondensedTypography style={{ fontWeight: '900' }}>Notes:</CondensedTypography>
                                  <Box sx={{ height: 100, width: '100%' }}>

                                            <FormControl variant="standard" style={{ width: '100%' }} error={Boolean(touched.fnote && errors.fnote)}>
                                                <Field
                                                as={TextField}
                                                name="fnote"
                                                id="outlined-multiline-static"
                                                label="Additional Notes"
                                                multiline
                                                rows={2}
                                                value={values.fnote}
                                                onChange={(e) => setFieldValue('fnote', e.target.value)}
                                                onBlur={handleBlur}
                                                placeholder="Enter your additional notes"
                                                fullWidth
                                                />
                                                <ErrorMessage name="fnote">
                                                {msg => <FormHelperText>{msg}</FormHelperText>}
                                                </ErrorMessage>
                                            </FormControl>

                                 </Box>
                            </Grid>
                </Grid>
          </Paper>
        </Grid>
      </Grid>


        <Grid
                container
                spacing={1}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ flexGrow: 1 , marginTop:'0.5%'}} 
            >
                <Grid item xs={12} sx={{ width: '100%' }}>
                                    <Paper
                                        elevation={3}
                                        sx={{
                                        padding: 1,
                                        display: 'flex',
                                        justifyContent: 'right',
                                        alignItems: 'right',
                                        textAlign: 'center',
                                        }}
                                    >
                                        <CondensedButton variant="outlined" onClick={async () => {
                                                const isValid = await validateForm();
                                                if (Object.keys(isValid).length === 0) {
                                                     handleFinish(validateForm, setTouched, values);
                                                } else {
                                                setTouched({
                                                    faccount_type: true,
                                                    faccount_name:true,
                                                    faccount_number:true,
                                                    fnote:true,
                                                    fbalance:true,
                                                });
                                                }
                                            }}
                                        >
                                        Save
                                        </CondensedButton>
                                    </Paper>
                </Grid>
        </Grid>
     </Form>
      )}
</Formik>












  </Container>
 );
};

export default CashAtHand;