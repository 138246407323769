
import React, { useRef , useState , useEffect  } from 'react';

import { useNavigate  } from 'react-router-dom';
import {IconButton , Modal, Dialog, DialogActions , DialogContent ,DialogTitle,Alert,  Container, TextField, Typography, Box, Paper } from '@mui/material';
import AppAppBar from './AppAppBar';
import Orders from './Orders';
import TotalsForm from './TotalsForm';
import CustomerPanel from './CustomerPanel';
import ScanInputs from './ScanInputs';
import RegisterMode from './RegisterMode';
import  DrawerCart from './DrawerCart';
import  ShortcutDrawer from './ShortcutDrawer';
import Login from './Login';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';

import RegisterDetails from './restaurant/RegisterDetails';
import CashAtHand from './restaurant/CashAtHand';
import RoomReservation from './restaurant/RoomReservation';
import LicenceExpiry  from './restaurant/LicenceExpiry';

import Invoice from './Invoice';
import Quote from './Quote';
import WorkOrder from './WorkOrder';
import Return from './Return';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.container,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


function Home() {
    const [searchValue, setSearchValue] = useState('');
    const [totalSection, setTotalSection] = useState([]);
    const [cartValues, setCartValues] = useState([]);
    const [customerId, setCustomerId] = useState({name: 'Default Customer', id:1});
    const [suspendedOrders, setSuspendedOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [userDetails, setUserDetails] = useState(null); 
    const [invoiceId, setInvoiceId] = useState(0);


    const [dopen, setdOpen] = React.useState(false);
    const [modalContent, setModalContent] = useState('');
    const [forceClose, setForceClose] = useState(false);

    const [transactionType, setransactionType] = useState(1);


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleBackdropClick = (event) => {
      event.stopPropagation();
    };


    const readInstallConfig = async () => {
      try {
          const response = await fetch('/install.conf');
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          const text = await response.text();
          const lines = text.split('\n');
          const systemInstalledLine = lines.find(line => line.startsWith('SYSTEM_INSTALLED'));
          if (systemInstalledLine) {
              const value = systemInstalledLine.split('=')[1].trim();
              return value;
          } else {
              throw new Error('SYSTEM_INSTALLED not found in the config file');
          }
      } catch (error) {
          console.error('Error reading install.conf:', error);
          return null;
      }
  };
  const navigate = useNavigate();
  useEffect(() => {
    const fetchConfig = async () => {
        const value = await readInstallConfig();
        if (value === '0') {
              navigate('/install');
        }
    };
    fetchConfig();
  }, [navigate]);

  const handledOpen = () => setdOpen(true);
  const handledClose = () => {
    setForceClose(false);
    setdOpen(false);
  }


  const [mopen, setmOpen] = useState(false);
  const handlemOpen = () => setmOpen(true);
  const handlemClose = () =>{ 
      setmOpen(false);
      setForceClose(true);
      closeRegister();
  }
  const [alertInfo, setAlertInfo] = useState({ show: false, severity: '', message: '' });
  
  
  const registerCheck = async (user) => {
    try {
        const response = await axios.post('/ui/register-check', {
            id: user?.id
        });
        if(response.data.length == 0){
            setForceClose(true);
            setModalContent('cash_at_hand');
            handledOpen();
        }else{
            handledClose();
        }
        const createdAt = response.data[0]?.created_at;
        const createdDate = new Date(createdAt).toISOString().split('T')[0];
        const todayDate = new Date().toISOString().split('T')[0];
        if (createdDate !== todayDate) {
          setForceClose(true);
          setAlertInfo({
            show: true,
            severity: 'error',
            message: `The system detected you have an Open register dated ${createdDate}, Please close to proceed.`,
          });
          handlemOpen();
        }
  
        //setRegisterdata(response.data);
    } catch (error) {
        console.error('Error fetching register details:', error);
    }
  };
  
  const operationsRegister =  (user) => {
    if (!user) {
      window.location.href = '/';
    }else{
        const expiryDate = new Date(user?.business[0].expiration_date).toISOString().split('T')[0];      
        const todayDate = new Date().toISOString().split('T')[0];
        if (expiryDate < todayDate) {
            setForceClose(true);
            setModalContent('expired_licence');
            handledOpen();
        }else{
            registerCheck(user);
        }
    }
  }

  const [dproducts, setDProducts] = useState([]);
  const [dloading, setDLoading] = useState(false);
  const fetchProductsData = async () => {
       try {
          setDLoading(true);
          const response = await axios.get(`/ui/products`);
          setDProducts(response.data);
        } catch (error) {
          console.error('Error fetching products:', error);
        } finally {
          setDLoading(false);
        }

  }


  const checkUserDetails = async () => {
      try {
          const response = await axios.get('/ui/check-user-session');
          if (response.data.user) {
              setUserDetails(response.data.user);
              setOpen(false);
              operationsRegister(response.data.user);
              switch(response.data.user?.business[0]?.businessCategory){
                case '3':
                  navigate('/restaurant', { state: { user: response.data.user } });
                break;
              }
              fetchProductsData();
            } else {
              setOpen(true);
          }
      } catch (error) {
          console.error('Error checking user session:', error);
          setOpen(true);
      }finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      document.body.style.backgroundImage = "url('./images/home-1.jpg')";
      document.body.style.backgroundSize = '100%';
      document.body.style.backgroundPosition = 'top';
      checkUserDetails();
      return () => {
        document.body.style.backgroundImage = '';
        document.body.style.backgroundSize = '';
        document.body.style.backgroundPosition = '';
      };
    }, []);

    const toggleColorMode = () => {
        setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
      };
    const drawerCartRef = useRef(null);
    const handleToggleDrawer = () => {
      if (drawerCartRef.current) {
        drawerCartRef.current.openDrawer();
      }
    };
    const drawerShortcutCartRef = useRef(null);
    const handleToggleShortcutDrawer = () => {
      if (drawerShortcutCartRef.current) {
        drawerShortcutCartRef.current.openDrawer();
      }
    };


    if (loading) {
      return (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#fff',
        }}>
          <img
            src="./images/install-background-1.png"
            alt="Loading..."
            style={{ width: '100%', height: '100%', objectFit: 'cover' ,opacity:'0.5'}}
          />
        </div>
      );
    }

    const fullScreen = () => {
      const elem = document.documentElement;
      if (!document.fullscreenElement) {
          if (elem.requestFullscreen) {
              elem.requestFullscreen();
          } else if (elem.mozRequestFullScreen) {
              elem.mozRequestFullScreen();
          } else if (elem.webkitRequestFullscreen) {
              elem.webkitRequestFullscreen();
          } else if (elem.msRequestFullscreen) {
              elem.msRequestFullscreen();
          }
          setIsFullscreen(true);
      } else {

          if (document.exitFullscreen) {
              document.exitFullscreen();
          } else if (document.mozCancelFullScreen) {
              document.mozCancelFullScreen();
          } else if (document.webkitExitFullscreen) {
              document.webkitExitFullscreen();
          } else if (document.msExitFullscreen) {
              document.msExitFullscreen();
          }
          setIsFullscreen(false);
      }
  };

  const closeRegister =  () => {
    setModalContent('close_register');
    handledOpen();
};

const registerDetails =  () => {
    setModalContent('register_details');
    handledOpen();
};



const renderRightPanel = () => {
  switch (transactionType) {
      case '1':
        return (
          <Grid xs={12} md={4}>
          <Item>
              <CustomerPanel customerId={customerId} setCustomerId={setCustomerId} onToggleShortcutDrawer={handleToggleShortcutDrawer}/>
                    <TotalsForm
                        invoiceId={invoiceId} setInvoiceId={setInvoiceId}
                        totalSection={totalSection}
                        setTotalSection={setTotalSection}
                        cartValues={cartValues}
                        customerId={customerId}
                        setCartValues={setCartValues}
                        setSearchValue={setSearchValue}
                        setCustomerId={setCustomerId}
                        setSuspendedOrders={setSuspendedOrders}
                        userDetails={userDetails}
                    />
              </Item>
        </Grid>
      );
      case '2':
        return (
          <Grid xs={12} md={4}>
              <Item>
                <CustomerPanel customerId={customerId} setCustomerId={setCustomerId} onToggleShortcutDrawer={handleToggleShortcutDrawer}/> 
                <Quote
                        invoiceId={invoiceId} setInvoiceId={setInvoiceId}
                        totalSection={totalSection}
                        setTotalSection={setTotalSection}
                        cartValues={cartValues}
                        customerId={customerId}
                        setCartValues={setCartValues}
                        setSearchValue={setSearchValue}
                        setCustomerId={setCustomerId}
                        setSuspendedOrders={setSuspendedOrders}
                        userDetails={userDetails}
                />
              </Item>
        </Grid>
      );;
      case '3':
        return (
          <Grid xs={12} md={4}>
              <Item>
                <CustomerPanel customerId={customerId} setCustomerId={setCustomerId} onToggleShortcutDrawer={handleToggleShortcutDrawer}/> 
                <WorkOrder
                        invoiceId={invoiceId} setInvoiceId={setInvoiceId}
                        totalSection={totalSection}
                        setTotalSection={setTotalSection}
                        cartValues={cartValues}
                        customerId={customerId}
                        setCartValues={setCartValues}
                        setSearchValue={setSearchValue}
                        setCustomerId={setCustomerId}
                        setSuspendedOrders={setSuspendedOrders}
                        userDetails={userDetails}
                />
              </Item>
        </Grid>
      );;
      case '4':
        return (
          <Grid xs={12} md={4}>
              <Item>
                <CustomerPanel customerId={customerId} setCustomerId={setCustomerId} onToggleShortcutDrawer={handleToggleShortcutDrawer}/> 
                <Invoice
                        invoiceId={invoiceId} setInvoiceId={setInvoiceId}
                        totalSection={totalSection}
                        setTotalSection={setTotalSection}
                        cartValues={cartValues}
                        customerId={customerId}
                        setCartValues={setCartValues}
                        setSearchValue={setSearchValue}
                        setCustomerId={setCustomerId}
                        setSuspendedOrders={setSuspendedOrders}
                        userDetails={userDetails}
                />
              </Item>
        </Grid>
      );;
      case '5':
        return (
          <Grid xs={12} md={4}>
              <Item>
                <CustomerPanel customerId={customerId} setCustomerId={setCustomerId} onToggleShortcutDrawer={handleToggleShortcutDrawer}/> 
                <Return
                      invoiceId={invoiceId} setInvoiceId={setInvoiceId}
                      totalSection={totalSection}
                      setTotalSection={setTotalSection}
                      cartValues={cartValues}
                      customerId={customerId}
                      setCartValues={setCartValues}
                      setSearchValue={setSearchValue}
                      setCustomerId={setCustomerId}
                      setSuspendedOrders={setSuspendedOrders}
                      userDetails={userDetails}
                />
              </Item>
        </Grid>
      );;

      default:
          return (
            <Grid xs={12} md={4}>
                <Item>
                    <CustomerPanel customerId={customerId} setCustomerId={setCustomerId} onToggleShortcutDrawer={handleToggleShortcutDrawer}/>

                    <TotalsForm
                        invoiceId={invoiceId} setInvoiceId={setInvoiceId}
                        totalSection={totalSection}
                        setTotalSection={setTotalSection}
                        cartValues={cartValues}
                        customerId={customerId}
                        setCartValues={setCartValues}
                        setSearchValue={setSearchValue}
                        setCustomerId={setCustomerId}
                        setSuspendedOrders={setSuspendedOrders}
                        userDetails={userDetails}
                    />
                </Item>
              </Grid>
          );
  }
};




    return (
      <div>
      <AppAppBar registerDetails={registerDetails} closeRegister={closeRegister} fullScreen={fullScreen} cart={cartValues} mode="light" toggleColorMode={toggleColorMode} userDetails={userDetails} setUserDetails={setUserDetails} handleOpen={handleOpen} />
        <Container maxWidth="lg" style={{ marginTop: '100px' }}>
        <DrawerCart dloading={dloading}  dproducts={dproducts}  ref={drawerCartRef} setSearchValue={setSearchValue} cartValues={cartValues} />
        <ShortcutDrawer ref={drawerShortcutCartRef} />
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid xs={12} md={8}>
                <Item style={{marginBottom:'2%'}}>
                  <Grid container spacing={3}>
                  <Grid xs={12} md={12}>
                    <Item style={{marginBottom:'2px'}}>
                      < RegisterMode transactionType={transactionType} setransactionType={setransactionType} onToggleDrawer={handleToggleDrawer}  setCartValues={setCartValues} suspendedOrders={suspendedOrders} setSuspendedOrders={setSuspendedOrders}/> 
                    </Item>
                    <Item>
                      < ScanInputs dloading={dloading}  dproducts={dproducts} fetchProductsData={fetchProductsData}  invoiceId={invoiceId} setInvoiceId={setInvoiceId} cartValues={cartValues} setSearchValue={setSearchValue}  setCartValues={setCartValues} /> 
                    </Item>
                  </Grid>
                 </Grid>
                </Item>
                
                <Item>
                  <Orders searchValue={searchValue} setTotalSection={setTotalSection} cartValues={cartValues} setCartValues={setCartValues} />
                </Item>
              </Grid> 
                    {renderRightPanel()}
            </Grid>
          </Box>

          <Modal
              open={mopen}
              onClose={handlemClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              >
              <Box sx={style}>
                {alertInfo.show && (
                  <Alert variant="outlined" severity={alertInfo.severity} onClose={() => {
                    setAlertInfo({ ...alertInfo, show: false });
                    handlemClose();
                  }}>
                    {alertInfo.message}
                  </Alert>
                )}
              </Box>
          </Modal>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
              onClick: handleBackdropClick,
            }}
          >
            <Box sx={style} style={{backgroundColor: '#f1dbdb47'}}>
                <Login onLoginSuccess={handleClose} setUserDetails={setUserDetails} checkUserDetails={checkUserDetails} />
            </Box>
          </Modal>



          <Modal 
                open={dopen}
                onClose={handledClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropProps={{
                    onClick: handleBackdropClick,
                }}
              >
                <Box sx={{ ...style, backgroundColor: '#ffffffd4', position: 'relative' , width:'60%' , height: 'auto' }}>
                {forceClose === false &&  <IconButton
                        aria-label="close"
                        onClick={handledClose}
                        sx={{
                            position: 'absolute',
                            top: -20,
                            right: -20,
                            color: 'red',
                            backgroundColor: 'white',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 0, 0, 0.1)',
                            },
                        }}
                    >
                        <CloseIcon />
                    </IconButton>}
                    {modalContent === 'register_details' && <RegisterDetails type={"view"} user={userDetails} handleCloseModal={handledClose} />}
                    {modalContent === 'close_register' && <RegisterDetails type={"close"} user={userDetails} handleCloseModal={handledClose} />}
                    {modalContent === 'cash_at_hand' && <CashAtHand handleClose={handledClose} user={userDetails} />}
                    {modalContent === 'expired_licence' && <LicenceExpiry handleClose={handledClose} user={userDetails} />}
                </Box>
            </Modal>


        </Container>
    </div>
    );
}

export default Home;
