import React, { useState, useEffect , useRef} from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Card, CardContent ,Divider} from '@mui/material';

import Button from '@mui/material/Button';
import Popper from '@mui/material/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';



import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import CalculateIcon from '@mui/icons-material/Calculate';
import FitScreenIcon from '@mui/icons-material/FitScreen';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';


import Calc from './Calc'

import KioskBoard from 'kioskboard';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default function MenuToolBar({cart , fullScreen , calculator , registerDetails, closeRegister,  user , searchterm, setSearchterm , handleSearch}) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const inputRef = useRef(null);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSearchValue = () => {
    let searchInput = document.querySelector('.js-kioskboard-input');
    if (searchInput) {
      setSearchterm(searchInput.value);
    }
  };


  useEffect(() => {
    if (searchterm) {
      handleSearch(searchterm);
    }
    //console.log(cart?.items?.length);

  }, [searchterm, handleSearch]); 

  useEffect(() => {
    console.log(inputValue);
  }, [inputValue]);

  useEffect(() => {
      KioskBoard.init({
      keysArrayOfObjects: null,
      keysJsonUrl: "./keyboard-keys.json",
      keysSpecialCharsArrayOfStrings: null,
      keysNumpadArrayOfNumbers: null,
      language: 'en',
      theme: 'dark',
      autoScroll: true,
      capsLockActive: false,
      allowRealKeyboard: true,
      allowMobileKeyboard: false,
      cssAnimations: true,
      cssAnimationsDuration: 360,
      cssAnimationsStyle: 'slide',
      keysAllowSpacebar: true,
      keysSpacebarText: 'Space',
      keysFontFamily: 'sans-serif',
      keysFontSize: '22px',
      keysFontWeight: 'normal',
      keysIconSize: '25px',
      keysEnterText: 'Enter',
      keysEnterCallback: handleSearchValue,
      keysEnterCanClose: true,
      });
      KioskBoard.run(inputRef.current);
  }, []);


  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >


      
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={0} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 0 new notifications"
          color="inherit"
        >
          <Badge badgeContent={0} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );


  const BusinessLicenseCard = ({ details }) => { 

    return (
      <Paper elevation={3} style={{ padding: '20px', margin: '20px' }}>
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              {details.businessName}
            </Typography>
  
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              {details.businessLocation}
            </Typography>
            <Divider/>
            <Typography variant="subtitle2" gutterBottom>
              <strong>Business id:</strong> {details.business_id}
            </Typography>
  
            <Typography variant="subtitle2" gutterBottom>
              <strong>Category:</strong> {details.category}
            </Typography>
  
            <Typography variant="subtitle2" gutterBottom>
              <strong>Company:</strong> {details.company}
            </Typography>
  
            <Typography variant="subtitle2" gutterBottom>
              <strong>Email:</strong> {details.email}
            </Typography>
  
            <Typography variant="subtitle2" gutterBottom>
              <strong>Expiration date:</strong> {details.expiration_date}
            </Typography>
  
            <Typography variant="subtitle2" gutterBottom>
              <strong>License name:</strong> {details.license_name}
            </Typography>
  
            <Typography variant="subtitle2" gutterBottom>
              <strong>License email:</strong> {details.license_email}
            </Typography>
  
            <Typography variant="subtitle2" gutterBottom>
              <strong>Software:</strong> {details.licensed_to}
            </Typography>
  
            <Typography variant="subtitle2" gutterBottom>
              <strong>Manufacturer:</strong> {details.manufacturer}
            </Typography>
  
            <Typography variant="subtitle2" gutterBottom>
              <strong>Notes:</strong> {details.notes}
            </Typography>
  
            <Typography variant="subtitle2" gutterBottom>
              <strong>PIN:</strong> {details.pin}
            </Typography>
  
            <Typography variant="subtitle2" gutterBottom>
              <strong>Supplier:</strong> {details.supplier}
            </Typography>
  
            <Typography variant="subtitle2" gutterBottom>
              <strong>Suppler phone:</strong> {details.telephone}
            </Typography>
          </CardContent>
        </Card>
      </Paper>
    );
  };

//console.log(user?.business);

  return (
    <Box sx={{ flexGrow: 1 }} >
      <AppBar position="static" style={{backgroundColor:'black'}}>
        <Toolbar >
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            POS
          </Typography>
          <Search>
            <SearchIconWrapper>
              <SearchIcon/>
            </SearchIconWrapper>
            <input ref={inputRef} value={inputValue}  onInput={handleChange}  style={{backgroundColor:'black',color:'white',paddingLeft:'45px'}} className="js-kioskboard-input" data-kioskboard-type="all" data-kioskboard-placement="bottom" placeholder="Search" id="searchField" />
            {/* <StyledInputBase className="js-kioskboard-input"
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            /> */}
          </Search>
          <Box sx={{ flexGrow: 1 }} />
          <span style={{fontSize:'11px'}}>Logged in as {user?.name} | {user?.branch} | {user?.counter}</span>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

            <IconButton size="large" aria-label="show 4 new mails" color="inherit" onClick={fullScreen}>
                <FitScreenIcon />&nbsp;
               <span style={{fontSize:'11px',color:'orange',fontWeight:'900'}}>Fullscreen</span>
            </IconButton>

            <IconButton size="large" aria-label="show 4 new mails" color="inherit" onClick={registerDetails}>
                <ReceiptIcon />&nbsp;
               <span style={{fontSize:'11px',color:'orange',fontWeight:'900'}}>Register Details</span>
            </IconButton>

            <IconButton size="large" aria-label="show 4 new mails" color="inherit" onClick={closeRegister}>
                <DisabledByDefaultIcon />&nbsp;
               <span style={{fontSize:'11px',color:'orange',fontWeight:'900'}}>Close Register</span>
            </IconButton>

            <IconButton size="large" aria-label="show 4 new mails" color="inherit">
              <PopupState variant="popper" popupId="demo-popup-popper">
                {(popupState) => (
                  <div style={{marginBottom:'5px'}}>
                    <div {...bindToggle(popupState)}><CalculateIcon />
                    <span style={{fontSize:'11px',color:'orange',fontWeight:'900'}}></span></div>

                    <Popper {...bindPopper(popupState)} transition style={{zIndex:10000}}>
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                          <Paper>
                             <Calc/>
                          </Paper>
                        </Fade>
                      )}
                    </Popper>
                  </div>
                )}
              </PopupState>
            </IconButton>


            <IconButton size="large" aria-label="show 4 new mails" color="inherit">
              <PopupState variant="popper" popupId="demo-popup-popper">
                {(popupState) => (
                  <div style={{marginBottom:'5px'}}>
                    <div {...bindToggle(popupState)}><QrCodeScannerIcon />
                    <span style={{fontSize:'11px',color:'orange',fontWeight:'900'}}></span></div>

                    <Popper {...bindPopper(popupState)} transition style={{zIndex:10000}}>
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                          <Paper>
                              <BusinessLicenseCard details={user?.business[0]}/>
                          </Paper>
                        </Fade>
                      )}
                    </Popper>
                  </div>
                )}
              </PopupState>
            </IconButton>





            <IconButton size="large" aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={cart?.items?.length} color="error">
                <ProductionQuantityLimitsIcon />
              </Badge>
            </IconButton>



            <IconButton
              size="large"
              aria-label="show 0 new notifications"
              color="inherit"
            >
              <Badge badgeContent={0} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>



            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}