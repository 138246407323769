import React, { useState, useEffect } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import { Grid, TextField , Typography , CircularProgress , Box   } from '@mui/material';
import axios from 'axios';

const CondensedTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  display: 'flex',
  alignItems: 'center',
  height: '30px',
  marginLeft: '5px',
}));

const CondensedTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
      fontSize: '0.875rem',
      padding: '4px 8px', 
      height: '30px',    
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderWidth: '1px',
        textAlign:'left',
      },
    },
    margin: 0,
    '& input': {
      textAlign: 'center',
    },
  }));

export default function DrawerProducts({ dloading , dproducts , selectedCategory , selectedSupplier , setSearchValue ,cartValues  } ) {

  const [inputValue, setInputValue] = useState('Search...');
  const [products, setProducts] = useState(dproducts);
  const [loading, setLoading] = useState(dloading);

  const [cartNewValues, setCartNewValues] = useState(cartValues);
  const [newValue, setNewValue] = useState('');
 
  


  useEffect(() => {
    const fetchData = async () => {
      if (inputValue && inputValue != 'Search...') {
        try {
          const response = await axios.get(`/ui/products`, {
            params: { query: inputValue }
          });
          setProducts(response.data);      
        } catch (error) {
          console.error('Error fetching products:', error);
        }finally { 
        }
      }else if(selectedCategory > 0){
        try {
          setLoading(true);
          const response = await axios.get(`/ui/products`, {
            params: {category : selectedCategory }
          });
          setProducts(response.data);      
        } catch (error) {
          console.error('Error fetching products:', error);
        }finally {
          setLoading(false);
        }
      }else if(selectedSupplier > 0){
        try {
          setLoading(true);
          const response = await axios.get(`/ui/products`, {
            params: {supplier : selectedSupplier }
          });
          setProducts(response.data);      
        } catch (error) {
          console.error('Error fetching products:', error);
        }finally {
          setLoading(false);
        }
      } else {

        setProducts(dproducts);
        setLoading(dloading);
        // try {
        //   setLoading(true);
        //   const response = await axios.get(`/ui/products`);
        //   setProducts(response.data);
        // } catch (error) {
        //   console.error('Error fetching products:', error);
        // } finally {
        //   setLoading(false);
        // }
      }

   }
    fetchData();
  }, [inputValue,selectedCategory,selectedSupplier,dproducts,dloading]);

  const handleInputChange = (event) => {
    setProducts([]);
    setInputValue(event.target.value);
  };

  // const handleProduct = (item) => {
  //   item.quantity = 1;
  //   item.sub_total = ((item.quantity + 1) * parseFloat(item.selling_price));
  //   item.taxValue = (item.sub_total) * parseFloat(item.tax) ;
  //   alert(item.sub_total);
  //   console.log(item);
  //   setCartNewValues((prevCartValues) => [...prevCartValues, item]);
  //   setNewValue(item);
  // };

  const handleProduct = (item) => {
    setCartNewValues((prevCartValues) => {
      const existingItem = prevCartValues.find((cartItem) => cartItem.id === item.id);
  
      if (existingItem) {
        const updatedCart = prevCartValues.map((cartItem) => {
          if (cartItem.id === item.id) {
            const updatedQuantity = cartItem.quantity + 1;
            const updatedSubTotal = updatedQuantity * parseFloat(cartItem.selling_price);
            const updatedTaxValue = updatedSubTotal * parseFloat(cartItem.tax || 0.16);
            return {
              ...cartItem,
              quantity: updatedQuantity,
              sub_total: updatedSubTotal,
              taxValue: updatedTaxValue,
            };
          }
          return cartItem;
        });
  
        return updatedCart;
      } else {

        const newQuantity = 1;
        const newSubTotal = newQuantity * parseFloat(item.selling_price);
        const newTaxValue = newSubTotal * parseFloat(item.tax || 0.16);
  
        return [
          ...prevCartValues,
          {
            ...item,
            quantity: newQuantity,
            sub_total: newSubTotal,
            taxValue: newTaxValue,
          },
        ];
      }
    });

  };

  useEffect(() => {
    if (newValue !== null) {
      setSearchValue([cartNewValues]);
    }
  }, [newValue, cartNewValues]);


  return (
    <>

{loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="70vh"
          width="100%"
        >
          <CircularProgress
              style={{ color: 'blue' }} 
              thickness={1} 
           />
        </Box>
      ) : (
        <>
            <ImageList sx={{ width: 500, height: 'auto' }}>
              <ImageListItem key="Subheader" cols={2}>

                    <Grid sx={{ padding: "1px" }}>
                            <CondensedTextField 
                                variant="outlined"                   
                                InputProps={{
                                readOnly: false,
                                }}
                                fullWidth
                                value={inputValue}
                                onChange={handleInputChange} 
                            />
                    </Grid>   
              </ImageListItem>
            
              {products.map((item) => (
                <ImageListItem key={item.id}>
                  <img onClick={() => handleProduct(item)} 
                    // srcSet={`/storage/products/${item.product_image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    // src={`/storage/products/${item.product_image}?w=248&fit=crop&auto=format`}
                    srcSet={item.product_image}
                    src={item.product_image}
                    alt={item.product_name}
                    loading="lazy"
                    style={{width:'180px',height:'124px',objectFit: 'scale-down'}}
                  />
                  <ImageListItemBar
                    title={item.product_name}
                    subtitle={item.category?.name}
                    actionIcon={
                      <IconButton
                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                        aria-label={`info about ${item.product_name}`}
                      >
                        <InfoIcon />
                      </IconButton>
                    }
                  />

                <Box mt={1} textAlign="right">
                  <Typography variant="body2" color="textSecondary">
                     {item.selling_price.toFixed(2)} /=
                  </Typography>
                </Box>

                </ImageListItem>
              ))}      
            
            </ImageList>
        </>
      )}
  </>
  );
}
