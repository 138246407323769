import React, { useState, useEffect, useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import AddCard from '@mui/icons-material/AddCard';
import NotInterested from '@mui/icons-material/NotInterested';
import AccountBalanceWallet from '@mui/icons-material/AccountBalanceWallet';
import axios from 'axios';
import { Box , TextField , IconButton, Button, Slide } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Delete as DeleteIcon, Undo as UndoIcon } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import AvTimer from '@mui/icons-material/AvTimer';

const CondensedButton = styled(Button)(({ theme }) => ({
  fontSize: '12px',
  margin: '15px',
  padding: '5px 10px 5px',
  width: 'auto'
}));

const CondensedTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '18px',
    padding: '4px 8px', 
    height: '30px',    
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '1px',
      textAlign:'right',
    },
  },
  margin: 0,
  '& input': {
    textAlign: 'right',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function createDbData(
    id, 
    product_code, 
    product_name, 
    product_garage, 
    product_image, 
    selling_price,
    quantity,
    sub_total,
    taxValue,
    tax
  ) {
    return { 
      id, 
      product_code, 
      product_name, 
      product_garage, 
      product_image, 
      selling_price,
      quantity,
      sub_total,
      taxValue,
      tax
    };
  }

export default function SuspendedDialog({ open, onClose , setCartValues ,suspendedOrders,setSuspendedOrders}) {
  const [alertInfo, setAlertInfo] = useState({ show: false, severity: '', message: '' });
  const [loading, setLoading] = useState(false);
  const [suspendedItems, setSuspendedItems] = useState([]);
  const [comments, setComments] = useState({});

  const handleUnsuspend = async (id) => {
    try {
        const response = await axios.post(`/ui/fetch-suspended-order-items`, {
            id:id
        });
        if(response.data){
            let responseData = response.data;
            let formattedData = responseData.map(item => {
                const { product } = item;
               
                return createDbData(
                  item.product_id,
                  item.product.product_code,
                  item.product.product_name,
                  item.product.product_garage,
                  item.product.product_image,
                  product.selling_price,
                  item.quantity,
                  parseFloat(item.total)?.toFixed(2),
                  item.tax,
                  product.tax
              );
            });

            const updatedCartValues = formattedData.map(item => {
                const updatedQuantity = item.quantity;
                return {
                  ...item,
                  quantity: updatedQuantity,
                  sub_total: updatedQuantity * item.selling_price,
                  tax: parseFloat(item.tax),
                  taxValue: parseFloat(item.taxValue),
                };
              return item;
            });
            
            setCartValues(updatedCartValues);
            handleDelete(id);
        }
        setSuspendedItems(response.data);
        onClose();
      } catch (error) {
        console.error('Error fetching suspended orders:', error);
      } finally {
    }
};
const handleDelete = async (id) => {
    try {
        const response = await axios.post('/ui/unsuspended-order', { id });
        if (response.data.bool) {
            setSuspendedOrders(prevOrders =>
                prevOrders.filter(order => order.id !== id)
            );
            setAlertInfo({
                show: true,
                severity: 'success',
                message:response.data.message
            });
        } else {
            setAlertInfo({
                show: true,
                severity: 'error',
                message:`Failed to delete the order: ${response.data.message}`
            });
        }
    } catch (error) {
        console.error('Error deleting order:', error);
        setAlertInfo({
            show: true,
            severity: 'error',
            message:`An error occurred while deleting the order.`
        });
    }
};

const handleSaveComment = async (id) => {
  const commentToSave = comments[id];
  if (commentToSave) {
    try {
      const response = await axios.post('/ui/save-order-comment', { id, comment: commentToSave });
      if (response.data.bool) {
        setAlertInfo({
          show: true,
          severity: 'success',
          message:`Comment for order ${id} saved successfully.`
        });
      } else {
        setAlertInfo({
          show: true,
          severity: 'error',
          message:'Failed to save comment:'+ response.data.message
        });
      }
    } catch (error) {
        setAlertInfo({
          show: true,
          severity: 'error',
          message:'Error saving comment:'+ error
        });
    }
  }
};


const handleCommentChange = (event, id) => {
  const newComment = event.target.value;
  setComments((prevComments) => ({
    ...prevComments,
    [id]: newComment,
  }));
};

const columns = [
  { field: 'invoice_no', headerName: '#', flex: 1 },
  { field: 'order_date', headerName: 'Sale Date', flex: 1 },
  {
    field: 'customerName',
    headerName: 'Customer',
    flex: 1,
    renderCell: (params) => params.row.customer?.name || 'N/A',
  },
  {
    field: 'userName',
    headerName: 'Employee',
    flex: 1,
    renderCell: (params) => params.row.user?.name || 'N/A',
  },
  {
    field: 'comments',
    headerName: 'Comments',
    flex: 1,
    renderCell: (params) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CondensedTextField
          value={params.row.comments}
          onChange={(e) => handleCommentChange(e, params.row.id)}
          variant="outlined"
          size="small"
          style={{ width: '100%' }}
          // multiline
          // maxRows={4}
        />
        <IconButton aria-label="save" onClick={() => handleSaveComment(params.row.id)}>
          <SaveIcon />
        </IconButton>
      </div>
    ),
  },
  {
    field: 'actions',
    headerName: 'Actions',
    flex: 1,
    renderCell: (params) => (
      <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
        <IconButton aria-label="unsuspend" onClick={() => handleUnsuspend(params.row.id)}>
          <UndoIcon />
          <span style={{ marginLeft: '4px', fontSize: '12px' }}>Unsuspend</span>
        </IconButton>
        <IconButton aria-label="delete" onClick={() => handleDelete(params.row.id)}>
          <DeleteIcon />
          <span style={{ marginLeft: '4px', fontSize: '12px' }}>Delete</span>
        </IconButton>
      </div>
    ),
  },
];


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`/ui/suspended-orders`, {
          params: {}
        });
        setSuspendedOrders(response.data);
      } catch (error) {
        console.error('Error fetching suspended orders:', error);
      } finally {
      }
    }
    fetchData();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{
        '& .MuiDialog-paper': {
          width: '80%',
          maxWidth: 'none',
        }
      }}
      keepMounted
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
        },
      }}
     >

      <DialogTitle>
        <Box display="flex" alignItems="center">
          <AvTimer style={{ marginRight: '8px' }} />
          Suspended transactions
        </Box>
      </DialogTitle>
      <DialogContent>
        {alertInfo.show && (
                <Alert variant="outlined" severity={alertInfo.severity} onClose={() => setAlertInfo({ ...alertInfo, show: false })} style={{marginBottom:'1%'}}>
                {alertInfo.message}
                </Alert>
        )}
        <DataGrid
          rows={suspendedOrders}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          rowHeight={38}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" startIcon={<NotInterested />} onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
