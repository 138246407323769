import React, { useState ,useRef , useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import AddCard from '@mui/icons-material/AddCard';
import NotInterested from '@mui/icons-material/NotInterested';
import AccountBalanceWallet from '@mui/icons-material/AccountBalanceWallet';
import axios from 'axios';
import { Alert , Grid, MenuItem, Select, InputLabel, FormControl, Stack, Button, TextField, Box, Paper } from '@mui/material';
import Receipt from './Receipt';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const CondensedButton = styled(Button)(({ theme }) => ({
  fontSize: '12px',
  margin: '15px',
  padding: '5px 10px 5px',
  width: 'auto'
}));

const CondensedTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '18px',  
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '1px',
      textAlign:'right',
    },
  },
  margin: 0,
  '& input': {
    textAlign: 'right',
  },
}));


const PaymentForm = ({ paymentnote , amount, totals, paymentmethod, handleChange, index }) => (
  <Paper elevation={2} >
    <Box sx={{ flexGrow: 0 }}>
      <Stack direction="row" spacing={1}>
        <Item>
          <TextField
            style={{width:"120px"}}
            label="Amount"
            id={`outlined-size-small-${index}`}
            name="amount"
            value={amount}
            onChange={(e) => handleChange(e, index)}
            size="small"
          />
        </Item>

        <Item>
          <FormControl sx={{ m: 0, minWidth: 240 , maxWidth: 140 }} size="small">
            <InputLabel id={`demo-select-small-label-${index}`}>Payment method</InputLabel>
            <Select
              labelId={`demo-select-small-label-${index}`}
              id={`demo-select-small-${index}`}
              name="paymentmethod"
              value={paymentmethod}
              label="Payment Method"
              onChange={(e) => handleChange(e, index)}
            >
              <MenuItem value={1}>Cash</MenuItem>
              <MenuItem value={2}>Mpesa</MenuItem>
              <MenuItem value={3}>Card payment (VISA,Mastercard,Discovery)</MenuItem>
              <MenuItem value={4}>Cheque</MenuItem>
              <MenuItem value={5}>Advance</MenuItem>
            </Select>
          </FormControl>
        </Item>
      </Stack>
      <FormControl sx={{ m: 0, minWidth: '100%' }} size="small" style={{ marginTop: '2%' }}>
        <TextField
          id={`outlined-multiline-static-${index}`}
          label="Payment note"
          name="paymentnote"
          value={paymentnote}
          onChange={(e) => handleChange(e, index)}
          multiline
          rows={2}
          defaultValue=""
        />
      </FormControl>
    </Box>
  </Paper>
);

export default function PaymentDialog({ open, onClose, totals, customerId  , totalSection , setTotalSection , cartValues , setCartValues , setSearchValue , setCustomerId , userDetails }) {
  const [paymentMethods, setPaymentMethods] = useState([{ paymentnote : '', amount: totals, paymentmethod: 1 }]);
  const [cashSaleNo, setCashSaleNo] = React.useState(null);
  const [alertInfo, setAlertInfo] = useState({ show: false, severity: '', message: '' });
  const receiptRef = useRef();

  const handlePrint = (invoice_id) => {
    const printContent = receiptRef.current.innerHTML; 
    const printWindowWidth = 400;
    const printWindowHeight = 700;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const left = (screenWidth / 2) - (printWindowWidth / 2);
    const top = (screenHeight / 2) - (printWindowHeight / 2);
    const printWindow = window.open('', '', `height=${printWindowHeight},width=${printWindowWidth},top=${top},left=${left}`);  

    printWindow.document.write('<html><head><title>Print Receipt</title>');
    printWindow.document.write('<style>');
    printWindow.document.write(`
      @media print {  }
        @page { size: 80mm auto; margin: 0; }
        body { font-size: 12px; padding: 10mm; text-transform: uppercase; }
        .centered-box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
          padding: 0;
          margin: 0;
        }
        .condensed-typography {
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 2px;       
        }
        .dotted-hr {
        width: 100%;
        border-top: 2px dotted black;
        border-bottom: none;
        border-left: none;
        border-right: none;
      }
       .two-column-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 10px;
        row-gap: 0px;
        font-size: 12px;
        margin: 0;
        padding: 0;
      }
      .four-column-grid {
        grid-template-columns: 2fr 1fr 1fr 1fr;
        row-gap: 0px;
        font-size: 12px;
        margin: 0;
        padding: 0;
        display: grid;
      }
      .bold-total-row {
        font-weight: bold;
        font-size: 18px;
      }
      .right-aligned-digits {
        text-align: right;
      }
    `);
    printWindow.document.write('</style>');
    printWindow.document.write('<script src="https://cdnjs.cloudflare.com/ajax/libs/qrcodejs/1.0.0/qrcode.min.js"></script></head><body>'); 
    printWindow.document.write(printContent);
    printWindow.document.write('<script>var qrcode = new QRCode("qrcode", {text:"'+ invoice_id +'",width: 100,height: 100,colorDark: "#000000",colorLight: "#ffffff",correctLevel: QRCode.CorrectLevel.H});</script>');
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  }

  useEffect(() => {
    if (cashSaleNo !== null) {
      handlePrint(cashSaleNo);
      setCartValues([]);
      setSearchValue(null);
      setTotalSection([]);
      setPaymentMethods([{ paymentnote : '', amount: 0, paymentmethod: 1 }]);
      // setPaymentMethods(prevPaymentMethods =>
      //   prevPaymentMethods.map(pm => ({ ...pm, amount: '0.00' }))
      // );
      setCustomerId({name: 'Default Customer', id:1});
    }
  }, [cashSaleNo]);


  const handleChange = (event, index) => {
    const { name, value } = event.target;
    const newPaymentMethods = [...paymentMethods];
    newPaymentMethods[index][name] = value;
    setPaymentMethods(newPaymentMethods);
  };

  const handleANewpaymentMethod = () => {
    const totalPaid = paymentMethods.reduce((sum, { amount }) => sum + parseFloat(amount || 0.00), 0.00);
    if(totalPaid === 0 ){
      setAlertInfo({
        show: true,
        severity: 'error',
        message: `Please enter an amount paid by customer!`
      });
    }else{
      const remainingTotal = totals - totalPaid;
      if(remainingTotal > 0){
        setPaymentMethods([...paymentMethods, { amount: remainingTotal > 0.00 ? remainingTotal : 0.00, paymentmethod: 1 }]);
      }else{
          setAlertInfo({
            show: true,
            severity: 'warning',
            message: `Please proceed and finalize the transaction!`
          });
      } 
    }
  };

  const handleFinalize = async () => {

    let grandtotal = totalSection['grandTotal'];
    let totalpaid = paymentMethods.reduce((sum, method) => sum + Number(method.amount),0);
    let balance = grandtotal - totalpaid;

    if (balance > 0) {
      setAlertInfo({
        show: true,
        severity: 'warning',
        message: `Please settle a balance of Ksh ${balance.toFixed(2)}`
      });
    } else {
      setAlertInfo({
        show: true,
        severity: 'success',
        message: 'Finalizing transaction! Please wait...'
      });
     try {
        if(totalpaid > 0){
         if(cartValues.length <= 0){
          setAlertInfo({
            show: true,
            severity: 'error',
            message: 'No items in your Cart!'
          });
         }else{

                    const formattedTotalPaid = parseFloat(totalpaid).toFixed(2);
                    const formattedBalance = parseFloat(balance).toFixed(2);
                    await setTotalSection((prevState) => ({
                      ...prevState,
                      totalpaid: formattedTotalPaid,
                      balance: formattedBalance,
                      is_receipt:true
                    }));
                    const response = await axios.post(`/ui/finalize-payment`, {
                          payment_methods:paymentMethods,
                          employee_id:userDetails?.id,
                          customer_id:customerId.id,
                          cart:cartValues,
                          grand_total:grandtotal,
                          sub_total:totalSection['subTotal'],
                          vat:totalSection['taxValue'],
                          total_products:totalSection['totalQuantity'],
                          total_paid:totalpaid,
                          balance:balance
                    });
                    if(response.data.bool){
                      await setCashSaleNo(response.data.invoice_id);
                      //await handlePrint(response.data.invoice_id);
                      setAlertInfo({
                        show: false,
                        severity: 'info',
                        message: response.data.message
                      });
                      onClose();
                    }else{
                      setAlertInfo({
                        show: true,
                        severity: 'error',
                        message: response.data.error
                      });
                    }


         }
      }else{
        setAlertInfo({
          show: true,
          severity: 'error',
          message: 'No amount Paid !'
        });
      }
     } catch (error) {
        console.error('Error fetching finalizing payment:', error);
     }finally {

     }
    }

  };

  return (
   <>
    <Dialog
      open={open}
      //onClose={onClose}
      onClose={(event, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
          return;
        }
        onClose();
      }}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries(formData.entries());
          console.log(formJson);
          onClose();
        },
      }}
    >
      <DialogTitle>Payment Details (Ksh {totals})</DialogTitle>
      <DialogContent>
          {alertInfo.show && (
            <Alert variant="outlined" severity={alertInfo.severity} onClose={() => setAlertInfo({ ...alertInfo, show: false })}>
              {alertInfo.message}
            </Alert>
          )}
          <Stack direction="row" spacing={0} style={{ justifyContent: 'right' }}>
          <Item>
            {paymentMethods.map((paymentMethod, index) => (
              <PaymentForm
                key={index}
                paymentnote={paymentMethod.paymentnote}
                amount={paymentMethod.amount}
                paymentmethod={paymentMethod.paymentmethod}
                handleChange={handleChange}
                index={index}
              />
            ))}
          </Item>


          <Item>

          <Box sx={{ flexGrow: 5 }}>

          <Stack direction="column" spacing={1}>
                <Item>
                  <CondensedTextField
                    label="Total items"
                    name="total_items"
                    value={totalSection['totalQuantity']}
                    size="small"
                    variant="filled"
                  />
                </Item>
                <Item style={{marginTop:'-5px'}}>
                  <CondensedTextField
                    label="Total payable"
                    name="total_payable"
                    value={totalSection['grandTotal']}
                    size="small"
                    variant="filled"
                  />
                </Item>
                <Item style={{marginTop:'-5px'}}>
                  <CondensedTextField
                    label="Total paying"
                    name="total_paying"
                    value={ paymentMethods.reduce((sum, method) => sum + Number(method.amount),0).toFixed(2)}
                    size="small"
                    variant="filled"
                  />
                </Item>
                <Item style={{marginTop:'-5px'}}>
                  <CondensedTextField
                    label="Change return"
                    name="change_return"
                    value={ (paymentMethods.reduce((sum, method) => sum + Number(method.amount),0).toFixed(2) - totalSection['grandTotal']).toFixed(2)}
                    size="small"
                    variant="filled"
                  />
                </Item>
                <Item style={{marginTop:'-5px'}}>
                  <CondensedTextField
                    InputProps={{
                        style: { textAlign: 'right' }
                    }}
                    label="Balance"
                    name="balance"
                    value={ (totalSection['grandTotal'] - paymentMethods.reduce((sum, method) => sum + Number(method.amount),0).toFixed(2)).toFixed(2)}
                    size="small"
                    variant="filled"
                  />
                </Item>
               </Stack>
            </Box>
           </Item>
          </Stack>
        <Grid  sx={{ padding: '1px', marginTop: '5px' }}>
          <Stack direction="row" spacing={2} style={{ justifyContent: 'left' }}>
            <CondensedButton variant="outlined" startIcon={<AddCard />} onClick={handleANewpaymentMethod}>
              Add a payment method
            </CondensedButton>
          </Stack>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" startIcon={<AccountBalanceWallet/>} onClick={handleFinalize}  >Finalize</Button>
        <Button variant="contained" startIcon={<NotInterested />}  onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
    <div style={{ display: 'none' }}>
          <Receipt paymentMethods={paymentMethods} ref={receiptRef} totalSection={totalSection} cartValues={cartValues} customerId={customerId} userDetails={userDetails} cashSaleNo={cashSaleNo}/>
    </div>
    </>
  );
}
