import React, { useState , useRef , useEffect   } from 'react';
import {IconButton, Typography ,TextField ,Dialog ,DialogActions ,DialogContent ,DialogContentText ,DialogTitle , Button ,ButtonGroup, Box, Grid, Paper, FormControl, InputLabel, Input, InputAdornment, FormHelperText, alertTitleClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import AddHomeWork from '@mui/icons-material/AddHomeWork';
import AddLocation from '@mui/icons-material/AddLocation';
import RequestPage from '@mui/icons-material/RequestPage';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Tty from '@mui/icons-material/Tty';
import Email from '@mui/icons-material/Email';
import {  RichTreeView, TreeItem } from '@mui/x-tree-view';
import { TreeItem2 } from '@mui/x-tree-view/TreeItem2';
import { Field, ErrorMessage } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

const BusinessInfo = ({ errors, touched , treeData, setTreeData }) => {

    const [selectedNodeId, setSelectedNodeId] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [branchName, setBranchName] = useState('');
    const [counterName, setCounterName] = useState('');
    const [openCounter, setOpenCounter] = React.useState(false);
    const selectedNodeIdRef = useRef(null);

    const handleCloseCounter = () => {
      setOpenCounter(false);
  };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e) => { 
        setBranchName(e.target.value);
     };

     const handleCounterChange = (e) => { 
      setCounterName(e.target.value);
   };
   const handleCounterAdd = () => {

    let split_ = selectedNodeId.split('_');
    let childrenArray = treeData[split_[1]-1].children;

    const generateUniqueId = () => `counter_${Date.now()}_${Math.floor(Math.random() * 1000)}`;
    const newCounterId = generateUniqueId();
    const newCounterLabel =  counterName;
    const updatedTreeData = [...treeData];
    updatedTreeData[split_[1]-1].children.push({
    id: newCounterId,
    label: newCounterLabel,
      });
    setTreeData(updatedTreeData);
    setOpenCounter(false);
    const selBranchId = `branch_`+split_[1];
    setSelectedNodeId(selBranchId);
    setCounterName('');
   }

    const handleAdd = () => {
      const newBranchId = `branch_${treeData.length + 1}`;
      const newBranchLabel = branchName;
      const newBranch = {
        id: newBranchId,
        label: newBranchLabel,
        children: [],
      };
      setTreeData([...treeData, newBranch]);
      handleClose();
      setSelectedNodeId(newBranchId);
      setBranchName('');
     }
  
    const handleCreateBranch = () => {
        setOpen(true);
    };

    const handleNodeSelect = (event, nodeIds) => {
        selectedNodeIdRef.current = nodeIds;
        setSelectedNodeId(selectedNodeIdRef.current);
      };
  
    const handleAddCounter = () => {
      
        const selectedNodeId = selectedNodeIdRef.current;
        setSelectedNodeId(selectedNodeId);
        if(selectedNodeId){

          let split_ = selectedNodeId.split('_');
          if(split_[0] === 'counter'){
            alert("Please select a Branch to add a counter to!");
          }else{
            setOpenCounter(true);
          }

        }else{
          alert("Please select a Branch to add a counter to!");
        }


    };

    const handleDeleteNode = (nodeId) => {
      const removeNodeRecursively = (nodes, idToRemove) => {
        return nodes.reduce((acc, node) => {
          if (node.id === idToRemove) {
            return acc;
          }
          if (node.children) {
            const updatedChildren = removeNodeRecursively(node.children, idToRemove);
            if (updatedChildren.length > 0) {
              acc.push({ ...node, children: updatedChildren });
            }
          } else {
            acc.push(node);
          }
          return acc;
        }, []);
      };
    setTreeData(removeNodeRecursively(treeData, nodeId));
    };




return (<Box elevation={1}>

<Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            console.log(email);
            handleClose();
          },
        }}
      >
        <DialogTitle>Branch name</DialogTitle>
        <DialogContent>
          <DialogContentText>
           Branch is an independent business entity that may have one or several POS terminals,
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="branch_name"
            name="branch_name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            value={branchName}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="button" onClick={handleAdd}>Add</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openCounter}
        onClose={handleCloseCounter}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {

          },
        }}
      >
        <DialogTitle>Counter / POS Terminal name</DialogTitle>
        <DialogContent>
          <DialogContentText>
           This specifies a terminal (counter) where items are to be checked out.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="counter_name"
            name="counter_name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            value={counterName}
            onChange={handleCounterChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCounter}>Cancel</Button>
          <Button type="button" onClick={handleCounterAdd}>Add</Button>
        </DialogActions>
      </Dialog>

        <Grid>
            <Grid>
             <Item>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Item>
                                <FormControl
                                    variant="standard"
                                    style={{ width: '100%' }}
                                    error={Boolean(errors.customerName && touched.customerName)}
                                    >
                                    <InputLabel htmlFor="business-name">Customer full name</InputLabel>
                                    <Field
                                        name="customerName"
                                        as={Input}
                                        id="customer-name"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <PersonAdd />
                                            </InputAdornment>
                                        }
                                    />
                                    <ErrorMessage name="customerName" component={FormHelperText} />
                                </FormControl>
                            </Item>
                        </Grid>
                    </Grid>
                </Item>
                <Item>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Item>
                                <FormControl
                                    variant="standard"
                                    style={{ width: '100%' }}
                                    error={Boolean(errors.businessName && touched.businessName)}
                                >
                                    <InputLabel htmlFor="business-name">Business name or company</InputLabel>
                                    <Field
                                        name="businessName"
                                        as={Input}
                                        id="business-name"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <AddHomeWork />
                                            </InputAdornment>
                                        }
                                    />
                                    <ErrorMessage name="businessName" component={FormHelperText} />
                                </FormControl>
                            </Item>
                        </Grid>
                    </Grid>
                </Item>
                <Item>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Item>
                                <FormControl
                                    variant="standard"
                                    style={{ width: '100%' }}
                                    error={Boolean(errors.businessLocation && touched.businessLocation)}
                                >
                                    <InputLabel htmlFor="business-location">Business location</InputLabel>
                                    <Field
                                        name="businessLocation"
                                        as={Input}
                                        id="business-location"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <AddLocation />
                                            </InputAdornment>
                                        }
                                    />
                                    <ErrorMessage name="businessLocation" component={FormHelperText} />
                                </FormControl>
                            </Item>
                        </Grid>
                    </Grid>
                </Item>
                <Item>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Item>
                                <FormControl
                                    variant="standard"
                                    style={{ width: '100%' }}
                                    error={Boolean(errors.pin && touched.pin)}
                                >
                                    <InputLabel htmlFor="pin">Pin</InputLabel>
                                    <Field
                                        name="pin"
                                        as={Input}
                                        id="pin"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <RequestPage />
                                            </InputAdornment>
                                        }
                                    />
                                    <ErrorMessage name="pin" component={FormHelperText} />
                                </FormControl>
                            </Item>
                        </Grid>
                    </Grid>
                </Item>
                <Item>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Item>
                                <FormControl
                                    variant="standard"
                                    style={{ width: '100%' }}
                                    error={Boolean(errors.telephone && touched.telephone)}
                                >
                                    <InputLabel htmlFor="telephone">Telephone</InputLabel>
                                    <Field
                                        name="telephone"
                                        as={Input}
                                        id="telephone"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Tty />
                                            </InputAdornment>
                                        }
                                    />
                                    <ErrorMessage name="telephone" component={FormHelperText} />
                                </FormControl>
                            </Item>
                        </Grid>
                        <Grid item xs={6}>
                            <Item>
                                <FormControl
                                    variant="standard"
                                    style={{ width: '100%' }}
                                    error={Boolean(errors.email && touched.email)}
                                >
                                    <InputLabel htmlFor="email">Email</InputLabel>
                                    <Field
                                        name="email"
                                        as={Input}
                                        id="email"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Email />
                                            </InputAdornment>
                                        }
                                    />
                                    <ErrorMessage name="email" component={FormHelperText} />
                                </FormControl>
                            </Item>
                        </Grid>
                    </Grid>


                    <Grid container spacing={1}>
                    <Grid item xs={12} style={{ marginTop: '1%' }}>
                        <ButtonGroup variant="outlined" aria-label="Loading button group">
                        <Button onClick={handleCreateBranch}>Create a new Branch</Button>
                        <Button onClick={handleAddCounter}>Add a Point of sale terminal (Counter)</Button>
                        <Button
                          size="small"
                          color="error"
                          onClick={(e) => {
                            e.stopPropagation(); 

                            if (selectedNodeIdRef.current) {
                              handleDeleteNode(selectedNodeIdRef.current);
                              setSelectedNodeId(null); 
                            } else {
                              alert('No node selected for deletion');
                            }                  
                          }}
                        >
                          <DeleteIcon />
                          Delete
                        </Button>
                        </ButtonGroup>
                        

                            <RichTreeView
                            items={treeData}
                            selectedItems={selectedNodeId}
                            onSelectedItemsChange={handleNodeSelect}
                            multiSelect={false}
                            aria-label="TreeView"
                            slots={{ item: TreeItem2 }}
                            />
                    </Grid>
                    </Grid>

                </Item>
            </Grid>
        </Grid>
    </Box>
  );
};
export default BusinessInfo;