
import React, { useRef , useState , useEffect  } from 'react';
import { Alert, Button, Stack, Container, 
  TextField, Typography, Box, Paper, Grid 
  ,FormControl,InputAdornment,FormHelperText,Divider,Autocomplete,
  Dialog,DialogTitle,DialogContent,DialogContentText,createFilterOptions,
  Modal,IconButton   
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Add from '@mui/icons-material/Add';
import AttachMoney from '@mui/icons-material/AttachMoney';
import Print from '@mui/icons-material/Print';
import ReceiptIcon from '@mui/icons-material/Receipt';

import { Formik, Field, Form, ErrorMessage, useField } from "formik";
import * as Yup from "yup";
import axios from 'axios';

import ReportMain from './back-end/reports/Reports';
import CloseIcon from '@mui/icons-material/Close';

const filter = createFilterOptions();

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  textAlign: 'left',
  color: theme.palette.text.secondary,
  padding: theme.spacing(2),
}));

const CondensedButton = styled(Button)(({ theme }) => ({
  fontSize: '12px',
  margin: '0px',
  padding: '5px 0px',
  width: '100%',
  textTransform: 'none',
}));

const CondensedTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '18px',
    padding: '4px 4px',
    height: '30px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '1px',

    },
  },
  margin: 0,
  '& input': {
    textAlign: 'right',
  },
}));

const CondensedTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  display: 'flex',
  alignItems: 'center',
  height: '30px',
  marginLeft: '5px',
}));

const CondensedAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '0.875rem',
    padding: '4px 8px',
    height: '30px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '1px',
    },
  },
  margin: 0,
  '& input': {
    textAlign: 'center',
  },
}));

const Invoice = ({ invoiceId, setInvoiceId , customerId, totalSection , setTotalSection , userDetails , setCartValues , setCustomerId , setSearchValue ,cartValues = [] }) => {

  const [customerData, setCustomerData] = useState({
    fcustomer_name: customerId?.name?.name || "",
    fphone: customerId?.name?.phone || "",
    femail: customerId?.name?.email || "",
    faddress: customerId?.name?.address || "",
    fnotes:""
  });

  const [paymentAmount, setPaymentAmount] = useState(0);
  const [totalpaid, setTotalpaid] = useState(0);
  const [notes, setNotes] = useState("");

  const [open, toggleOpen] = React.useState(false);
  const [accounts, setAccounts] = useState([]);
  const [value, setValue] = React.useState({
    name: '',
    id:0,
  });


const [openItem, setOpenItem] = React.useState(false);
const handleCloseItem = () => {
  setOpenItem(false);
};
const handleClickOpen = () => setOpenItem(true);

const handleClose = () => {
  setDialogValue({
    title: '',
    year: '',
  });
  toggleOpen(false);
};

const [dialogValue, setDialogValue] = React.useState({
  title: '',
  year: '',
});

  const grandTotal = parseFloat(totalSection['grandTotal']);
  const taxValue = parseFloat(totalSection['taxValue']);
  const subTotal = parseFloat(totalSection['subTotal']);

  const [balance, setBalance] = useState(0);

  useEffect(() => {
    setBalance(parseFloat(totalSection['grandTotal']));
  }, [totalSection]);
 
  useEffect(() => {
    if (customerId?.name) {
      setCustomerData({
        fcustomer_name: customerId.name.name || "",
        fphone: customerId.name.phone || "",
        femail: customerId.name.email || "",
        faddress: customerId.name.address || "",
      });
    }
  }, [customerId]);

  const validationSchema = Yup.object().shape({
    fcustomer_name: Yup.string().required("Customer name is required"),
    fphone: Yup.string().required("Customer phone is required"),
    femail: Yup.string().required("Email is required"),
    faddress: Yup.string().required("Customer address is required"),
  });

  const handlePaymentChange = (e) => {
    const payment = parseFloat(e.target.value) || 0;
    setPaymentAmount(payment);
    setTotalpaid(payment);
    setBalance((grandTotal - payment).toFixed(2));
  };


  const handleFinish = async (validateForm, setTouched, values) => {
    try {
      values.payment_account = value?.id;
      const response = await axios.post(`/ui/invoice/create`, {
        values,
        employee_id:userDetails?.id,
        customer_id:customerId.id,
        cart:cartValues,
        grand_total:grandTotal,
        sub_total:subTotal,
        total_products : cartValues.reduce((sum, item) => sum + item?.quantity, 0),
        vat:taxValue,
        total_paid:totalpaid,
        balance:balance
      });
      if(response.data.bool){
        setInvoiceId(response.data.id);
        alert(response.data.message);        
        setCartValues([]);
        setSearchValue(null);
        setTotalSection([]);
        setCustomerId({name: 'Default Customer', id:1});
        handlePdfPrint();        
      }

    } catch (error) {
      console.error('Error creating a new account type:', error);
    } finally {
  }
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const [dopen, setdOpen] = React.useState(false);
const [modalContent, setModalContent] = useState('');
const handledOpen = () => setdOpen(true);
const handledClose = () => {
  setdOpen(false);
} 
const handlePdfPrint = async () => {
  setModalContent('print_pdf');
  handledOpen();
}
const handleBackdropClick = (event) => {
  event.stopPropagation();
};


useEffect(() => {
  const fetchAccounts = async () => {
    try {
      const response = await axios.post(`/accounts`, {
      });
      const activeAccounts = response.data.filter(account => account.status === 1);
      setAccounts(activeAccounts);
    } catch (error) {
      console.error('Error fetching accounts:', error);
    }
  };
  fetchAccounts();
}, []); 


  return (
    <Container maxWidth="sm">
      <Formik
        initialValues={customerData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log("Form submitted successfully", values);
        }}
      >
        {({
          errors,
          touched,
          validateForm,
          setFieldValue,
          setTouched,
          handleBlur,
          values,
        }) => (
          <Form>
            <Box sx={{ flexGrow: 1 }}>
              <Stack spacing={2}>
                <Item>
                  <Typography variant="h6" gutterBottom>
                    Customer Information
                  </Typography>

                  <FormControl
                    variant="standard"
                    style={{ width: "100%" }}
                    error={Boolean(
                      touched.fcustomer_name && errors.fcustomer_name
                    )}
                  >
                    <CondensedTextField
                      name="fcustomer_name"
                      variant="outlined"
                      read-only="true"
                      value={values.fcustomer_name}
                      onChange={(e) =>
                        setFieldValue("fcustomer_name", e.target.value)
                      }
                      onBlur={handleBlur}
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                    />
                    <ErrorMessage name="fcustomer_name">
                      {(msg) => <FormHelperText>{msg}</FormHelperText>}
                    </ErrorMessage>
                  </FormControl>

                  <FormControl
                    variant="standard"
                    style={{ width: "100%", marginTop: "2%" }}
                    error={Boolean(touched.fphone && errors.fphone)}
                  >
                    <CondensedTextField
                      name="fphone"
                      variant="outlined"
                      value={values.fphone}
                      onChange={(e) => setFieldValue("fphone", e.target.value)}
                      onBlur={handleBlur}
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                    />
                    <ErrorMessage name="fphone">
                      {(msg) => <FormHelperText>{msg}</FormHelperText>}
                    </ErrorMessage>
                  </FormControl>


                  <FormControl
                    variant="standard"
                    style={{ width: "100%", marginTop: "2%" }}
                    error={Boolean(touched.femail && errors.femail)}
                  >
                    <CondensedTextField
                      name="femail"
                      variant="outlined"
                      value={values.femail}
                      onChange={(e) => setFieldValue("femail", e.target.value)}
                      onBlur={handleBlur}
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                    />
                    <ErrorMessage name="femail">
                      {(msg) => <FormHelperText>{msg}</FormHelperText>}
                    </ErrorMessage>
                  </FormControl>


                  <FormControl variant="standard" style={{ width: '100%',marginTop:'2%' }} error={Boolean(touched.faddress && errors.faddress)}>
                        <Field
                        as={TextField}
                        name="faddress"
                        id="outlined-multiline-static"
                        label="Customer address"
                        multiline
                        rows={2}
                        value={values.faddress}
                        onChange={(e) => setFieldValue('faddress', e.target.value)}
                        onBlur={handleBlur}
                        placeholder="Customer address"
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        />
                        <ErrorMessage name="faddress">
                        {msg => <FormHelperText>{msg}</FormHelperText>}
                        </ErrorMessage>
                  </FormControl>

                </Item>

                <Item>
                  <Typography variant="h6" gutterBottom>
                    Invoice Details
                  </Typography>
                  <CondensedTypography>
                    Total Items: {cartValues.reduce((sum, item) => sum + item?.quantity, 0)}
                  </CondensedTypography>
                  <Divider/>
                  <CondensedTypography>
                    Sub Total: {subTotal.toFixed(2)}
                  </CondensedTypography>
                  <CondensedTypography>
                    Tax: {taxValue.toFixed(2)}
                  </CondensedTypography>
                  <CondensedTypography>
                    Total Payable: {grandTotal.toFixed(2)}
                  </CondensedTypography>

                  <FormControl variant="standard" style={{ width: '100%',marginTop:'2%' }} error={Boolean(touched.fnotes && errors.fnotes)}>
                        <Field
                        as={TextField}
                        name="fnotes"
                        id="outlined-multiline-static"
                        label="Notes"
                        multiline
                        rows={2}
                        value={values.fnotes}
                        onChange={(e) => setFieldValue('fnotes', e.target.value)}
                        onBlur={handleBlur}
                        placeholder="Notes"
                        InputProps={{
                          readOnly: false,
                        }}
                        fullWidth
                        />
                        <ErrorMessage name="fnotes">
                        {msg => <FormHelperText>{msg}</FormHelperText>}
                        </ErrorMessage>
                  </FormControl>
                </Item>

                <Item>
                  <Typography variant="h6" gutterBottom>
                    Payment Details
                  </Typography>

                  <React.Fragment>
                                    <CondensedTypography>Payment account *</CondensedTypography>
                                    <CondensedAutocomplete
                                      //value={userDetails?.name}
                                      onChange={(event, newValue) => {
                                        setValue(newValue);
                                        if (typeof newValue === 'string') {
                                          setTimeout(() => {
                                            toggleOpen(true);
                                            setDialogValue({
                                              name: newValue,
                                              year: '',
                                            });
                                          });
                                        } else if (newValue && newValue.inputValue) {
                                          toggleOpen(true);
                                          setDialogValue({
                                            name: newValue.inputValue,
                                            year: '',
                                          });
                                        } else {
                                          setValue(newValue);
                                        }
                                      }}
                                      filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                                        if (params.inputValue !== '') {
                                          filtered.push({
                                            inputValue: params.inputValue,
                                            account_name: `Add "${params.inputValue}"`,
                                          });
                                        }
                                        return filtered;
                                      }}
                                      id="free-solo-dialog-demo"
                                      options={accounts}
                                      getOptionLabel={(option) => {
                                        if (typeof option === 'string') {
                                          return option;
                                        }
                                        if (option.inputValue) {
                                          return option.inputValue;
                                        }
                                        return option.account_name;
                                      }}
                                      selectOnFocus
                                      clearOnBlur
                                      handleHomeEndKeys
                                      renderOption={(props, option) => {
                                        const { key, ...optionProps } = props;
                                        return (
                                          <li key={key} {...optionProps}>
                                            {option.account_name}
                                          </li>
                                          );
                                      }}
                                      
                                      freeSolo
                                      renderInput={(params) => <CondensedTextField  {...params} label="" />}
                                    />

                                          <Dialog open={open} onClose={handleClose}>
                                              <DialogTitle>Add a new account</DialogTitle>
                                              <DialogContent>
                                                  <DialogContentText>
                                                      Did you miss any account in our list? Please, add it!
                                                  </DialogContentText>
                                                      {/* <AddCustomer name={dialogValue.name} handleCloseItem={handleClose} /> */}
                                                </DialogContent>
                                          </Dialog>
                                  </React.Fragment>
                  <CondensedTextField
                    label="Payment Amount"
                    fullWidth
                    value={paymentAmount}
                    onChange={handlePaymentChange}
                    size="small"
                    sx={{ mt: 1 }}
                  />
                  <CondensedTypography>Balance: {balance}</CondensedTypography>
                </Item>

                <Item>
                  <Stack direction="row" spacing={1} justifyContent="center">
                  <CondensedButton variant="contained" color="primary"
                              startIcon={<ReceiptIcon />} onClick={async () => {
                          const isValid = await validateForm();
                          if (Object.keys(isValid).length === 0) {
                                handleFinish(validateForm, setTouched, values);
                          } else {
                          setTouched({
                            fcustomer_name: true,
                            fphone:true,
                            femail:true,
                            faddress:true,
                            fnotes:false
                          });
                          }
                      }}
                  >
                  Create Invoice
                </CondensedButton>
                <CondensedButton
                        variant="outlined"
                        color="secondary"
                        startIcon={<Print />}
                        onClick={async () => {
                          handlePdfPrint();
                        }}
                        disabled={!invoiceId}
                      >
                        Print Invoice
                </CondensedButton>
                  </Stack>
                </Item>
              </Stack>
            </Box>
          </Form>
        )}
      </Formik>
      <Modal
                open={dopen}
                onClose={handledClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropProps={{
                    onClick: handleBackdropClick,
                }}
              >
                <Box sx={{ ...style, backgroundColor: '#ffffffd4', position: 'relative' , width:'60%' , height: 'auto' }}>
                <IconButton
                        aria-label="close"
                        onClick={handledClose}
                        sx={{
                            position: 'absolute',
                            top: -20,
                            right: -20,
                            color: 'red',
                            backgroundColor: 'white',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 0, 0, 0.1)',
                            },
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {modalContent === 'print_pdf' && <ReportMain report_name={'invoice'} data={invoiceId}/>}
                </Box>
            </Modal>
    </Container>
  );
};

export default Invoice;


