import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useNavigate  } from 'react-router-dom';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import CalculateIcon from '@mui/icons-material/Calculate';
import FitScreenIcon from '@mui/icons-material/FitScreen';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import Badge from '@mui/material/Badge';
import { Card, CardContent } from '@mui/material';


import Calc from './restaurant/Calc'


import Popper from '@mui/material/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';

const logoStyle = {
  width: '140px',
  height: 'auto',
  cursor: 'pointer',
};

function AppAppBar({ mode , fullScreen={fullScreen}, registerDetails={registerDetails}, closeRegister={closeRegister}, cart={cart}, toggleColorMode , userDetails , setUserDetails , handleOpen }) {

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };


  const BusinessLicenseCard = ({ details }) => { 

    return (
      <Paper elevation={3} style={{ padding: '20px', margin: '20px' }}>
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              {details.businessName}
            </Typography>
  
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              {details.businessLocation}
            </Typography>
            <Divider/>
            <Typography variant="subtitle2" gutterBottom>
              <strong>Business id:</strong> {details.business_id}
            </Typography>
  
            <Typography variant="subtitle2" gutterBottom>
              <strong>Category:</strong> {details.category}
            </Typography>
  
            <Typography variant="subtitle2" gutterBottom>
              <strong>Company:</strong> {details.company}
            </Typography>
  
            <Typography variant="subtitle2" gutterBottom>
              <strong>Email:</strong> {details.email}
            </Typography>
  
            <Typography variant="subtitle2" gutterBottom>
              <strong>Expiration date:</strong> {details.expiration_date}
            </Typography>
  
            <Typography variant="subtitle2" gutterBottom>
              <strong>License name:</strong> {details.license_name}
            </Typography>
  
            <Typography variant="subtitle2" gutterBottom>
              <strong>License email:</strong> {details.license_email}
            </Typography>
  
            <Typography variant="subtitle2" gutterBottom>
              <strong>Software:</strong> {details.licensed_to}
            </Typography>
  
            <Typography variant="subtitle2" gutterBottom>
              <strong>Manufacturer:</strong> {details.manufacturer}
            </Typography>
  
            <Typography variant="subtitle2" gutterBottom>
              <strong>Notes:</strong> {details.notes}
            </Typography>
  
            <Typography variant="subtitle2" gutterBottom>
              <strong>PIN:</strong> {details.pin}
            </Typography>
  
            <Typography variant="subtitle2" gutterBottom>
              <strong>Supplier:</strong> {details.supplier}
            </Typography>
  
            <Typography variant="subtitle2" gutterBottom>
              <strong>Suppler phone:</strong> {details.telephone}
            </Typography>
          </CardContent>
        </Card>
      </Paper>
    );
  };




  //console.log(cart);

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
      setOpen(false);
    }
  };

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
        },
      }),
    [mode]
  );


  const handleLogout = async () => {
    try {
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
        const response = await axios.post('/ui/logout');
        if (response.data.bool) {
            setUserDetails(null);
            navigate('/');
            handleOpen();
        } else {
            console.error(response.data.message);
        }
    } catch (error) {
        console.error('Error logging out:', error);
    }
};

const handleNavigation = (cpath) => {
   navigate(cpath);
};

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 2,
        }}
      >
        <Container maxWidth={false} disableGutters>
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
             // borderRadius: '999px',
              bgcolor:
                theme.palette.mode === 'light'
                  ? 'rgba(255, 255, 255, 0.4)'
                  : 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(24px)',
              maxHeight: 40,
              border: '1px solid',
              borderColor: 'divider',
              boxShadow:
                theme.palette.mode === 'light'
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                ml: '-18px',
                px: 0,
              }}
            >
              <img
                src={
                  './images/Plannettech-Logo-1.png'
                }
                style={logoStyle}
                alt="Logo"
              />
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <MenuItem
                  onClick={() => handleNavigation('/')}
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography variant="body2" color="text.primary">
                    POS
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => handleNavigation('/customer-list')}
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography variant="body2" color="text.primary">
                    Customers
                  </Typography>
                </MenuItem>
                <MenuItem
                   onClick={() => handleNavigation('/items')}
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography variant="body2" color="text.primary">
                    Items
                  </Typography>
                </MenuItem>
                <MenuItem
                   onClick={() => handleNavigation('/supplier-list')}
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography variant="body2" color="text.primary">
                    Suppliers
                  </Typography>
                </MenuItem>
                <MenuItem
                   onClick={() => handleNavigation('/reports')}
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography variant="body2" color="text.primary">
                    Reports
                  </Typography>
                </MenuItem>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 0.5,
                alignItems: 'center',
              }}
              >

             {userDetails ? (<>
              <span style={{fontSize:'11px'}}>Logged in as {userDetails?.name} | {userDetails?.branch} | {userDetails?.counter}</span>

              </>
              ) : (
              <></>
              )}


              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

              <IconButton size="large" aria-label="show 4 new mails" color="inherit" onClick={fullScreen}>
                  <FitScreenIcon />&nbsp;
                <span style={{fontSize:'11px',color:'orange',fontWeight:'900'}}>Fullscreen</span>
              </IconButton>


              <IconButton size="large" aria-label="show 4 new mails" color="inherit" onClick={registerDetails}>
                <ReceiptIcon />&nbsp;
               <span style={{fontSize:'11px',color:'orange',fontWeight:'900'}}>Register Details</span>
              </IconButton>


              <IconButton size="large" aria-label="show 4 new mails" color="inherit" onClick={closeRegister}>
                <DisabledByDefaultIcon />&nbsp;
               <span style={{fontSize:'11px',color:'orange',fontWeight:'900'}}>Close Register</span>
              </IconButton>


              <IconButton size="large" aria-label="show 4 new mails" color="inherit">
              <PopupState variant="popper" popupId="demo-popup-popper">
                {(popupState) => (
                  <div style={{marginBottom:'5px'}}>
                    <div {...bindToggle(popupState)}><CalculateIcon />
                    <span style={{fontSize:'11px',color:'orange',fontWeight:'900'}}></span></div>

                    <Popper {...bindPopper(popupState)} transition style={{zIndex:10000}}>
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                          <Paper>
                             <Calc/>
                          </Paper>
                        </Fade>
                      )}
                    </Popper>
                  </div>
                )}
              </PopupState>
            </IconButton>


            <IconButton size="large" aria-label="show 4 new mails" color="inherit">
              <PopupState variant="popper" popupId="demo-popup-popper">
                {(popupState) => (
                  <div style={{marginBottom:'5px'}}>
                    <div {...bindToggle(popupState)}><QrCodeScannerIcon />
                    <span style={{fontSize:'11px',color:'orange',fontWeight:'900'}}></span></div>

                    <Popper {...bindPopper(popupState)} transition style={{zIndex:10000}}>
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                          <Paper>
                              <BusinessLicenseCard details={userDetails?.business[0]}/>
                          </Paper>
                        </Fade>
                      )}
                    </Popper>
                  </div>
                )}
              </PopupState>
            </IconButton>


            <IconButton size="large" aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={cart?.length} color="error">
                <ProductionQuantityLimitsIcon />
              </Badge>
            </IconButton>

            {userDetails ? (<>
                  <Button
                  color="secondary"
                  variant="text"
                  size="small"
                  component="a"
                  target="_blank"
                  onClick={handleLogout}
                  >
                  Log Out
                </Button>
              </>
              ) : (
                  <Button
                      color="primary"
                      variant="text"
                      size="small"
                      component="a"
                      href="/login"
                      target="_blank"
                  >
                      User Login
                  </Button>
              )}

              <Button
                color="primary"
                variant="text"
                size="small"
                component="a"
                href="/login"
                target="_blank"
              >
                Admin Login
              </Button>






              </Box>

              {/* <Button
                color="primary"
                variant="contained"
                size="small"
                component="a"
                href="/register"
                target="_blank"
              >
                Sign up
              </Button> */}

            </Box>
            <Box sx={{ display: { sm: '', md: 'none' } }}>
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: '30px', p: '4px' }}
              >
                <MenuIcon />
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: '60dvw',
                    p: 2,
                    backgroundColor: 'background.paper',
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'end',
                      flexGrow: 1,
                    }}
                  >
                    {/* <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} /> */}
                  </Box>
                  <MenuItem onClick={() => scrollToSection('features')}>
                    Features
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection('testimonials')}>
                    Testimonials
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection('highlights')}>
                    Highlights
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection('pricing')}>
                    Pricing
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection('faq')}>FAQ</MenuItem>
                  <Divider />
                  <MenuItem>
                    <Button
                      color="primary"
                      variant="contained"
                      component="a"
                      href="/material-ui/getting-started/templates/sign-up/"
                      target="_blank"
                      sx={{ width: '100%' }}
                    >
                      Sign up
                    </Button>
                  </MenuItem>
                  <MenuItem>
                    <Button
                      color="primary"
                      variant="outlined"
                      component="a"
                      href="/material-ui/getting-started/templates/sign-in/"
                      target="_blank"
                      sx={{ width: '100%' }}
                    >
                      Sign in
                    </Button>
                  </MenuItem>
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;
