import React, { useEffect , useState ,useRef  } from 'react';
import { InputLabel, FormControl , NativeSelect  , createFilterOptions , DialogContentText ,DialogActions, Autocomplete , TextField, Typography, Box, Paper, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Add from '@mui/icons-material/Add';
import AttachMoney from '@mui/icons-material/AttachMoney';
import AvTimer from '@mui/icons-material/AvTimer';
import Search from '@mui/icons-material/Search';
import Grid from '@mui/material/Unstable_Grid2';
import SuspendedDialog from './SuspendedDialog';
import SalesDialog from './SalesDialog';
import axios from 'axios';
import dayjs from 'dayjs';

const filter = createFilterOptions();

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

const CondensedNativeSelect = styled(NativeSelect)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '0.875rem',
    padding: '4px 8px', 
    height: '30px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '1px',
      textAlign:'right',
    },
  },
  marginRight: '10px',
  marginLeft: '10px',
  fontSize: '0.875rem',
  padding: '0px 2px', 
  width:'200px',
  '& input': {
    textAlign: 'right',  
  },
}));

const CondensedTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: '900',
  display: 'flex',
  height: '30px',
  marginLeft: '5px',
}));

const CondensedButton = styled(Button)(({ theme }) => ({
    fontSize: '12px',
    padding: '5px 10px',
    float: 'right',
    textTransform: "none"
  }));

const CondensedAutocomplete = styled(Autocomplete)(({ theme }) => ({
    '& .MuiInputBase-root': {
      fontSize: '0.875rem',
      padding: '4px 8px',
      height: '30px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderWidth: '1px',
      },
    },
    margin: 0,
    '& input': {
      textAlign: 'center',
    },
  }));

const RegisterMode = ({ transactionType , setransactionType ,  onToggleDrawer , setCartValues , suspendedOrders, setSuspendedOrders }) => {

const [value, setValue] = React.useState(null);
const [open, toggleOpen] = React.useState(false);
const [dialogOpen, setDialogOpen] = useState(false);
const [salesDialogOpen, setSalesDialogOpen] = useState(false);

const [salesItems, setSalesItemsItems] = useState([]);
const today = dayjs().startOf('day');
const tomorrow = dayjs().add(1, 'day').startOf('day');
const [selectedDate, setSelectedDate] = useState([today, tomorrow]);


const formatDate = (date) => {
  return date ? dayjs(date).format('YYYY-MM-DD') : '';
};
const fetchData = async () => {
  try {
    const response = await axios.post(`/ui/sale-items`, {
      start_date: formatDate(selectedDate[0]),
      end_date: formatDate(selectedDate[1])
    });
    setSalesItemsItems(response.data);
  } catch (error) {
    console.error('Error fetching sale items:', error);
  } finally {
  }
}

const handleOpenSuspended = () => {
  setDialogOpen(true);
};

const handleCloseSuspended = () => {
  setDialogOpen(false);
};



  const handleClose = () => {
    setDialogValue({
      title: '',
      year: '',
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    title: '',
    year: '',
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setValue({
      title: dialogValue.title,
      year: parseInt(dialogValue.year, 10),
    });
    handleClose();
  };


  const handleSuspended = () => {
    handleOpenSuspended();
  };

  const handleDailySales = () => {
     fetchData();
     setSalesDialogOpen(true);
  };

  const handleCloseSalesDialog = () => {
    setSalesDialogOpen(false);
  };

 const handleChange = (event) => {
     setransactionType(event.target.value);
  };
  


  return (
      <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0} alignItems="left">
            <Grid xs={2} sx={{ padding: "1px" }}>
              <CondensedTypography style={{fontStyle:'normal'}}>Register Mode :</CondensedTypography>
            </Grid>
              <Grid xs={2} sx={{ padding: "1px" }}>

                    <CondensedNativeSelect
                        value={transactionType}
                        onChange={handleChange}
                        inputProps={{
                            name: 'type',
                            id: 'transactionType',
                        }}
                    >
                        <option value={1}>Sales Receipt</option>
                        <option value={2}>Quote</option>
                        <option value={3}>Work Order</option>
                        <option value={4}>Invoice</option>
                        <option value={5}>Return</option>
                    </CondensedNativeSelect>
 
             </Grid>





            <Grid xs={1} sx={{ padding: "1px" }}>
            </Grid>
          
            <Grid xs={7} sx={{ padding: "0px" }}>
                  <CondensedButton style={{marginLeft:"2px"}} variant="outlined" startIcon={<AvTimer />} onClick={handleSuspended}>
                        Suspended
                   </CondensedButton>
                   <CondensedButton style={{marginLeft:"2px"}} variant="outlined" startIcon="(KSh)" onClick={handleDailySales}>
                        Daily Sales
                   </CondensedButton>
                   <CondensedButton  variant="outlined" startIcon={<Search />} onClick={onToggleDrawer}>
                        Products
                   </CondensedButton>
            </Grid>
          </Grid>

          <SuspendedDialog
            open={dialogOpen}
            onClose={handleCloseSuspended}
            setCartValues={setCartValues}
            suspendedOrders={suspendedOrders}
            setSuspendedOrders={setSuspendedOrders}
          />
          <SalesDialog
            open={salesDialogOpen}
            onClose={handleCloseSalesDialog}
            setCartValues={setCartValues}
            salesItems={salesItems}
            setSalesItemsItems={setSalesItemsItems}
          />
      </Box>
  );
};

export default RegisterMode;

