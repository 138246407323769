import { Routes, Route } from 'react-router-dom';
import { useLocation , useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { IconButton , Popper, Fade  , Card,CardMedia ,Modal, Dialog, DialogActions, DialogContent, DialogTitle, Alert, Container, TextField, Typography, Box, Paper } from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import CloseIcon from '@mui/icons-material/Close';

import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import MenuToolBar from './MenuToolBar';
import Home from './Home';
import Menu from './Menu';
import Payment from './Payment';
import Orders from './Orders';
import ProductSearch from './ProductSearch';
import Settings from './Settings';
import RegisterDetails from './RegisterDetails';
import CashAtHand from './CashAtHand';
import RoomReservation from './RoomReservation';
import LicenceExpiry  from './LicenceExpiry';

import axios from 'axios';
import { WindowSharp } from '@mui/icons-material';

const drawerWidth = 140;

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.container,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const generateOrderId = () => {
    const hexPart = Math.floor(Math.random() * 0x100000)
        .toString(16) 
        .padStart(5, '0');
    return `OD-${hexPart.toUpperCase()}`;
};

const Index = () => {
    const location = useLocation();
    const user = location.state?.user;
    const [selectedComponent, setSelectedComponent] = useState('Home');
    const [furnituredata, setFurnituredata] = useState([]);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [categoryid, setCategoryId] = useState(null);
    const [categorydata, setCategorydata] = useState([]);
    const [categorytab, setCategorytab] = useState(0);
    const [searchterm, setSearchterm] = useState("");
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [open, setOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [registerdata, setRegisterdata] = useState([]);

    const [forceClose, setForceClose] = useState(false);

    const [mopen, setmOpen] = useState(false);
    const handlemOpen = () => setmOpen(true);
    const handlemClose = () =>{ 
        setmOpen(false);
        setForceClose(true);
        closeRegister();
    }
    const [alertInfo, setAlertInfo] = useState({ show: false, severity: '', message: '' });

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleBackdropClick = (event) => {
        event.stopPropagation();
    };

    const [cart, setCart] = useState(() => ({
        order_id: generateOrderId(),
        tableids:0,
        table: "Table 0",
        guests: 0,
        items: []
    }));

useEffect(() => {
    if (!user) {
        window.location.href = '/';
    }else{
        const expiryDate = new Date(user?.business[0].expiration_date).toISOString().split('T')[0];
        const todayDate = new Date().toISOString().split('T')[0];
        if (expiryDate < todayDate) {
            setForceClose(true);
            setModalContent('expired_licence');
            handleOpen();
        }else{
            registerCheck(user);
        }
    }
}, [user]);

    const fullScreen = () => {
        const elem = document.documentElement;
        if (!document.fullscreenElement) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            }
            setIsFullscreen(true);
        } else {

            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
            setIsFullscreen(false);
        }
    };
    // useEffect(() => {
    //     const elem = document.documentElement;
    //     if (isFullscreen) {
    //         if (elem.requestFullscreen) {
    //             elem.requestFullscreen();
    //         } else if (elem.mozRequestFullScreen) {
    //             elem.mozRequestFullScreen();
    //         } else if (elem.webkitRequestFullscreen) {
    //             elem.webkitRequestFullscreen();
    //         } else if (elem.msRequestFullscreen) {
    //             elem.msRequestFullscreen();
    //         }
    //     }else{
    //         if (document.exitFullscreen) {
    //             document.exitFullscreen();
    //         } else if (document.mozCancelFullScreen) {
    //             document.mozCancelFullScreen();
    //         } else if (document.webkitExitFullscreen) {
    //             document.webkitExitFullscreen();
    //         } else if (document.msExitFullscreen) {
    //             document.msExitFullscreen();
    //         }
    //     }
    // }, []);

    const closeRegister =  () => {
        setModalContent('close_register');
        handleOpen();
    };

    const registerDetails =  () => {
        setModalContent('register_details');
        handleOpen();
    };


    const mainTabs = async () => {
        try {
            const response = await axios.post('/ui/categories', {
                parent_id: null,
                business_category_id: 3
            });
            setData(response.data);       
            setCategoryId(response.data[0]?.id);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const categoriesTabs = async (categorytab) => {
        let cdata = [];
        try {
            const response = await axios.post('/ui/categories', {
                parent_id: categorytab,
                business_category_id: 3
            });
            cdata = response.data; 
            setCategorydata(cdata); 
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
        return cdata;
    };

    useEffect(() => {
        mainTabs();
        if(categoryid > 0){
            categoriesTabs(categoryid);
        }
    }, [categoryid]);


    const restaurantFurniture = async () => {
        try {
            const response = await axios.post('/ui/furniture', {
                branch_id: 1
            });
            setFurnituredata(response.data);
        } catch (error) {
            console.error('Error fetching furniture:', error);
        }
    };

    useEffect(() => {
        restaurantFurniture();
    }, []);


    const registerCheck = async (user) => {
        try {
            const response = await axios.post('/ui/register-check', {
                id: user?.id
            });
            if(response.data.length == 0){
                setForceClose(true);
                setModalContent('cash_at_hand');
                handleOpen();
            }else{
                handleClose();
            }

            const createdAt = response.data[0]?.created_at;
            const createdDate = new Date(createdAt).toISOString().split('T')[0];
            const todayDate = new Date().toISOString().split('T')[0];
            if (createdDate !== todayDate) {
              setForceClose(true);
              setAlertInfo({
                show: true,
                severity: 'error',
                message: `The system detected you have an Open register dated ${createdDate}, Please close to proceed.`,
              });
              handlemOpen();
            }
            // console.log(createdDate +" --- "+ todayDate);
            // alert(response.data[0]?.created_at);

            setRegisterdata(response.data);
        } catch (error) {
            console.error('Error fetching register details:', error);
        }
    };

    // useEffect(() => {
    //    registerCheck(user);
    // }, [user]);




    useEffect(() => {
        document.body.style.backgroundImage = "url('./images/home-1.jpg')";
        document.body.style.backgroundSize = '100%';
        document.body.style.backgroundPosition = 'top';
        return () => {
          document.body.style.backgroundImage = '';
          document.body.style.backgroundSize = '';
          document.body.style.backgroundPosition = '';
        };
      }, []);


    const handleComponentChange = (text) => {
        setSelectedComponent(text);
    };

    const handleSearch = () => { 
        setSelectedComponent('ProductSearch');
    };

    const renderComponent = useMemo(() => {
        switch (selectedComponent) {
            case 'Home':
                return <Home user={user} cart={cart} setCart={setCart} furnituredata={furnituredata} setFurnituredata={setFurnituredata} sx={{ width: '100%' }} />;
            case 'Menu':
                return <Menu user={user} cart={cart} setCart={setCart} data={data} setData={setData} catdata={categorydata} setCategorydata={setCategorydata} categoriesTabs={categoriesTabs} categorytab={categorytab} setCategorytab={setCategorytab} sx={{ width: '100%' }} />;
            case 'Payment':
                return <Payment user={user} cart={cart} setCart={setCart} sx={{ width: '100%' }} />;
            case 'Order':
                return <Orders user={user} cart={cart} setCart={setCart} sx={{ width: '100%' }} />;
            case 'Room Reservation':
                    return <RoomReservation user={user} cart={cart} setCart={setCart} sx={{ width: '100%' }} />;
            case 'Settings':
                return <Settings user={user} cart={cart} setCart={setCart} sx={{ width: '100%' }} />;
            case 'ProductSearch':
                return <ProductSearch searchterm={searchterm} setSearchterm={setSearchterm} user={user} cart={cart} setCart={setCart} sx={{ width: '100%' }} />;
            default:
                return "Error : 403";
        }
    }, [selectedComponent, furnituredata , cart , searchterm]);

    return (
        <Container style={{ maxWidth: '100%'}}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    sx={{width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
                >
                    <MenuToolBar cart={cart}  closeRegister={closeRegister} registerDetails={registerDetails} fullScreen={fullScreen} user={user} searchterm={searchterm} setSearchterm={setSearchterm} handleSearch={handleSearch}/>
                </AppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="permanent"
                    anchor="left"
                   >
                    <Card style={{padding:'10%'}}>
                        <CardMedia
                            component="img"
                            image="./../images/Plannettech-Logo-1.png"
                            alt="Plannettech Inv. Ltd"
                            title="Plannettech Inv. Ltd"
                            sx={{ width:'auto' , height: 'auto', marginTop:'10%' }}
                        />
                    </Card>
                    <Divider />
                    <List sx={{ height: '15%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
                        {['Home', 'Menu', 'Room Reservation', 'Payment', 'Order','Settings'].map((text) => (
                            <React.Fragment key={text}>
                                <ListItem disablePadding>
                                    <ListItemButton
                                        sx={{
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            backgroundColor: selectedComponent === text ? 'black' : 'transparent',
                                            color: selectedComponent === text ? 'white' : 'black',
                                        }}
                                        onClick={() => handleComponentChange(text)}
                                    >
                                        <ListItemIcon sx={{ minWidth: 'auto' }}>
                                            <CardMedia
                                                    component="img"
                                                    alt={"Default product image"}
                                                    height="50"
                                                    image={"./images/"+text+".png"}
                                                    sx={{
                                                        width: '100%',
                                                        height: '40px',
                                                        objectFit: 'cover',
                                                    }}
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = "./images/default-product-image.png";
                                                    }}
                                                />
                                        </ListItemIcon>
                                        <ListItemText primary={text} sx={{ textAlign: 'center', marginTop: 0.5 }} />
                                    </ListItemButton>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        ))}
                    </List>
                </Drawer>
                <Box
                    component="main" style={{ width: '100%' , backgroundColor: '#ffffff87' }}
                    sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, width: '100%' }}
                >
                    <Toolbar />
                    <div style={{ flexGrow: 1, padding: '0px' }}>
                        {renderComponent}
                    </div>
                </Box>
            </Box>

            <Modal
              open={mopen}
              onClose={handlemClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              >
              <Box sx={style}>
                {alertInfo.show && (
                  <Alert variant="outlined" severity={alertInfo.severity} onClose={() => {
                    setAlertInfo({ ...alertInfo, show: false });
                    handlemClose();
                  }}>
                    {alertInfo.message}
                  </Alert>
                )}
              </Box>
            </Modal>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropProps={{
                    onClick: handleBackdropClick,
                }}
              >
                <Box sx={{ ...style, backgroundColor: '#ffffffd4', position: 'relative' , width:'60%' , height: 'auto' }}>
                {forceClose === false &&  <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            top: -20,
                            right: -20,
                            color: 'red',
                            backgroundColor: 'white',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 0, 0, 0.1)',
                            },
                        }}
                    >
                        <CloseIcon />
                    </IconButton>}
                    {modalContent === 'register_details' && <RegisterDetails type={"view"} user={user} handleCloseModal={handleClose} />}
                    {modalContent === 'close_register' && <RegisterDetails type={"close"} user={user} handleCloseModal={handleClose} />}
                    {modalContent === 'cash_at_hand' && <CashAtHand handleClose={handleClose} user={user} />}
                    {modalContent === 'expired_licence' && <LicenceExpiry handleClose={handleClose} user={user} />}
                </Box>
            </Modal>



        </Container>
    );
};

export default Index;