
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import  DrawerProducts from './DrawerProducts';
import { Typography , Grid , InputLabel, FormControl , NativeSelect } from '@mui/material';

const CondensedTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: '900',
  display: 'flex',
  alignItems: 'center',
  height: '30px',
  marginLeft: '5px',
}));

const CondensedNativeSelect = styled(NativeSelect)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '0.875rem',
    padding: '4px 8px', 
    height: '30px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '1px',
      textAlign:'right',
    },
  },
  marginRight: '10px',
  marginLeft: '10px',
  fontSize: '0.875rem',
  padding: '0px 8px', 
  width:'100%',
  '& input': {
    textAlign: 'right',  
  },
}));


const DrawerCart = React.forwardRef((props, ref  ) => {
  //console.log(props);
  const [open, setOpen] = React.useState(true);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(0);

  const toggleDrawer = (newOpen) => {
    setOpen(newOpen);
  };

  React.useImperativeHandle(ref, () => ({
    openDrawer: () => toggleDrawer(true),
    closeDrawer: () => toggleDrawer(false),
  }));


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/ui/categories`);
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }

      try {
        const response = await axios.get(`/ui/suppliers`);
        setSuppliers(response.data);
      } catch (error) {
        console.error('Error fetching suppliers:', error);
      }

    };
    fetchData();
  }, []);

  const handleCategoryChange = (event) => { 
    setSelectedCategory(event.target.value);
  };

  const handleSupplierChange = (event) => { 
    setSelectedSupplier(event.target.value);
  };

  const DrawerList = (
    <Box sx={{ width: 390, paddingTop: '10%' }} role="presentation">
      <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
        <Grid container spacing={5} alignItems="center">
          <Grid item>
              <CondensedNativeSelect
                defaultValue={0}
                inputProps={{
                  name: 'category',
                  id: 'uncontrolled-native',
                }}
                style={{maxWidth:'140px'}}
                onChange={handleCategoryChange}
              >
                <option value={0}>All categories</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </CondensedNativeSelect>
          </Grid>
          <Grid item>

          <CondensedNativeSelect
                defaultValue={0}
                inputProps={{
                  name: 'suppliers',
                  id: 'uncontrolled-native',
                }}
                onChange={handleSupplierChange}
                style={{maxWidth:'140px'}}
              >
                <option value={0}>All suppliers</option>
                {suppliers.map((suppliers) => (
                  <option key={suppliers.id} value={suppliers.id}>
                    {suppliers.name}
                  </option>
                ))}
              </CondensedNativeSelect>
          </Grid>
        </Grid>
      </Box>

      <Divider />
      <Box sx={{ width: 380, paddingTop: '2%' }} role="presentation">
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
              <DrawerProducts dloading={props.dloading}  dproducts={props.dproducts}   selectedCategory={selectedCategory} selectedSupplier={selectedSupplier} setSearchValue={props.setSearchValue} cartValues={props.cartValues} />
          </Box>
      </Box>
         
    </Box>
  );

  return (
    <Drawer
      // variant="persistent"
      open={open}
      onClose={() => toggleDrawer(false)}
    >
      {DrawerList}
    </Drawer>
  );
});
export default DrawerCart;
