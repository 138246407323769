import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import Install from './Install';
import Customers from './Customers';
import Items from './Items';
import Suppliers from './Suppliers';
import Reports from './Reports';
import Restaurant from './restaurant/Index';

function App() {
    return (
        <>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/install" element={<Install />} />
            <Route path="/customer-list" element={<Customers />} />
            <Route path="/items" element={<Items />} />
            <Route path="/supplier-list" element={<Suppliers />} />
            <Route path="/reports" element={<Reports />} />

            <Route path="/restaurant" element={<Restaurant />} />
        </Routes>
        </>
    );
}
export default App;
