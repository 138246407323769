import React, { useEffect , useState , useRef} from 'react';
import { Alert , Modal , IconButton, Container, Typography, Box, Paper, Button, Divider, Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import DeleteIcon from '@mui/icons-material/Delete';
import PaymentDialog from './PaymentDialog';
import Receipt from './Receipt';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.container,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CondensedButton = styled(Button)(({ theme }) => ({
    fontSize: '12px',
    margin: '0px',
    padding: '5px 0px',
    width: '500px',
}));

const CondensedTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        fontSize: '18px',
        padding: '4px 8px',
        height: '30px',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderWidth: '1px',
            textAlign: 'right',
        },
    },
    margin: 0,
    '& input': {
        textAlign: 'right',
    },
}));

const CondensedTypography = styled(Typography)(({ theme }) => ({
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
    height: '30px',
    marginLeft: '5px',
}));

const PaymentForm = ({ user={user}, cart = [], setCart , fetchData={fetchData} }) => {
    console.log(cart);
    const itemCount = cart?.reduce((sum, item) => sum + item.quantity, 0);
    const subtotal = cart?.reduce((sum, item) => sum + item.quantity * item.product.selling_price, 0);
    const tax = cart.reduce((acc, item) => {
        return acc + parseFloat(item.tax);
      }, 0);

    const grandTotal = subtotal + tax;
    const [totalSection, setTotalSection] = useState([]);
    const [cashSaleNo, setCashSaleNo] = React.useState(null);
    const receiptRef = useRef();
    const [cartValues, setCartValues] = useState(() => ({
        grandTotal:0,
        tax:0,
        subtotal:0,
        count: 0,
        cart: []
    }));


    const handlePrint = (invoice_id) => {
        const printContent = receiptRef.current.innerHTML;
        const printWindowWidth = 400;
        const printWindowHeight = 700;
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const left = (screenWidth / 2) - (printWindowWidth / 2);
        const top = (screenHeight / 2) - (printWindowHeight / 2);
        const printWindow = window.open('', '', `height=${printWindowHeight},width=${printWindowWidth},top=${top},left=${left}`);  
    
        printWindow.document.write('<html><head><title>Print Receipt</title>');
        printWindow.document.write('<style>');
        printWindow.document.write(`
          @media print {  }
            @page { size: 80mm auto; margin: 0; }
            body { font-size: 12px; padding: 10mm; text-transform: uppercase; }
            .centered-box {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              height: 100%;
              padding: 0;
              margin: 0;
            }
            .condensed-typography {
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 2px;       
            }
            .dotted-hr {
            width: 100%;
            border-top: 2px dotted black;
            border-bottom: none;
            border-left: none;
            border-right: none;
          }
           .two-column-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 10px;
            row-gap: 0px;
            font-size: 12px;
            margin: 0;
            padding: 0;
          }
          .four-column-grid {
            grid-template-columns: 2fr 1fr 1fr 1fr;
            row-gap: 0px;
            font-size: 12px;
            margin: 0;
            padding: 0;
            display: grid;
          }
          .bold-total-row {
            font-weight: bold;
            font-size: 18px;
          }
          .right-aligned-digits {
            text-align: right;
          }
        `);
        printWindow.document.write('</style>');
        printWindow.document.write('<script src="https://cdnjs.cloudflare.com/ajax/libs/qrcodejs/1.0.0/qrcode.min.js"></script></head><body>'); 
        printWindow.document.write(printContent);
        printWindow.document.write('<script>var qrcode = new QRCode("qrcode", {text:"'+ invoice_id +'",width: 100,height: 100,colorDark: "#000000",colorLight: "#ffffff",correctLevel: QRCode.CorrectLevel.H});</script>');
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
      }


    useEffect(() => {
        setCartValues({
            grandTotal: grandTotal,
            tax: tax,
            subtotal: subtotal,
            count: itemCount,
            cart: cart
        });
        setTotalSection({
            totalpaid: 0,
            balance: 0,
            is_receipt: false
        });
    }, [cart, grandTotal, tax, subtotal, itemCount]);

    const [dialogOpen, setDialogOpen] = useState(false);

    const [isSending, setIsSending] = useState(false);
    const [isCancelling, setIsCancelling] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [alertInfo, setAlertInfo] = useState({ show: false, severity: '', message: '' });

    const generateOrderId = () => {
      const hexPart = Math.floor(Math.random() * 0x100000)
          .toString(16) 
          .padStart(5, '0');
      return `OD-${hexPart.toUpperCase()}`;
    };

    const handleClosePayments = () => {
        setDialogOpen(false);
      };


    const  handlePrintReceipt = async () => {
        if (typeof cart.length === 'undefined'  || cart.length <= 0) {
            setAlertInfo({
              show: true,
              severity: 'error',
              message: 'Please select items to Order!'
            });
            handleOpen();
       }else{
        handlePrint(cart.order_id);
       }
    }

      const handleCancel = async () => {
        if (typeof cart.length === 'undefined'  || cart.length <= 0) {
            setAlertInfo({
              show: true,
              severity: 'error',
              message: 'Please select an Order!'
            });
            handleOpen();
       }else{
         setIsCancelling(true)
        try {
            const response = await axios.post(`/ui/restaurant-order-cancel`, {
                order_status:'pending',
                user:user,
                cart:cart,
                customer_id:1
            });
            if(response.data.bool){
                setAlertInfo({
                    show: false,
                    severity: 'info',
                    message: response.data.message
                });
              }
            } finally {
                fetchData();
                setCartValues({
                    grandTotal: 0,
                    tax: 0,
                    subtotal: 0,
                    count: 0,
                    cart: []
                });
                setIsCancelling(false);
         }
       }
    };


    const handleMakePayment = async () => {

      if (typeof cart.length === 'undefined'  || cart.length <= 0) {
            setAlertInfo({
              show: true,
              severity: 'error',
              message: 'Please select an Order row!'
            });
            handleOpen();
       }else{
        setIsSending(true)
        try {
            setDialogOpen(true);
            } finally {
            setIsSending(false);
         }
       }

    };

    return (
        <Container maxWidth={true} sx={{ width: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0} alignItems="center">
                    <Grid xs={6} sx={{ padding: "1px" }}>
                        <CondensedTypography style={{ fontSize: '30px', fontWeight: '900' }}>Order #</CondensedTypography>
                    </Grid>
                    <Grid xs={6} sx={{ padding: "1px" }}>
                        <CondensedTypography style={{ fontSize: '22px', fontWeight: '900', justifyContent: 'right' }}>{cart[0]?.order?.invoice_no}</CondensedTypography>
                    </Grid>

                    <Grid xs={12} sx={{ padding: "1px" }}>
                        <Divider />
                    </Grid>

                    <Grid xs={6} sx={{ padding: "1px" }}>
                        <CondensedTypography>Item count</CondensedTypography>
                    </Grid>
                    <Grid xs={6} sx={{ padding: "1px" }}>
                        <CondensedTextField
                            variant="outlined"
                            type="text"
                            value={itemCount}
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>

                    <Grid xs={6} sx={{ padding: "1px" }}>
                        <CondensedTypography style={{ fontWeight: '900' }}>Sub total</CondensedTypography>
                    </Grid>
                    <Grid xs={6} sx={{ padding: "1px" }}>
                        <CondensedTextField
                            variant="outlined"
                            value={subtotal.toFixed(2)}
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>

                    <Grid xs={6} sx={{ padding: "1px" }}>
                        <CondensedTypography>Tax</CondensedTypography>
                    </Grid>
                    <Grid xs={6} sx={{ padding: "1px" }}>
                        <CondensedTextField
                            variant="outlined"
                            value={tax.toFixed(2)}
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>

                    <Grid xs={6} sx={{ padding: "1px" }}>
                        <CondensedTypography style={{ fontSize: '22px', fontWeight: '900' }}>Total</CondensedTypography>
                    </Grid>
                    <Grid xs={6} sx={{ padding: "1px" }}>
                        <CondensedTextField
                            variant="outlined"
                            value={grandTotal.toFixed(2)}
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <Divider style={{ marginTop: '5%', marginBottom: '5%' }} />

                <Grid xs={12} sx={{ padding: "1px", marginTop: '5px' }}>
                    <Stack direction="row" spacing={2} style={{ justifyContent: 'center', }}>
                       <CondensedButton variant="outlined" onClick={handleCancel} >
                            {isCancelling ? "Cancelling Order..." : "Cancel Order"}
                        </CondensedButton>
                        <CondensedButton variant="outlined" onClick={handleMakePayment}  disabled={isSending} >
                            {isSending ? "Making Payment..." : "Make Payment"}
                        </CondensedButton>
                    </Stack>
                    <Stack direction="row" spacing={0} style={{ justifyContent: 'center', marginTop: '2%' }}>
                        <CondensedButton variant="outlined" onClick={handlePrintReceipt}>
                            Print
                        </CondensedButton>
                    </Stack>
                </Grid>
            </Box>

            <PaymentDialog
                user={user}
                open={dialogOpen}
                onClose={handleClosePayments}
                cartValues={cartValues}
                setCartValues={setCartValues}
                userDetails={user}
                fetchData={fetchData}
            />

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              >
              <Box sx={style}>
                {alertInfo.show && (
                  <Alert variant="outlined" severity={alertInfo.severity} onClose={() => {
                    setAlertInfo({ ...alertInfo, show: false });
                    handleClose();
                  }}>
                    {alertInfo.message}
                  </Alert>
                )}
              </Box>
            </Modal>
            <div style={{ display: 'none' }}>
                <Receipt user={user} ref={receiptRef} totalSection={totalSection} cartValues={cartValues} cashSaleNo={cashSaleNo}/>
            </div>
        </Container>
    );
};

export default PaymentForm;
