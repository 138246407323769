import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import axios from 'axios';


const CondensedTypography = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '30px',
  marginLeft: '5px',
}));

const CenteredBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  padding: '0',
  margin: '0',
}));

const DottedHr = styled('hr')({
  width: '100%',
  borderTop: '2px dotted black',
  borderBottom: 'none',
  borderLeft: 'none',
  borderRight: 'none',
});

const TwoColumnGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: '10px',
  rowGap: '0px',
  fontSize: '12px',
  margin: '0',
  padding: '0',
  textTransform: 'uppercase',
}));

const FourColumnGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '2fr 1fr 1fr 1fr',
  columnGap: '10px',
  rowGap: '5px',
  fontSize: '12px',
  margin: '0',
  padding: '0',
  textTransform: 'uppercase',
}));

const BoldTotalRow = styled('span')({
  fontWeight: 'bold',
  fontSize: '18px',
});

const LeftAlignedDigits = styled('span')({
  textAlign: 'left',
});

const Receipt = React.forwardRef((props, ref) => {
  const [business, setBusiness] = useState(null);

  const formatDateAndTime = () => {
    const date = new Date();
  
    const day = date.getDate().toString().padStart(2, '0');
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
  
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
  
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`/ui/business-details`, { id: 1 });
        setBusiness(response.data);
      } catch (error) {
        console.error('Error fetching business details:', error);
      }
    };
    fetchData();
  }, []);

  if (!business) {
    return null; //
  }

  return (
    <CenteredBox ref={ref} className="centered-box" sx={{ width: '80mm', padding: '10mm' }}>
      <CondensedTypography align="center" className="condensed-typography">{business[0].businessName}</CondensedTypography>
      <CondensedTypography align="center" className="condensed-typography">{business[0].businessLocation}</CondensedTypography>
      <CondensedTypography align="center" className="condensed-typography">Tel . {business[0].telephone}</CondensedTypography>
      <CondensedTypography align="center" className="condensed-typography">Email . {business[0].email}</CondensedTypography>
      <DottedHr className="dotted-hr"/> 

      <TwoColumnGrid className="two-column-grid">    
        <span>Customer Name :</span><span>{props?.customerId?.name?.name || 'N/A'}</span>
        <span>No.# : {props?.cashSaleNo}</span><span>Counter # : {props?.user?.counter}</span>
        <span>Date : { formatDateAndTime() }</span><span>Store : {props?.user?.branch}</span>
      </TwoColumnGrid>
      <DottedHr className="dotted-hr"/>  

      <FourColumnGrid className="four-column-grid">
        <span>ITEM</span><span>QTY</span><span>PRICE</span><span>AMOUNT</span>
      </FourColumnGrid>
      <DottedHr className="dotted-hr"/> 

      {props.cartValues.type === 'proforma' ? (
        props.cartValues.cart.items.map((item, index) => (
            <FourColumnGrid key={index} className="four-column-grid">
            <span>{item.product_name}</span>
            <span>{item.quantity}</span>
            <span className="right-aligned-digits">{item.selling_price.toFixed(2)}</span>
            <span className="right-aligned-digits">{(item.quantity * item.selling_price).toFixed(2)}</span>
            </FourColumnGrid>
        ))
        ) : (
        props.cartValues.cart.map((item, index) => (
            <FourColumnGrid key={index} className="four-column-grid">
            <span>{item.product.product_name}</span>
            <span>{item.quantity}</span>
            <span className="right-aligned-digits">{item.product.selling_price.toFixed(2)}</span>
            <span className="right-aligned-digits">{(item.quantity * item.product.selling_price).toFixed(2)}</span>
            </FourColumnGrid>
        ))
        )}

      <DottedHr className="dotted-hr"/> 

      <TwoColumnGrid className="two-column-grid">
      <LeftAlignedDigits>SUB TOTAL</LeftAlignedDigits><LeftAlignedDigits className="right-aligned-digits">{parseFloat(props.cartValues.subtotal).toFixed(2)}</LeftAlignedDigits>
      <LeftAlignedDigits>TAX</LeftAlignedDigits><LeftAlignedDigits className="right-aligned-digits">{parseFloat(props.cartValues.tax).toFixed(2)}</LeftAlignedDigits>
       <BoldTotalRow className="bold-total-row">TOTAL</BoldTotalRow><BoldTotalRow className="bold-total-row right-aligned-digits">{parseFloat(props.cartValues.grandTotal).toFixed(2)}</BoldTotalRow>

       {props.totalSection["is_receipt"] === true && (
        <>
          <LeftAlignedDigits>PAID</LeftAlignedDigits><LeftAlignedDigits className="right-aligned-digits">{parseFloat(props.totalSection['totalpaid']).toFixed(2)}</LeftAlignedDigits>
          <LeftAlignedDigits>BALANCE</LeftAlignedDigits><LeftAlignedDigits className="right-aligned-digits">{parseFloat(props.totalSection['balance']).toFixed(2)}</LeftAlignedDigits>
        </>
      )}
      </TwoColumnGrid>
      <DottedHr className="dotted-hr"/>
      <LeftAlignedDigits align="left" style={{fontSize:'12px'}}>Total items: {props.cartValues.count}</LeftAlignedDigits><hr/>
      
        {props.cartValues.type === 'proforma' ? (<>
            <LeftAlignedDigits align="left" style={{fontSize:'9px'}}>Table : {props.cartValues?.cart.table}</LeftAlignedDigits><br/>
            <LeftAlignedDigits align="left" style={{fontSize:'9px'}}>No. of guests : {props.cartValues?.cart.guests}</LeftAlignedDigits><br/>
            </>
            ) : (
            <></>
        )}	  
      
      <LeftAlignedDigits align="left" style={{fontSize:'9px'}}>Served by : {props?.user?.name}</LeftAlignedDigits>

      <div id="qrcode" align="center" style={{marginTop:'5%',marginBottom:'15%'}}></div><hr/>

      <CondensedTypography align="center" className="condensed-typography" style={{fontSize:'12px'}}>Thank you for dining with us!</CondensedTypography>
      <CondensedTypography align="center" className="condensed-typography" style={{fontSize:'9px'}}>Plannettech Investors Limited &copy; 2024-2030</CondensedTypography>
      <CondensedTypography align="center" className="condensed-typography" style={{fontSize:'9px' , textTransform: 'none'}}>Developed by Core ICT Consultancy</CondensedTypography>
    </CenteredBox>
  );
});
export default Receipt;
