import React, { useState, useEffect } from 'react';
import {
    ToggleButton, ToggleButtonGroup, Container, Divider, Box, Grid, Paper, Card, CardContent, CardMedia, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import ProductCard from './ProductCard';
import { keyframes } from '@mui/system';

const CondensedTypography = styled(Typography)(({ theme }) => ({
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
    height: '30px',
    marginLeft: '5px',
}));  

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

const Products = ({ categoryid , cart , setCart }) => {

    const [alignment, setAlignment] = useState('all'); 
    const [categorydata, setCategorydata] = useState([]);
    const [data, setData] = useState([]);
    const [categoriesIds, setCategoriesIds] = useState([]); 


    const handleAddToCart = (product) => {
        setCart(prevCart => {
            const existingProduct = prevCart.items.find(item => item.product_name === product.product_name);
    
            if (existingProduct) {
                return {
                    ...prevCart,
                    items: prevCart.items.map(item =>
                        item.product_name === product.product_name
                            ? { ...item, quantity: item.quantity + 1 }
                            : item
                    )
                };
            } else {
                return {
                    ...prevCart,
                    items: [...prevCart.items, { ...product, quantity: 1 }]
                };
            }
        });
    };

    const handleChange = (event, newAlignment) => {
      setAlignment(newAlignment);       
      if (newAlignment === 'all') {
        fetchDataPerCategory(categoriesIds);
      } else {
        fetchDataPerCategory([newAlignment]);
      }  
    };

    const fetchDataPerCategory = async (selectedItems) => {
        try {
          const response = await axios.post(`/ui/products-category`, {
            "categories": selectedItems
          });
          setData(response.data);
        } catch (error) {
          console.error('Error fetching products:', error);
        }
      };

    const fetchCategories = async () => {
        try {
            const response = await axios.post('/ui/categories', {
                parent_id: categoryid,
                business_category_id: 3,
            });
            const ids = response.data.map(category => category.id);
            setCategoriesIds(ids);
            setCategorydata(response.data);
            fetchDataPerCategory(ids);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    return (
        <Container maxWidth={true} sx={{ width: '100%', textAlign: 'center', minHeight: '63vh', height: '100px', overflowY: 'auto', overflowX: 'none' }}>
            <Box sx={{ my: 2 }}>
                <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    aria-label="Menu categories"
                    style={{ position: 'sticky' }}
                >
                    <ToggleButton value='all'>All</ToggleButton>
                    {categorydata.map((category) => (
                        <ToggleButton key={category.id} value={category.id}>
                            {category.name}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
                <Divider/>
            </Box>     
            <Grid container spacing={1} direction="row" alignItems="center">
                {data.map(product => (
                    <Grid item key={product.id}>
                        <ProductCard
                            key={product.id}
                            product={product}
                            onAddToCart={handleAddToCart}
                        />
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default Products;