import React, { useState, useEffect } from 'react';
import {
    IconButton, Card, CardContent, CardMedia, Modal, BottomNavigation, BottomNavigationAction, Paper,
    Container, TextField, Typography, Box, CssBaseline, List, Grid
} from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import Totals from './Totals';
import Products from './Products';

import axios from 'axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff2f28c',
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

const Menu = ({ user, cart , setCart , data , setData , catdata, setCatdata , categoriesTabs , categorytab ,setCategorytab}) => {
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false);
    const [categorydata, setCategorydata] = useState(catdata);
    const [catid, setCatId] = useState(null);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleBackdropClick = (event) => {
        event.stopPropagation();
    };

    const MenuCard = ({ item, handleOpen }) => {
        const handleCategoryClick = (event, item) => {
            event.preventDefault();
            event.stopPropagation();
            setCatId(item.id); 
            handleOpen();
            
        };
        return (
            <Card sx={{ maxWidth: 245, m: 1 }} onClick={(event) => handleCategoryClick(event, item)}>
                <CardMedia
                    component="img"
                    alt={item.name || "Default product image"}
                    height="100"
                    image={item.image || "./images/default-product-image.png"}
                    sx={{
                        width: '100%',
                        height: '200px',
                        objectFit: 'cover',
                    }}
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "./images/default-product-image.png";
                    }}
                />
                <CardContent sx={{ p: 0 }}>
                    <div
                        style={{
                            textAlign: 'center',
                            fontSize: '20px',
                            backgroundColor: 'transparent',
                            padding: '8px 0',
                        }}
                    >
                        {item.name}
                    </div>
                </CardContent>
            </Card>
        );
    };

    return (
        <Container maxWidth={true} sx={{ width: '100%' }}>
            <Grid container spacing={1}>
                <Grid item xs={8}>
                    <Item>
                        <Box sx={{ pb: 7 }}>
                            <CssBaseline />
                            <List>
                                <Grid container spacing={1}>
                                    {categorydata.map(item => (
                                        <Grid item key={item.id} xs={12} sm={6} md={3}>
                                            <MenuCard item={item} handleOpen={handleOpen} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </List>
                            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>

                            <BottomNavigation
                                showLabels
                                value={value}
                                onChange={async (event, newValue) => {
                                    const selectedItem = data[newValue];
                                    setValue(newValue);
                                    setCategorytab(selectedItem.id);
                                    try {
                                    let cdata = await categoriesTabs(selectedItem.id);
                                    setCategorydata(cdata);
                                    } catch (error) {
                                    console.error("Error fetching category data:", error);
                                    }
                                }}
                                >
                                {data.map((item) => (
                                    <BottomNavigationAction style={{color:'black' , fontWeight:'black'}}
                                    key={item.id}
                                    label={item.name}
                                    icon={
                                        item.image ? (
                                        <CardMedia
                                        style={{width:'50px' , height:'50px', marginTop:'-20%' , borderRadius:'20%'}}
                                            component="img"
                                            alt={item.name}
                                            image={item.image}
                                            title={item.name}
                                        />
                                        ) : (
                                        <CardMedia
                                            component="img"
                                            alt="Menu"
                                            height="40"
                                            image="./images/default-product-image.png"
                                            title="Menu"
                                        />
                                        )
                                    }
                                    />
                                ))}
                                </BottomNavigation>

                                {/* <BottomNavigation
                                    showLabels
                                    value={value}
                                    onChange={async (event, newValue) => {
                                        const selectedItem = data[newValue];
                                        setValue(newValue);
                                        setCategorytab(selectedItem.id);
                                        try {
                                            let cdata = await categoriesTabs(selectedItem.id);
                                            setCategorydata(cdata);
                                        } catch (error) {
                                            console.error("Error fetching category data:", error);
                                        }
                                    }}
                                >
                                    {data.map((item, index) => (
                                        <BottomNavigationAction
                                            key={item.id}
                                            label={item.name}
                                            icon={
                                                index === 0 ? <RestoreIcon /> :
                                                    index === 1 ? <FavoriteIcon /> :
                                                        <ArchiveIcon />
                                            }
                                        />
                                    ))}
                                </BottomNavigation> */}
                            </Paper>
                        </Box>
                    </Item>
                </Grid>
                <Grid item xs={4}>
                    <Item><Totals user={user} cart={cart} setCart={setCart} /></Item>
                </Grid>
            </Grid>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropProps={{
                    onClick: handleBackdropClick,
                }}
              >
                <Box sx={{ ...style, backgroundColor: '#ffffffd4', position: 'relative' , width:'60%' , height: '70vh' }}>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            top: -20,
                            right: -20,
                            color: 'red',
                            backgroundColor: 'white',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 0, 0, 0.1)',
                            },
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Products cart={cart} setCart={setCart} categoryid={catid}/>
                </Box>
            </Modal>
        </Container>
    );
};
export default Menu;
