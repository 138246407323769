
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import '@mui/material/styles/styled';
import axios from 'axios';
import {Autocomplete, Divider, Typography, Alert, MenuItem, Select, InputLabel, FormControl, Stack, Button, TextField, Box, Paper, createFilterOptions } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { useTreeViewApiRef } from '@mui/x-tree-view/hooks';
import * as yup from 'yup';
import { Field, ErrorMessage, Formik, Form } from 'formik';

const filter = createFilterOptions();

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const CondensedTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  display: 'flex',
  alignItems: 'center',
  height: '30px',
  marginLeft: '5px',
}));
const CondensedTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '14px',
    padding: '4px 0px',
    height: '25px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '1px',
      textAlign: 'right',
    },
  },
  margin: 0,
  '& input': {
    textAlign: 'left',
  },
}));
const CondensedButton = styled(Button)(({ theme }) => ({
  fontSize: '12px',
  margin: '0px',
  padding: '5px 0px',
  width: '100px',
}));

const CondensedAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '0.875rem',
    padding: '4px 8px',
    height: '30px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '1px',
    },
  },
  margin: 0,
  '& input': {
    textAlign: 'left',
  },
}));

const validationSchema = yup.object().shape({
  productCode: yup.string().required('Product code (serial/sku) is required'),
  productName: yup.string().required('Product name is required'),
  supplier: yup.string().required('Supplier is required'),
  buyingPrice: yup.number().required('Buying Price is required').positive('Buying Price must be a number'),
  sellingPrice: yup.number().required('Selling Price is required').positive('Selling Price must be a number'),
  storeQuantity: yup.number().required('Store quantity is required').positive('Buying Price must be a number'),
  productLocation: yup.string().required('Specify which store the product is located'),
});

export default function AddProduct({fetchProductsData, productName, handleCloseItem }) {

  const [categories, setCategories] = useState([]);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const toggledItemRef = React.useRef({});
  const apiRef = useTreeViewApiRef();
  const [suppliers, setSuppliers] = useState([]);
  const [alertInfo, setAlertInfo] = useState({ show: false, severity: '', message: '' });
  const [formData, setFormData] = useState({
    productCode:'',
    productName: productName,
    supplier: '',
    buyingPrice: '',
    sellingPrice: '',
    storeQuantity:'',
    productLocation:'',
  });

  useEffect(() => {
    axios.get('/ui/categories-fetch')
      .then(response => setCategories(response.data))
      .catch(error => console.error('Error fetching categories:', error));
    axios.get('/ui/suppliers')
      .then(response => setSuppliers(response.data))
      .catch(error => console.error('Error fetching suppliers:', error));
  }, []);

  const handleItemSelectionToggle = (event, itemId, isSelected) => {
    toggledItemRef.current[itemId] = isSelected;
  };

  const getItemDescendantsIds = (item) => {
    const descendants = [];
    const getDescendants = (item) => {
      if (item.children) {
        item.children.forEach((child) => {
          descendants.push(child.id);
          getDescendants(child);
        });
      }
    };
    getDescendants(item);
    return descendants;
  };

  const handleSelectedItemsChange = (event, newSelectedItems) => {
    setSelectedItems(newSelectedItems);
    const itemsToSelect = [];
    const itemsToUnSelect = {};
    Object.entries(toggledItemRef.current).forEach(([itemId, isSelected]) => {
      const item = apiRef.current.getItem(itemId);
      if (isSelected) {
        itemsToSelect.push(...getItemDescendantsIds(item));
      } else {
        getItemDescendantsIds(item).forEach((descendantId) => {
          itemsToUnSelect[descendantId] = true;
        });
      }
    });

    const newSelectedItemsWithChildren = Array.from(
      new Set(
        [...newSelectedItems, ...itemsToSelect].filter(
          (itemId) => !itemsToUnSelect[itemId],
        ),
      ),
    );

    setSelectedItems(newSelectedItemsWithChildren);

    toggledItemRef.current = {};
  };

  return (
    <Formik
      initialValues={formData}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        if(selectedItems.length === 0){
             setAlertInfo({ show: true, severity: 'error',  message:"Please choose a category for your new product ("+values.productName+")"});
        }else{
          values.categories = selectedItems;
          try {
                     setAlertInfo({ show: true, severity: 'info',  message:'Submitting form please wait...'});
                    const response = await axios.post(`/ui/products-save`,values);
                    if(response.data.bool){
                        setAlertInfo({ show: true, severity: 'success',  message:response.data.message });
                        resetForm();
                        setSelectedItems([]);
                        handleCloseItem();
                        fetchProductsData();
                  }else{
                      setAlertInfo({ show: true, severity: 'error',  message:response.data.message });
                  }
                } catch (error) {
                  setAlertInfo({ show: true, severity: 'error', message:  "Database error : "+error.message });
                }finally {
              }
        }
      }}
    >
      {({ setFieldValue }) => (
        <Form>
          {alertInfo.show && (
            <Alert variant="outlined" severity={alertInfo.severity} onClose={() => setAlertInfo({ ...alertInfo, show: false })}>
              {alertInfo.message}
            </Alert>
          )}
          <Stack direction="row" spacing={0} style={{ justifyContent: 'right' }}>
            <Item>
              <Box sx={{ flexGrow: 0 }}>
                <Divider style={{ marginTop: '1%', marginBottom: '1%' }} />
                <Grid container spacing={0} alignItems="center">
                  <Grid xs={3} sx={{ padding: "1px" }}>
                    <CondensedTypography>Product Name *</CondensedTypography>
                  </Grid>
                  <Grid xs={9} sx={{ padding: "1px" }}>
                    <Field name="productName">
                      {({ field }) => (
                        <CondensedTextField
                          variant="outlined"
                          {...field}
                          fullWidth
                        />
                      )}
                    </Field>
                    <ErrorMessage name="productName" component={Alert} severity="error" />
                  </Grid>


                  <Grid xs={3} sx={{ padding: "1px" }}>
                    <CondensedTypography>Product Code *</CondensedTypography>
                  </Grid>
                  <Grid xs={9} sx={{ padding: "1px" }}>
                    <Field name="productCode">
                      {({ field }) => (
                        <CondensedTextField
                          variant="outlined"
                          {...field}
                          fullWidth
                        />
                      )}
                    </Field>
                    <ErrorMessage name="productCode" component={Alert} severity="error" />
                  </Grid>

                  <Grid xs={12} sx={{ padding: "1px" }} style={{ textAlign: 'left' }}>
                    <CondensedTypography>Product category</CondensedTypography>
                    <RichTreeView
                      multiSelect
                      checkboxSelection
                      apiRef={apiRef}
                      items={categories}
                      selectedItems={selectedItems}
                      onSelectedItemsChange={handleSelectedItemsChange}
                      onItemSelectionToggle={handleItemSelectionToggle}
                    />
                  </Grid>

                  <Grid xs={3} sx={{ padding: "1px" }}>
                    <CondensedTypography>Supplier *</CondensedTypography>
                  </Grid>
                  <Grid xs={9} sx={{ padding: "1px" }}>
                    <Field name="supplier">
                      {({ field }) => (
                        <CondensedAutocomplete
                          value={suppliers.find(supplier => supplier.id === field.value) || null}
                          onChange={(event, newValue) => {
                            setFieldValue('supplier', newValue ? newValue.id : '');
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            return filtered;
                          }}
                          id="supplier-autocomplete"
                          options={suppliers}
                          getOptionLabel={(option) => option.name}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          renderOption={(props, option) => {
                            const { key, ...optionProps } = props;
                            return (
                              <li key={key} {...optionProps}>
                                {option.name}
                              </li>
                            );
                          }}
                          freeSolo
                          renderInput={(params) => <CondensedTextField {...params} label="" />}
                        />
                      )}
                    </Field>
                    <ErrorMessage name="supplier" component={Alert} severity="error" />
                  </Grid>

                  <Grid xs={3} sx={{ padding: "1px" }}>
                    <CondensedTypography>Product location</CondensedTypography>
                  </Grid>
                  <Grid xs={9} sx={{ padding: "1px" }}>
                     <Field name="productLocation">
                      {({ field }) => (
                        <CondensedTextField
                          variant="outlined"
                          {...field}
                          fullWidth
                        />
                      )}
                    </Field>
                    <ErrorMessage name="productLocation" component={Alert} severity="error" />
                  </Grid>

                  <Grid xs={3} sx={{ padding: "1px" }}>
                    <CondensedTypography>Store Qty</CondensedTypography>
                  </Grid>
                  <Grid xs={9} sx={{ padding: "1px" }}> 
                    <Field name="storeQuantity">
                      {({ field }) => (
                        <CondensedTextField
                          variant="outlined"
                          {...field}
                          fullWidth
                        />
                      )}
                    </Field>
                    <ErrorMessage name="storeQuantity" component={Alert} severity="error" />
                  </Grid>

                  <Grid xs={3} sx={{ padding: "1px" }}>
                    <CondensedTypography>Buying Price *</CondensedTypography>
                  </Grid>
                  <Grid xs={9} sx={{ padding: "1px" }}>
                    <Field name="buyingPrice">
                      {({ field }) => (
                        <CondensedTextField
                          variant="outlined"
                          {...field}
                          fullWidth
                        />
                      )}
                    </Field>
                    <ErrorMessage name="buyingPrice" component={Alert} severity="error" />
                  </Grid>

                  <Grid xs={3} sx={{ padding: "1px" }}>
                    <CondensedTypography>Selling Price *</CondensedTypography>
                  </Grid>
                  <Grid xs={9} sx={{ padding: "1px" }}>
                    <Field name="sellingPrice">
                      {({ field }) => (
                        <CondensedTextField
                          variant="outlined"
                          {...field}
                          fullWidth
                        />
                      )}
                    </Field>
                    <ErrorMessage name="sellingPrice" component={Alert} severity="error" />
                  </Grid>

                  <Grid xs={12} sx={{ padding: "1px", marginTop: '5px' }}>
                    <Divider style={{ marginTop: '1%', marginBottom: '1%' }} />
                    <Stack direction="row" spacing={2} style={{ justifyContent: 'right', }}>
                      <CondensedButton variant="outlined" type="submit">
                        Save
                      </CondensedButton>
                      <CondensedButton variant="outlined" onClick={handleCloseItem}>
                        Close
                      </CondensedButton>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Item>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
