import React, { useRef , useState , useEffect , useCallback   } from 'react';
import { Alert, Button, Stack, Container, 
  TextField, Typography, Box, Paper, Grid 
  ,FormControl,InputAdornment,Divider,
  FormHelperText,Autocomplete,CircularProgress,
} from '@mui/material';

import { styled } from '@mui/material/styles';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ReceiptLong from '@mui/icons-material/ReceiptLong';
import HighlightOff from '@mui/icons-material/HighlightOff';
import _ from 'lodash';

import { Formik, Field, Form, ErrorMessage, useField } from "formik";
import * as Yup from "yup";
import axios from 'axios';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  textAlign: 'left',
  color: theme.palette.text.secondary,
  padding: theme.spacing(2),
}));

const CondensedButton = styled(Button)(({ theme }) => ({
  fontSize: '12px',
  margin: '0px',
  padding: '5px 0px',
  width: '100%',
  textTransform: 'none',
}));

const CondensedTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '18px',
    padding: '4px 8px',
    height: '30px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '1px',
    },
  },
  margin: 0,
  '& input': {
    textAlign: 'right',
  },
}));

const CondensedTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  display: 'flex',
  alignItems: 'center',
  height: '30px',
  marginLeft: '5px',
}));

function createDbData(
      id, 
      product_code, 
      product_name, 
      product_garage, 
      product_image, 
      selling_price,
      quantity,
      sub_total,
      taxValue,
      tax
    ) {
      return { 
        id, 
        product_code, 
        product_name, 
        product_garage, 
        product_image, 
        selling_price,
        quantity,
        sub_total,
        taxValue,
        tax
      };
  }

const SearchInvoice = ({ invoiceId, setInvoiceId , handleSearchCloseItem , customerId=0, totalSection=[] , setTotalSection=null , userDetails=[] , setCartValues=null , setCustomerId=null , setSearchValue=null ,cartValues = [] }) => {
  
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const fetchOptions = async (query) => {
    if (query) {
      try {
        setLoading(true);
        const response = await axios.get(`/transaction/invoice/search/${query}`);
        setOptions(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setOptions([]);
    }
  };

  const debouncedFetchOptions = useCallback(_.debounce(fetchOptions,1), []);
  useEffect(() => {
    debouncedFetchOptions(inputValue);
  }, [inputValue, debouncedFetchOptions]); 


  const [customerData, setCustomerData] = useState({
    fnotes:""
  });

  const grandTotal = parseFloat(totalSection['grandTotal']);
  const taxValue = parseFloat(totalSection['taxValue']);
  const subTotal = parseFloat(totalSection['subTotal']);
  const [balance, setBalance] = useState(0);

  return (
    <Container maxWidth="sm" style={{width:"500px"}}>
          <Formik
                        initialValues={customerData}
                        enableReinitialize={true}
                        onSubmit={(values) => {
                          console.log("Form submitted successfully", values);
                        }}
                      >
                        {({
                          errors,
                          touched,
                          validateForm,
                          setFieldValue,
                          setTouched,
                          handleBlur,
                          values,
                        }) => (
                          <Form>

                <Box sx={{ flexGrow: 1 }}>
                  <Stack spacing={0}>
                    <Item>
                            <Autocomplete
                                          style={{ marginLeft: '0%' }}
                                          id="size-small-standard-multi"
                                          size="large"
                                          options={options}
                                          loading={loading}
                                          getOptionLabel={(option) => option?.invoice_no || ''}
                                          inputValue={inputValue}
                                          value={null}
                                          onInputChange={(event, newInputValue) => {
                                              setInputValue(newInputValue);
                                          }}
                                          onChange={async (event, newValue) => {
                                              if (newValue) {
                                                try {
                                                  setRows(newValue);
                                                  const response = await axios.post(`/ui/fetch-suspended-order-items`, {
                                                      id:newValue.id
                                                  });
                                                  setInvoiceId(newValue.id);
                                                  if(response.data){
                                                      let responseData = response.data;
                                                      let formattedData = responseData.map(item => {
                                                          const { product } = item;                                               
                                                          return createDbData(
                                                            item.product_id,
                                                            item.product.product_code,
                                                            item.product.product_name,
                                                            item.product.product_garage,
                                                            item.product.product_image,
                                                            product.selling_price,
                                                            item.quantity,
                                                            parseFloat(item.total)?.toFixed(2),
                                                            item.tax,
                                                            product.tax
                                                        );
                                                      });                                
                                                      const updatedCartValues = formattedData.map(item => {
                                                          const updatedQuantity = item.quantity;
                                                          return {
                                                            ...item,
                                                            quantity: updatedQuantity,
                                                            sub_total: updatedQuantity * item.selling_price,
                                                            tax: parseFloat(item.tax),
                                                            taxValue: parseFloat(item.taxValue),
                                                          };
                                                        return item;
                                                      });                                                      
                                                      setCartValues(updatedCartValues);
                                                      handleSearchCloseItem();                                                     
                                                  }
                                                } catch (error) {
                                                  console.error('Error fetching invoice:', error);
                                                } finally {
                                              }
                                            }
                                          }}
                                          renderInput={(params) => (
                                              <CondensedTextField
                                                  {...params}
                                                  variant="standard"
                                                  label="Enter Invoice #"
                                                  placeholder="Enter Invoice #"
                                                  InputProps={{
                                                      ...params.InputProps,
                                                      endAdornment: (
                                                          <>
                                                              {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                              {params.InputProps.endAdornment}
                                                          </>
                                                      ),
                                                  }}
                                              />
                                            )}
                                        />

                    </Item>
                  </Stack>
                </Box>

          </Form>
        )}
      </Formik>

    </Container>
  );
};

export default SearchInvoice;
