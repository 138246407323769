import React, { useRef , useState , useEffect  } from 'react';
import { Alert, Button, Stack, Container, 
  TextField, Typography, Box, Paper, Grid 
  ,FormControl,InputAdornment,FormHelperText,Divider,
  Modal,IconButton  
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Send from '@mui/icons-material/Send';
import Print from '@mui/icons-material/Print';
import Cancel from '@mui/icons-material/Cancel';
import { Formik, Field, Form, ErrorMessage, useField } from "formik";
import * as Yup from "yup";
import axios from 'axios';

import ReportMain from './back-end/reports/Reports';
import CloseIcon from '@mui/icons-material/Close';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  textAlign: 'left',
  color: theme.palette.text.secondary,
  padding: theme.spacing(2),
}));

const CondensedButton = styled(Button)(({ theme }) => ({
  fontSize: '12px',
  margin: '0px',
  padding: '5px 0px',
  width: '100%',
  textTransform: 'none',
}));

const CondensedTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '18px',
    padding: '4px 8px',
    height: '30px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '1px',
    },
  },
  margin: 0,
  '& input': {
    textAlign: 'right',
  },
}));

const CondensedTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  display: 'flex',
  alignItems: 'center',
  height: '30px',
  marginLeft: '5px',
}));

const Quote = ({ invoiceId, setInvoiceId , customerId, totalSection , setTotalSection , userDetails , setCartValues , setCustomerId , setSearchValue ,cartValues = []}) => {

  const [customerData, setCustomerData] = useState({
    fcustomer_name: customerId?.name?.name || "",
    fphone: customerId?.name?.phone || "",
    femail: customerId?.name?.email || "",
    faddress: customerId?.name?.address || "",
    fvalidity_period:""
  });

  const [paymentAmount, setPaymentAmount] = useState(0);
  const [totalpaid, setTotalpaid] = useState(0);
  const [notes, setNotes] = useState("");

  const grandTotal = parseFloat(totalSection['grandTotal']);
  const taxValue = parseFloat(totalSection['taxValue']);
  const subTotal = parseFloat(totalSection['subTotal']);

  const [balance, setBalance] = useState(0);

  useEffect(() => {
    setBalance(parseFloat(totalSection['grandTotal']));
  }, [totalSection]);
 
  useEffect(() => {
    if (customerId?.name) {
      setCustomerData({
        fcustomer_name: customerId.name.name || "",
        fphone: customerId.name.phone || "",
        femail: customerId.name.email || "",
        faddress: customerId.name.address || "",
        fvalidity_period : "365 (days)",
      });
    }
  }, [customerId]);

  const validationSchema = Yup.object().shape({
    fcustomer_name: Yup.string().required("Customer name is required"),
    fphone: Yup.string().required("Customer phone is required"),
    femail: Yup.string().required("Email is required"),
    faddress: Yup.string().required("Customer address is required"),
    fvalidity_period : Yup.string().required("Quote validity is required is required"),
  });

  const handlePaymentChange = (e) => {
    const payment = parseFloat(e.target.value) || 0;
    setPaymentAmount(payment);
    setTotalpaid(payment);
    setBalance((grandTotal - payment).toFixed(2));
  };


  const handleFinish = async (validateForm, setTouched, values) => {
    try {
      const response = await axios.post(`/ui/quote/create`, {
        values,
        employee_id:userDetails?.id,
        customer_id:customerId.id,
        cart:cartValues,
        grand_total:grandTotal,
        sub_total:subTotal,
        total_products : cartValues.reduce((sum, item) => sum + item?.quantity, 0),
        vat:taxValue,
        total_paid:totalpaid,
        balance:balance
      });
      if(response.data.bool){
        setInvoiceId(response.data.id);
        alert(response.data.message);
        setCartValues([]);
        setSearchValue(null);
        setTotalSection([]);
        setCustomerId({name: 'Default Customer', id:1});
        handlePdfPrint(); 
      }

    } catch (error) {
      console.error('Error creating a new account type:', error);
    } finally {
  }
}


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const [dopen, setdOpen] = React.useState(false);
const [modalContent, setModalContent] = useState('');
const handledOpen = () => setdOpen(true);
const handledClose = () => {
  setdOpen(false);
} 
const handlePdfPrint = async () => {
  setModalContent('print_pdf');
  handledOpen();
}
const handleBackdropClick = (event) => {
  event.stopPropagation();
};


return (
    <Container maxWidth="sm">

      <Formik
              initialValues={customerData}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                console.log("Form submitted successfully", values);
              }}
            >
              {({
                errors,
                touched,
                validateForm,
                setFieldValue,
                setTouched,
                handleBlur,
                values,
              }) => (
                <Form>

                    <Box sx={{ flexGrow: 1 }}>
                      <Stack spacing={2}>
                        <Item>
                          <Typography variant="h6" gutterBottom>
                            Quotation Details
                          </Typography>

                          <FormControl
                            variant="standard"
                            style={{ width: "100%" }}
                            error={Boolean(
                              touched.fcustomer_name && errors.fcustomer_name
                            )}
                          >
                            <CondensedTypography>Customer full name *</CondensedTypography>
                            <CondensedTextField
                              name="fcustomer_name"
                              variant="outlined"
                              read-only="true"
                              value={values.fcustomer_name}
                              onChange={(e) =>
                                setFieldValue("fcustomer_name", e.target.value)
                              }
                              onBlur={handleBlur}
                              InputProps={{
                                readOnly: true,
                              }}
                              fullWidth
                            />
                            <ErrorMessage name="fcustomer_name">
                              {(msg) => <FormHelperText>{msg}</FormHelperText>}
                            </ErrorMessage>
                          </FormControl>

                          <FormControl
                            variant="standard"
                            style={{ width: "100%", marginTop: "2%" }}
                            error={Boolean(touched.fphone && errors.fphone)}
                          >
                             <CondensedTypography>Receiver phone *</CondensedTypography>
                            <CondensedTextField
                              name="fphone"
                              variant="outlined"
                              value={values.fphone}
                              onChange={(e) => setFieldValue("fphone", e.target.value)}
                              onBlur={handleBlur}
                              InputProps={{
                                readOnly: true,
                              }}
                              fullWidth
                            />
                            <ErrorMessage name="fphone">
                              {(msg) => <FormHelperText>{msg}</FormHelperText>}
                            </ErrorMessage>
                          </FormControl>


                          <FormControl
                            variant="standard"
                            style={{ width: "100%", marginTop: "2%" }}
                            error={Boolean(touched.femail && errors.femail)}
                          >
                             <CondensedTypography>Email *</CondensedTypography>
                            <CondensedTextField
                              name="femail"
                              variant="outlined"
                              value={values.femail}
                              onChange={(e) => setFieldValue("femail", e.target.value)}
                              onBlur={handleBlur}
                              InputProps={{
                                readOnly: true,
                              }}
                              fullWidth
                            />
                            <ErrorMessage name="femail">
                              {(msg) => <FormHelperText>{msg}</FormHelperText>}
                            </ErrorMessage>
                          </FormControl>
                          <FormControl variant="standard" style={{ width: '100%',marginTop:'2%' }} error={Boolean(touched.faddress && errors.faddress)}>
                          <CondensedTypography>Customer address / location *</CondensedTypography>
                                <Field
                                as={TextField}
                                name="faddress"
                                id="outlined-multiline-static"
                                label=""
                                multiline
                                rows={2}
                                value={values.faddress}
                                onChange={(e) => setFieldValue('faddress', e.target.value)}
                                onBlur={handleBlur}
                                placeholder=""
                                InputProps={{
                                  readOnly: true,
                                }}
                                fullWidth
                                />
                                <ErrorMessage name="faddress">
                                {msg => <FormHelperText>{msg}</FormHelperText>}
                                </ErrorMessage>
                          </FormControl>


                          <Grid container spacing={0} alignItems="center">
                            <Grid xs={5} sx={{ padding: "1px" }}>
                              <CondensedTypography>Quote validity *</CondensedTypography>
                            </Grid>
                            <Grid xs={7} sx={{ padding: "1px" }}>
                                <FormControl
                                  variant="standard"
                                  style={{ width: "100%", marginTop: "2%" }}
                                  error={Boolean(touched.fvalidity_period && errors.fvalidity_period)}
                                >
                                  <CondensedTextField
                                    name="fvalidity_period"
                                    variant="outlined"
                                    value={values.fvalidity_period}
                                    onChange={(e) => setFieldValue("fvalidity_period", e.target.value)}
                                    onBlur={handleBlur}
                                    InputProps={{
                                      readOnly: false,
                                    }}
                                    fullWidth
                                  />
                                  <ErrorMessage name="fvalidity_period">
                                    {(msg) => <FormHelperText>{msg}</FormHelperText>}
                                  </ErrorMessage>
                                </FormControl>
                              </Grid>
                          </Grid>

                        </Item>


                        <Item>
                          <Typography variant="h6" gutterBottom>
                            Totals
                          </Typography>
                          <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
                            <CondensedTypography>Total Items:</CondensedTypography>
                            <CondensedTypography>
                                {cartValues.reduce((sum, item) => sum + item?.quantity, 0)}
                            </CondensedTypography>
                          </Stack>

                          <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
                            <CondensedTypography>Sub total:</CondensedTypography>
                            <CondensedTypography>{subTotal.toFixed(2)}</CondensedTypography>
                          </Stack>

                          <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
                            <CondensedTypography>Tax:</CondensedTypography>
                            <CondensedTypography>{taxValue.toFixed(2)}</CondensedTypography>
                          </Stack>


                          <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
                            <CondensedTypography>Grand Total:</CondensedTypography>
                            <CondensedTypography>{grandTotal.toFixed(2)}</CondensedTypography>
                          </Stack>
                        </Item>

                        <Item>
                          <Stack direction="row" spacing={2} justifyContent="center">

                          <CondensedButton variant="contained" color="primary"
                              startIcon={<Send />}
                               onClick={async () => {
                                    const isValid = await validateForm();
                                    if (Object.keys(isValid).length === 0) {
                                          handleFinish(validateForm, setTouched, values);
                                    } else {
                                    setTouched({
                                      fcustomer_name: true,
                                      fphone:true,
                                      femail:true,
                                      faddress:true,
                                      fnotes:false
                                    });
                                    }
                                }}
                            >
                            Create Quote
                          </CondensedButton>

                            <CondensedButton
                                  variant="outlined"
                                  color="secondary"
                                  startIcon={<Print />}
                                  onClick={async () => {
                                    handlePdfPrint();
                                  }}
                                  disabled={!invoiceId}
                                >
                                  Print Quotation
                             </CondensedButton>

                          </Stack>
                        </Item>
                      </Stack>
                    </Box>
        </Form>
        )}
      </Formik>
      <Modal
                open={dopen}
                onClose={handledClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropProps={{
                    onClick: handleBackdropClick,
                }}
              >
                <Box sx={{ ...style, backgroundColor: '#ffffffd4', position: 'relative' , width:'60%' , height: 'auto' }}>
                <IconButton
                        aria-label="close"
                        onClick={handledClose}
                        sx={{
                            position: 'absolute',
                            top: -20,
                            right: -20,
                            color: 'red',
                            backgroundColor: 'white',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 0, 0, 0.1)',
                            },
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {modalContent === 'print_pdf' && <ReportMain report_name={'quotation'} data={invoiceId}/>}
                </Box>
            </Modal>
    </Container>
  );
};

export default Quote;
