
import React, { useRef , useState , useEffect  } from 'react';

import { useNavigate  } from 'react-router-dom';
import {MenuItem ,Button , Select, InputLabel , FormControl , Modal, Dialog, DialogActions , DialogContent ,DialogTitle,Alert,  Container, TextField, Typography, Box, Paper } from '@mui/material';
import AppAppBar from './AppAppBar';
import Orders from './Orders';
import TotalsForm from './TotalsForm';
import CustomerPanel from './CustomerPanel';
import ScanInputs from './ScanInputs';
import RegisterMode from './RegisterMode';
import  DrawerCart from './DrawerCart';
import  ShortcutDrawer from './ShortcutDrawer';
import Login from './Login';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import './App.css';
import  Download  from '@mui/icons-material/Download';
import * as XLSX from 'xlsx';
import ReportMain from './back-end/reports/Reports';

const CondensedTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
      fontSize: '18px',
      padding: '4px 8px', 
      height: '30px',    
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderWidth: '1px',
        textAlign:'right',
      },
    },
    margin: 0,
    '& input': {
      textAlign: 'right',
    },
  }));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.container,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


function Reports() {

    const [userDetails, setUserDetails] = useState(null);
    const [data, setData] = useState();
    const [filteredRows, setFilteredRows] = useState();
    const [selectedFields, setSelectedFields] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleBackdropClick = (event) => {
        event.stopPropagation();
      };
    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
        const filtered = data.filter((row) => {
        return row.name.toLowerCase().includes(value);
        })
        setFilteredRows(filtered);
    };

    const handleFieldChange = (event) => {
        setSelectedFields(event.target.value);
    };

    const handleExport = () => {
        const dataxs = data.map(row => ({
          'Id': row.id,
          'Name': row.name,
          'Email': row.email,
          'Phone': row.phone,
          'Address': row.address,
          'Business' : row.shopname,
          'Type' : row.type
        }));
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(dataxs);
        XLSX.utils.book_append_sheet(wb, ws, 'Supplier Data');
        XLSX.writeFile(wb, 'Supplier_data.xlsx');
      };


    const toggleColorMode = () => {
        setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
      };

      const checkUserDetails = async () => {
        try {
            const response = await axios.get('/ui/check-user-session');
            if (response.data.user) {
                setUserDetails(response.data.user);
                setOpen(false);
            } else {
                setOpen(true);
            }
        } catch (error) {
            console.error('Error checking user session:', error);
            setOpen(true);
        }
      };
  
      useEffect(() => {
        document.body.style.backgroundImage = "url('./images/home-1.jpg')";
        document.body.style.backgroundSize = '100%';
        document.body.style.backgroundPosition = 'top';
        checkUserDetails();
        return () => {
          document.body.style.backgroundImage = '';
          document.body.style.backgroundSize = '';
          document.body.style.backgroundPosition = '';
        };
      }, []);


      const fetchData = async () => {
        try {
          const response = await axios.get(`/ui/suppliers`, {
          });
          setData(response.data);
        } catch (error) {
          console.error('Error fetching suppliers:', error);
        } finally {
        }
      }

      useEffect(() => {
        fetchData();
      }, []);

      useEffect(() => {
        setFilteredRows(data);
      }, [data]);

    const columns = [
        { field: 'id', headerName: '#', flex: 0.1 },
        {
          field: 'created_at',
          headerName: 'Registered date',
          flex: 0.6,
          valueFormatter: (params) => {
            return dayjs(params.value).format('DD MMM YYYY HH:mm:ss');
          }
        },
        { field: 'name', headerName: 'Customer name' , flex: 1 },
        { field: 'email', headerName: 'Email' , flex: 0.5 },
        { field: 'phone', headerName: 'Phone' , flex: 0.5 },
        { field: 'address', headerName: 'Address' , flex: 0.5  },
        { field: 'shopname', headerName: 'Buiness' , flex: 0.5  },
        { field: 'type', headerName: 'Type' , flex: 0.5  },
      ];

      const getRowClassName = (params) => {
        return params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row';
      };


      const [cartValues, setCartValues] = useState([]);

      const fullScreen = () => {
        const elem = document.documentElement;
        if (!document.fullscreenElement) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            }
            setIsFullscreen(true);
        } else {
  
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
            setIsFullscreen(false);
        }
    };
  
    const closeRegister =  () => {
          setModalContent('close_register');
          handledOpen();
      };
      
      const registerDetails =  () => {
          setModalContent('register_details');
          handledOpen();
      };
      


    return (
      <div>
      {/* <AppAppBar mode="light" toggleColorMode={toggleColorMode} userDetails={userDetails} setUserDetails={setUserDetails}/> */}
      <AppAppBar registerDetails={registerDetails} closeRegister={closeRegister} fullScreen={fullScreen} cart={cartValues} mode="light" toggleColorMode={toggleColorMode} userDetails={userDetails} setUserDetails={setUserDetails} handleOpen={handleOpen} />
        <Container maxWidth="" style={{ marginTop: '100px' }}>
          <Box sx={{ flexGrow: 1 }}>
             <ReportMain report_name={'sales today'}/>
          </Box>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
              onClick: handleBackdropClick,
            }}
          >
            <Box sx={style} style={{backgroundColor: '#f1dbdb47'}}>
                <Login onLoginSuccess={handleClose} setUserDetails={setUserDetails} checkUserDetails={checkUserDetails} />
            </Box>
          </Modal>
        </Container>
    </div>
    );
}
export default Reports;
