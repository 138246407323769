import React, { useState, useEffect } from 'react';
import {Stack,Button ,FormControl,InputAdornment,Autocomplete,FormHelperText,
    TextField ,ToggleButton, ToggleButtonGroup, Container, Divider, Box, Grid, Paper, Card, CardContent, CardMedia, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { keyframes } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import PinDropIcon from '@mui/icons-material/PinDrop';
import { Formik, Field, Form, ErrorMessage, useField } from "formik";
import * as Yup from "yup";



const CondensedButton = styled(Button)(({ theme }) => ({
    fontSize: '12px',
    margin: '0px',
    padding: '5px 0px',
    width: '100px',
}));

const CondensedTypography = styled(Typography)(({ theme }) => ({
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
    height: '30px',
    marginLeft: '5px',
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '14px',
    padding: '0px 8px',
    height: '30px',
    width: '90%',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '1px',
      textAlign: 'left',
    },
  },
  margin: 0,
  '& input': {
    textAlign: 'left',
  },
}));

const CondensedTextField = (props) => {
  const { name, value, onChange, onBlur, ...otherProps } = props;
  return (
    <StyledTextField
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      {...otherProps}
    />
  );
}
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));




const AddDrop = ({handleClose , user , fetchTodaysRegister}) => {

    const [data, setData] = useState([]);
    const [accountType, setAccountType] = useState([]);

    const validationSchema = Yup.object().shape({
      fdrop_amount: Yup.string().required('Drop amount is required'),
      fnote: Yup.string().required('Note is required')
   });
    

    const [formData, setFormData] = useState({
        fdrop_amount:null,
        fnote:null,
      });


    const handleFinish = async (validateForm, setTouched, values) => {
      try {
        values.user_id = user?.id; 
        const response = await axios.post(`/account/create/drop`, {
          values
        });
        fetchTodaysRegister();
        handleClose();
      } catch (error) {
        console.error('Error creating a new account type:', error);
      } finally {
    }
  }


return (
    <Container maxWidth={true} sx={{ width: '100%', textAlign: 'center', minHeight: 'auto', height: 'auto', overflowY: 'auto', overflowX: 'none' }}>
       <Formik
                  initialValues={formData}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    console.log("Form submitted successfully", values);
                  }}
                >
          {({ errors, touched, validateForm, setTouched, values ,  setFieldValue, setFieldTouched , handleBlur }) => (
          <Form>
        
        
        <Grid
                container
                spacing={1}
                direction="column"
                alignItems="left"
                justifyContent="left"
                sx={{ flexGrow: 1 }} 
            >
                <Grid item xs={12} sx={{ width: '100%' }}>
                        <CondensedTypography style={{ fontWeight: '900' , width:'100%' , fontSize:'20px'  }}>Register a drop amount in KES</CondensedTypography>
                </Grid>
        </Grid>

      <Grid
        container
        spacing={1}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ flexGrow: 1 }} 
      >
        <Grid item xs={12} sx={{ width: '100%' }}>
                            <Paper
                                elevation={3}
                                sx={{
                                padding: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                }}
                            >

                                  <Grid container spacing={1} alignItems="left" style={{padding:'2%'}}>
                                            <Grid item xs={12} sx={{ padding: "1px" }}>
                                                 <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
                                                    <CondensedTypography style={{ fontWeight: '900' , width:'20%' }}>Amount:</CondensedTypography>
                                                    
                                                    <FormControl variant="standard" style={{ width: '90%' }} error={Boolean(touched.fdrop_amount && errors.fdrop_amount)}>
                                                            <CondensedTextField
                                                                    name="fdrop_amount"
                                                                    variant="outlined"
                                                                    value={values.fdrop_amount}
                                                                    onChange={(e) => setFieldValue('fdrop_amount', e.target.value)}
                                                                    onBlur={handleBlur}
                                                                    InputProps={{
                                                                        readOnly: false,
                                                                    }}
                                                                    fullWidth
                                                            />
                                                                <ErrorMessage name="fdrop_amount">
                                                        {msg => <FormHelperText>{msg}</FormHelperText>}
                                                        </ErrorMessage>
                                                    </FormControl>
                                        </Stack> 
                                    </Grid>
                                
                                </Grid>

          </Paper>
        </Grid>


        <Grid item xs={12} sx={{ width: '100%'  }}>
                <Paper
                    elevation={3}
                    sx={{
                    padding: 0,
                
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    }}
                >
                <Grid container spacing={0} alignItems="left">
                            <Grid item xs={12} sx={{ padding: "1px" }}> 
                                  <Box sx={{ height: 100, width: '100%' }}>

                                            <FormControl variant="standard" style={{ width: '98%',marginTop:'8px' }} error={Boolean(touched.fnote && errors.fnote)}>
                                                <Field
                                                as={TextField}
                                                name="fnote"
                                                id="outlined-multiline-static"
                                                label="Additional notes"
                                                multiline
                                                rows={2}
                                                value={values.fnote}
                                                onChange={(e) => setFieldValue('fnote', e.target.value)}
                                                onBlur={handleBlur}
                                                placeholder="Enter your additional note"
                                                fullWidth
                                                />
                                                <ErrorMessage name="fnote">
                                                {msg => <FormHelperText>{msg}</FormHelperText>}
                                                </ErrorMessage>
                                            </FormControl>

                                 </Box>
                            </Grid>
                </Grid>
          </Paper>
        </Grid>
      </Grid>


        <Grid
                container
                spacing={1}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ flexGrow: 1 , marginTop:'0.5%'}} 
            >
                <Grid item xs={12} sx={{ width: '100%' }}>
                                    <Paper
                                        elevation={3}
                                        sx={{
                                        padding: 1,
                                        display: 'flex',
                                        justifyContent: 'right',
                                        alignItems: 'right',
                                        textAlign: 'center',
                                        }}
                                    >
                                        <CondensedButton variant="outlined" onClick={async () => {
                                                const isValid = await validateForm();
                                                if (Object.keys(isValid).length === 0) {
                                                     handleFinish(validateForm, setTouched, values);
                                                } else {
                                                setTouched({
                                                    fdrop_amount: true,
                                                    fnote:true
                                                });
                                              }
                                            }}
                                        >
                                        Save
                                        </CondensedButton>
                                    </Paper>
                </Grid>
        </Grid>
     </Form>
      )}
</Formik>












  </Container>
 );
};

export default AddDrop;