import React, { useState, useEffect } from 'react';
import {Tabs,Tab,Tooltip,
    Button , MenuItem , Select, InputLabel , FormControl, IconButton, Card, CardContent, CardMedia, Modal, BottomNavigation, BottomNavigationAction, Paper,
    Container, TextField, Typography, Box, CssBaseline, List, Grid
} from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';


import * as XLSX from 'xlsx';
import { DataGrid } from '@mui/x-data-grid';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import  Download  from '@mui/icons-material/Download';
import dayjs from 'dayjs';



import { keyframes } from '@mui/system';

import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff2f28c',
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};



const paperStyle = { padding: 20, height: 'auto', width: 'auto', margin: "0px auto", backgroundColor: 'rgb(221 215 201 / 45%)', borderRadius: '12px', boxShadow: '0px 0px 8px rgba(0, 0, 0, 25)' };






const Settings = ({user , cart , setCart}) => {  
    const [data, setData] = useState([]);
    const [rows, setRows] = useState([]);
    const [value, setValue] = React.useState(0);  

    const handleChange = (event, newValue) => {
      setValue(newValue);
    }; 

    const columns = [
        { field: 'id', headerName: 'ID',  flex: 0.5 },
        { field: 'name', headerName: 'Floor', flex: 1, editable: true },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 0.5,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    <Tooltip title="Delete">
                        <IconButton color="error" onClick={() => handleDelete(params.row.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },
    ];

 const fetchData = async () => {
        try {
          const response = await axios.post(`/ui/furniture-floors`, {
            branch_id : 1
          });
          setRows(response.data);
        } catch (error) {
          console.error('Error fetching floors:', error);
        } finally {
        }
    } 
    
    const initialRows = [
        { id: 1, floor: 'First Floor' },
        { id: 2, floor: 'Second Floor' },    
    ];
    
   
    useEffect(() => {
        fetchData();
    }, []);

    const handleProcessRowUpdate = async (newRow) => {
        try { 
            await axios.put(`/ui/furniture-floors-update/${newRow.id}`, newRow);
            setRows((prevRows) =>
                prevRows.map((row) => (row.id === newRow.id ? { ...newRow } : row))
            );
            return newRow; 
        } catch (error) {
            console.error('Error updating row:', error);
            throw error; 
        }
    };
    
    const handleDelete = async (id) => {
        try {        
            await axios.delete(`/ui/furniture-floors-update/${id}`);
            setRows((prevRows) => prevRows.filter((row) => row.id !== id));
        } catch (error) {
            console.error('Error deleting row:', error);
        }
    };

    const handleAdd = async () => {
        const newRow = { id: rows.length + 1, name: 'New floor' };
        try {
            const response = await axios.post('/ui/furniture-floor-add', {
                branch_id: 1,
                name: newRow.name
            });    
            if (response.data.success) {
                setRows((prevRows) => [...prevRows, response.data.data]);
                console.log('Floor added successfully');
            }
        } catch (error) {
            console.error('Error adding floor:', error);
        }
    };

return (
        <Container maxWidth={true} sx={{ width: '100%' }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Item>                  
                        <Paper elavation={12} style={paperStyle} >

                                <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    <Tabs value={value} onChange={handleChange} centered>
                                        <Tab label="Configure restaurant floors" id="tab-0" aria-controls="tabpanel-0" />
                                    </Tabs>
                                    <TabPanel value={value} index={0}>


                                    <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Tooltip title="Add New Row">
                                            <IconButton color="primary" onClick={handleAdd}>
                                                <AddIcon /> <span style={{fontSize:'15px'}}>Create a new floor </span>
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                    <DataGrid
                                        rows={rows}
                                        columns={columns}
                                        processRowUpdate={handleProcessRowUpdate}       
                                        rowHeight={30}
                                        experimentalFeatures={{ newEditingApi: true }}
                                    />
                                    </TabPanel>

                                </Box>

                        </Paper>
                    </Item>
                </Grid>
                <Grid item xs={4}>
                    &nbsp;
                </Grid>
            </Grid>
        </Container>
    );
};
export default Settings;
