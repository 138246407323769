import React, { useState, useEffect } from 'react';
import { Modal ,IconButton, Box, Grid, Card, CardContent, Button, Typography, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import Bookings_Add from './../back-end/reservation/Bookings-Add';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const CondensedTypography = styled(Typography)(({ theme }) => ({
    fontSize: '0.875rem',
    fontWeight: '900',
    display: 'flex',
    height: '30px',
    marginLeft: '5px',
  }));
  
  const CondensedButton = styled(Button)(({ theme }) => ({
      fontSize: '12px',
      padding: '5px 10px',
      float: 'right'
    }));


const RoomList = ({ user, cart, setCart, roomData, setRoomData, bookedRooms, setBookedRooms }) => {


    const [selectedRooms, setSelectedRooms] = useState([]);
    const [selectedFloor, setSelectedFloor] = useState(1);
    const [roomsOnFloor, setRoomsOnFloor] = useState([]);
    const [selectedRoomsData, setSelectedRoomsData] = useState([]);

    const [open, setOpen] = useState(false);
    const [modalcontanet, setModalContanet] = useState(null);
    const handleOpen = () => setOpen(true);
    const handleMClose = () => setOpen(false);
    const handleBackdropClick = (event) => {
        event.stopPropagation();
    };


    const addToCartBooking = async (values) => {
        setCart(prevCart => {
            const roomNames = values.frooms.map(room => room.room_no).join(', ');
            const firstRoomImage = values.frooms[0]?.images?.[0]?.file_path || '';

            const bookingItem = {
                id: 'reservation_'+values.booking_id, 
                product_image: firstRoomImage, 
                product_name: `${values.fguests.name} (${roomNames})`,
                category_id: "Rooms",
                check_in_date: values.fcheck_in_date,
                check_out_date: values.fcheck_out_date,
                booking_status: values.fbooking_status.name,
                selling_price: parseFloat(values.ftotal_price),
                quantity: 1,
                tax: '0.16'
            };       
            return {
                ...prevCart,
                guests: values.fnum_guests,
                items: [...prevCart.items, bookingItem],
                total_price: parseFloat(values.ftotal_price).toFixed(2),
                payment_method: values.fpayment_method.name,
                booking_status: values.fbooking_status.name
            };
        });    
        // Log the cart for debugging
        console.log(cart);
        console.log("-----------------------");
        console.log(values);
    };

    const handleModalClose = async (id) => {  
        handleMClose();
    }

    useEffect(() => {
        setRoomsOnFloor(roomData.filter(room => room.floor === selectedFloor));
    }, [selectedFloor, roomData]);

    useEffect(() => {
        if (bookedRooms.length > 0) {
            setSelectedRooms(bookedRooms);
        }
    }, [bookedRooms]);

    const toggleRoomSelection = (room) => {
        if (room.room_status_name === 'Occupied') {
            alert(`Room ${room.room_no} is already booked.`);
            return;
        }

        setSelectedRooms((prevSelectedRooms) => 
            prevSelectedRooms.includes(room.id)
                ? prevSelectedRooms.filter((id) => id !== room.id)
                : [...prevSelectedRooms, room.id]
        );

    };

    const bookSelectedRooms = () => {
        setBookedRooms(selectedRooms);
        const selectedRoomObjects = roomData.filter(room => selectedRooms.includes(room.id));
        setSelectedRoomsData(selectedRoomObjects);
        setModalContanet("add_booking");
        handleOpen();
    };

    return (
        <div style={{ padding: '10px' }}>
            <Typography variant="h6" gutterBottom>
                Room Booking Management
            </Typography>

            <FormControl fullWidth variant="outlined" style={{ marginBottom: '20px' }}>
                <InputLabel>Floor</InputLabel>
                <Select
                    value={selectedFloor}
                    onChange={(e) => setSelectedFloor(e.target.value)}
                    label="Floor"
                >
                    {[...new Set(roomData.map(room => room.floor))].map((floor) => (
                        <MenuItem key={floor} value={floor}>
                            Floor {floor}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Grid container spacing={2}>
                {roomsOnFloor.map((room) => (
                    <Grid item xs={12} sm={6} md={4} key={room.id}>
                        <Card
                            style={{
                                position: 'relative',
                                cursor: room.room_status_name === 'Occupied' ? 'not-allowed' : 'pointer',
                                border: selectedRooms.includes(room.id) 
                                    ? '2px solid #1976d2' 
                                    : '1px solid #ccc',
                                backgroundColor: selectedRooms.includes(room.id) 
                                    ? '#e3f2fd' 
                                    : room.room_status_name === 'Occupied' ? '#f8d7da' : 'white',
                                boxShadow: selectedRooms.includes(room.id) 
                                    ? '0 4px 8px rgba(0,0,0,0.2)' 
                                    : 'none',
                                borderRadius: '10px',
                            }}
                            onClick={() => toggleRoomSelection(room)}
                        >
                            <CardContent 
                                style={{ 
                                    position: 'relative', 
                                    textAlign: 'center',
                                    borderRadius: '10px',
                                    padding: '10px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    minHeight: '300px',
                                    backgroundColor: 'white',
                                }}
                            >
                                <div 
                                    style={{
                                        marginBottom: '15px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '150px',
                                        overflow: 'hidden',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <img
                                        src={room.images?.[0]?.file_path || "./../images/placeholder.jpg"} // Use actual room image or placeholder
                                        alt="Room"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                            borderRadius: '10px',
                                        }}
                                    />
                                </div>

                                <div style={{ textAlign: 'left' }}>
                                    <Typography variant="h6">{room.room_no}</Typography>
                                    <Typography variant="body1">{room.room_type}</Typography>
                                    <Typography variant="body1" style={{ fontWeight: 'bold', marginTop: '10px' }}>
                                        Kes {room.price} /=
                                    </Typography>
                                </div>

                                {room.room_status_name === 'Occupied' && (
                                    <Typography
                                        variant="body2"
                                        color="error"
                                        style={{ marginTop: '10px' }}
                                    >
                                        Booked
                                    </Typography>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={bookSelectedRooms}
                    disabled={selectedRooms.length === 0}
                >
                    Book Selected Rooms
                </Button>
            </div>

            <Modal
            open={open}
            onClose={handleMClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
                onClick: handleBackdropClick,
            }}
            >
            <Box sx={{ ...style, backgroundColor: '#ffffffd4', position: 'relative' , width:'30%' , height: 'auto' }}>
                <IconButton
                    aria-label="close"
                    onClick={handleMClose}
                    sx={{
                        position: 'absolute',
                        top: -20,
                        right: -20,
                        color: 'red',
                        backgroundColor: 'white',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 0, 0, 0.1)',
                        },
                    }}
                >
                    <CloseIcon />
                </IconButton>    
                {modalcontanet === 'add_booking' && <Bookings_Add addToCartBooking={addToCartBooking} handleModalClose={handleModalClose} selectedRoomsData={selectedRoomsData}/>}
            </Box>
        </Modal>

        </div>
    );
};

export default RoomList;
