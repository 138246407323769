import React, { useState, useEffect } from 'react';
import {
    IconButton, Card, CardContent, CardMedia, Modal, BottomNavigation, BottomNavigationAction, Paper,
    Container, TextField, Typography, Box, CssBaseline, List, Grid
} from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import RoomList from './RoomList';
import Totals from './Totals';


import axios from 'axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff2f28c',
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

const RoomReservation = ({ user , cart, setCart, furnituredata,setFurnituredata}) => {

    const [rooms, setRooms] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axios.post(`/reservation/room`, {
            });
           //console.log(response.data); 
           setRooms(response.data);
          } catch (error) {
            console.error('Error fetching room:', error);
          } finally {
        }
    }
    
    useEffect(() => {
        fetchData();
      }, []);
    
    



    return (
        <Container maxWidth={true} sx={{ width: '100%' }}>
            <Grid container spacing={1}>
                <Grid item xs={8}>
                    <Item>
                        <Box sx={{ pb: 7 }}>
                            <CssBaseline />
                            <List>

                                <RoomList user={user} cart={cart} setCart={setCart} roomData={rooms} bookedRooms={[]} setBookedRooms={(rooms) => []} />



                              
                            </List>
                        </Box>
                    </Item>
                </Grid>
                <Grid item xs={4}>
                    <Item><Totals user={user} cart={cart} setCart={setCart} /></Item>
                </Grid>
            </Grid>
        </Container>
    );
};
export default RoomReservation;
