import React, { useState, useEffect } from 'react';
import { Alert, Stack,Button , Modal ,IconButton , FormControl , FormHelperText ,
    TextField ,ToggleButton, ToggleButtonGroup, Container, Divider, Box, Grid, Paper, Card, CardContent, CardMedia, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { keyframes } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import AddDrop from './AddDrop';
import { Formik, Field, Form, ErrorMessage, useField } from "formik";
import * as Yup from "yup";
import ReportMain from './../back-end/reports/Reports';






const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CondensedButton = styled(Button)(({ theme }) => ({
    fontSize: '12px',
    margin: '0px',
    padding: '5px 0px',
    width: '100px',
}));

const CondensedTypography = styled(Typography)(({ theme }) => ({
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
    height: '30px',
    marginLeft: '5px',
}));
const CondensedTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
      fontSize: '18px',
      padding: '4px 8px', 
      height: '30px',    
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderWidth: '1px',
        textAlign:'right',
      },
    },
    margin: 0,
    '& input': {
      textAlign: 'right',
    },
  }));



  const PaymentSummary = ({ payment_data }) => {
    return (
      <Grid container spacing={1} alignItems="center" style={{ padding: '2%' }}>
        {/* Headers */}
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={4} sx={{ padding: "1px" }}>
            <CondensedTypography style={{ fontWeight: '900' }}>Payment Method</CondensedTypography>
          </Grid>
          <Grid item xs={4} sx={{ padding: "1px" }}>
            <CondensedTypography style={{ fontWeight: '900' }}>Sell</CondensedTypography>
          </Grid>
          <Grid item xs={4} sx={{ padding: "1px" }}>
            <CondensedTypography style={{ fontWeight: '900' }}>Expense</CondensedTypography>
          </Grid>
        </Grid>
  
        {/* Data Rows */}
        {payment_data.map((pay_data, index) => (
          <Grid container item xs={12} key={index} spacing={1}>
            <Grid item xs={4} sx={{ padding: "1px" }}>
              <CondensedTypography style={{ fontWeight: 'normal' }}>{pay_data?.payment_method}</CondensedTypography>
            </Grid>
            <Grid item xs={4} sx={{ padding: "1px" }}>
              <CondensedTypography style={{ fontWeight: 'normal' }}>KSh {pay_data?.amount}</CondensedTypography>
            </Grid>
            <Grid item xs={4} sx={{ padding: "1px" }}>
              <CondensedTypography style={{ fontWeight: 'normal' }}>KSh {pay_data?.expense}</CondensedTypography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  };
  


const columns_products_sold = [
    {
        field: 'id',
        headerName: 'Sku',
        flex: 0.2,
        renderCell: (params) => params.row.id || 'N/A',
    },
    {
      field: 'product_code',
      headerName: 'Code',
      flex: 0.4,
      renderCell: (params) => params.row.product_code || 'N/A',
   },
   {
    field: 'product_name',
    headerName: 'Product name',
    flex: 1,
    renderCell: (params) => params.row.product_name || 'N/A',
   },
   {
    field: 'quantity',
    headerName: 'Quantity',
    flex: 0.2,
    renderCell: (params) => params.row.quantity || 'N/A',
   },
   {
    field: 'unitcost',
    headerName: 'Price',
    flex: 0.1,
    renderCell: (params) => {
      return (
        <div style={{ width: '100%', textAlign: 'right' }}>
          {Number(params?.row?.unitcost).toFixed(2) || '0.00'}
        </div>
      );
    }
  },
  {
    field: 'total',
    headerName: 'Total',
    flex: 0.15,
    renderCell: (params) => {
      return (
        <div style={{ width: '100%', textAlign: 'right' }}>
          {Number(params?.row?.total).toFixed(2) || '0.00'}
        </div>
      );
    }
  }
];


const columns_products_sold_by_brand = [
    {
        field: 'id',
        headerName: '#',
        flex: 0.1,
        renderCell: (params) => params.row.supplier.id || 'N/A',
    },
    {
      field: 'brands',
      headerName: 'Brands',
      flex: 1,
      renderCell: (params) => params.row.supplier.name || 'N/A',
   },
   {
    field: 'quantity',
    headerName: 'Quantity',
    flex: 0.5,
    renderCell: (params) => params.row.total_products || 'N/A',
   },

   {
    field: 'total_amount',
    headerName: 'Total amount',
    flex: 0.2,
    renderCell: (params) => {
      return (
        <div style={{ width: '100%', textAlign: 'right' }}>
          {Number(params?.row?.total_amount).toFixed(2) || '0.00'}
        </div>
      );
    }
  }

  ];













const RegisterDetails = ({ type, user,handleCloseModal}) => {



    const [products_sold, setProductSold] = useState([]);
    const [products_sold_brand, setProductSoldBrand] = useState([]);
    const [payment_data, setPaymentData] = useState([]);
    const [register_data, setRegisterData] = useState([]);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleBackdropClick = (event) => {
        event.stopPropagation(); 
    };


    const [ropen, setrOpen] = useState(false);
    const handlerOpen = () => setrOpen(true);
    const handlerClose = (event) => {
      event.stopPropagation();
      setrOpen(false);
    }


    const [mopen, setmOpen] = useState(false);
    const handlemOpen = () => setmOpen(true);
    const handlemClose = () =>{ 
        setmOpen(false);
    }
    const [alertInfo, setAlertInfo] = useState({ show: false, severity: '', message: '' });

    const fetchTodaysRegister = async () => {
        try {
          const response = await axios.post(`/ui/register/today`, {
            user_id:user?.id
          });
         
          const products_sold = response?.data?.product_sold?.map((item) => {
            return {
                id: item.id,
                product_code: item.product.product_code,
                product_name: item.product.product_name,
                quantity: item.quantity,
                unitcost: item.unitcost,
                total: item.totalinc,
                supplier_name: item.product.supplier.name,
                created_at: new Date(item.created_at).toLocaleDateString(),
                order_id: item.order.id,
                customer_name: item.order.user.name
            };
          });
          setProductSold(products_sold);
          setProductSoldBrand(response?.data?.product_sold_brand);
          setPaymentData(response?.data?.payment_data);
          setRegisterData(response?.data?.register_data);
          } catch (error) {
            console.error('Error fetching register details:', error);
          } finally {
        }
      }
  
      useEffect(() => {
        fetchTodaysRegister();
      }, []);

      const today = dayjs().startOf('day');

      const formatDate = (date) => {
        return date ? dayjs(date).format('dddd, D MMMM, YYYY') : '';
      };

      const handleAddDrop = async () => {
        handleOpen();
      }

      const handlePrint = async () => {
        handlerOpen();
      }

      const validationSchema = Yup.object().shape({
        fclosing_amount: Yup.string().required('Please enter your closing amount!'),
        fnote: Yup.string().required('Closing note is required!')
     });
      
  
      const [formData, setFormData] = useState({
          fclosing_amount:null,
          fnote:null,
        });
  
  
      const handleFinish = async ( validateForm, setTouched, values) => {
        try {
          values.user_id = user?.id; 
          let amount_sales = register_data?.total_sales;
          let amount_paid = parseFloat(register_data?.total_payment);
          let amount_sale_variance = amount_sales - amount_paid;          

          if(amount_sale_variance > 0){
            setAlertInfo({
              show: true,
              severity: 'error',
              message: "Error closing register : Variance between Sales and amount paid (Ksh "+ amount_sale_variance.toFixed(2) +")!",
            });
            handlemOpen();
          }else{

            let amount_in_hand = payment_data.reduce((sum, item) => {
              const amount = parseFloat(item.amount.replace(/,/g, ''));
              return sum + amount;
            }, 0);

            let amount_less_drop = (parseFloat(register_data?.total_payment) - parseFloat(register_data?.total_drop));
            let amount_expected =  amount_in_hand + amount_less_drop;
            let amount_user = values.fclosing_amount; 
            
            if(amount_expected > parseFloat(amount_user.replace(/,/g, ''))){
              let amount_cumulative =  amount_expected - parseFloat(amount_user.replace(/,/g, ''));
              
              setAlertInfo({
                show: true,
                severity: 'error',
                message: "Error closing register : Variance between Cash in hand and sales made today. variance  (Ksh "+ amount_cumulative.toFixed(2) +")!",
              });
              handlemOpen();

            }else{
              handlemClose();
                const response = await axios.post(`/account/close`, {
                  values
                });
                if(response.data.error !== undefined){
                  alert(response.data?.error);
                  setAlertInfo({
                    show: true,
                    severity: 'error',
                    message: response.data?.error,
                  });
                  handlemOpen();
                  window.location.href = "/restaurant";
                }
                handleCloseModal();
                if(type === "close"){
                  window.location.href = "/";
                }
            }     
          }

        } catch (error) {
          console.error('Error creating a closing type:', error);
        } finally {
      }
    }
       

    return (
 <Container maxWidth={true} sx={{ width: '100%', textAlign: 'center', minHeight: 'auto', height: '90vh', overflowY: 'auto', overflowX: 'none' }}>
    <Formik
          initialValues={formData}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            console.log("Form submitted successfully", values);
          }}
        >
        {({errors, touched, validateForm, setTouched, values ,  setFieldValue, setFieldTouched , handleBlur }) => (
          <Form>           
             <Grid
                container
                spacing={1}
                direction="column"
                alignItems="left"
                justifyContent="left"
                sx={{ flexGrow: 1 }} 
            >

              <Stack direction="row" spacing={0} justifyContent="space-between" alignItems="center" sx={{ width: '100%',marginTop:'8px',marginBottom:'8px' }}>
                  <CondensedTypography style={{ fontWeight: '900', fontSize: '20px' }}>
                      Register Details ( { formatDate(today)} )
                  </CondensedTypography>
                  
                  <CondensedButton variant="outlined" sx={{ marginLeft: 'auto' }} onClick={handleAddDrop} >
                      Add Drop
                  </CondensedButton>
              </Stack> 
               
            </Grid>

            <Grid item xs={12} sx={{ width: '100%' }}>
                            <Paper
                                elevation={3}
                                sx={{
                                padding: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                }}
                              >
                            <PaymentSummary payment_data={payment_data}/>
                        </Paper>
            </Grid>





            <Grid item xs={12} sx={{ width: '100%', marginTop:'5px' }}>
                            <Paper
                                elevation={3}
                                sx={{
                                padding: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                }}
                            >

                                  <Grid container spacing={1} alignItems="center" style={{padding:'2%'}}>
                                           <Grid item xs={4} sx={{ padding: "1px" }}>
                                                    <CondensedTypography style={{ fontWeight: 'normal'  }}>Total Sales:</CondensedTypography>
                                                    <CondensedTypography style={{ fontWeight: '900' }}>Total Refund:</CondensedTypography>
                                                    <CondensedTypography style={{ fontWeight: '900' }}>Total Payment:</CondensedTypography>
                                                    <CondensedTypography style={{ fontWeight: '900' }}>Credit Sales:</CondensedTypography>                                              
                                                    <CondensedTypography style={{ fontWeight: '900' }}>Total Expense:</CondensedTypography>
                                            </Grid>
                                            <Grid item xs={8} sx={{ padding: "1px" }}>
                                                    <CondensedTypography style={{ fontWeight: 'normal' }}>KSh {register_data?.total_sales}</CondensedTypography>
                                                    <CondensedTypography style={{ fontWeight: 'normal' }}>KSh {register_data?.total_refund}</CondensedTypography>
                                                    <CondensedTypography style={{ fontWeight: 'normal' }}>
                                                      KSh {(parseFloat(register_data?.total_payment) - parseFloat(register_data?.total_drop)).toFixed(2)}
                                                      {register_data?.total_drop > 0 ? (
                                                        <>
                                                          &nbsp;&nbsp;Drop(s): KSh {(parseFloat(register_data?.total_drop)).toFixed(2)}
                                                        </>
                                                      ) : null}
                                                    </CondensedTypography> 
                                                    <CondensedTypography style={{ fontWeight: 'normal' }}>KSh {register_data?.credit_sales}</CondensedTypography>
                                                    <CondensedTypography style={{ fontWeight: 'normal' }}>KSh {register_data?.total_expense}</CondensedTypography>
                                            </Grid>
                                 </Grid>
                         </Paper>
                </Grid>



            <Grid
                container
                spacing={1}
                direction="column"
                alignItems="left"
                justifyContent="left"
                sx={{ flexGrow: 1 }} 
            >
                <Grid item xs={12} sx={{ width: '100%' }}>
                        <CondensedTypography style={{ fontWeight: '900' , width:'100%' , fontSize:'20px'  }}>Details of products sold</CondensedTypography>
                </Grid>
            </Grid>


            <Grid item xs={12} sx={{ width: '100%'}}>
                <Paper
                    elevation={3}
                    sx={{
                    padding: 0,
                
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    }}
                >
                <Grid container spacing={0} alignItems="left">
                              <Box sx={{ width: '100%'}}>
                                <DataGrid
                                        rows={products_sold}
                                        columns={columns_products_sold}
                                        initialState={{
                                            pagination: {
                                            paginationModel: { page: 0, pageSize: 10 },
                                            },
                                            }}
                                        rowHeight={30}
                                />
                                 </Box>

                </Grid>
          </Paper>
        </Grid>


        <Grid
                container
                spacing={1}
                direction="column"
                alignItems="left"
                justifyContent="left"
                sx={{ flexGrow: 1 }} 
            >
                <Grid item xs={12} sx={{ width: '100%' }}>
                        <CondensedTypography style={{ fontWeight: '900' , width:'100%' , fontSize:'20px'  }}>Details of products sold (By Brand) </CondensedTypography>
                </Grid>
        </Grid>





        <Grid item xs={12} sx={{ width: '100%' }}>
                <Paper
                    elevation={3}
                    sx={{
                    padding: 0,
                
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    }}
                >
                <Grid container spacing={0} alignItems="left">
                              <Box sx={{width: '100%' }}>
                                <DataGrid
                                        rows={products_sold_brand}
                                        columns={columns_products_sold_by_brand}
                                        initialState={{
                                            pagination: {
                                            paginationModel: { page: 0, pageSize: 10 },
                                            },
                                            }}
                                        rowHeight={30}
                                />
                                 </Box>

                </Grid>
          </Paper>
 </Grid>






 {type === "close" ? (
  <>
         <Grid item xs={12} sx={{ width: '100%',marginTop:'10px' }}>


                         <Paper
                             elevation={3}
                             sx={{
                             padding: 0,
                             display: 'flex',
                             justifyContent: 'center',
                             alignItems: 'center',
                             textAlign: 'center',
                             }}
                         >

                               <Grid container spacing={1} alignItems="center" style={{padding:'2%'}}>
                                        <Grid item xs={4} sx={{ padding: "1px" }}>
                                                 <CondensedTypography style={{ fontWeight: '900'  }}>Total Cash:*</CondensedTypography>
                                                     <FormControl variant="standard" style={{ width: '90%' }} error={Boolean(touched.fclosing_amount && errors.fclosing_amount)}>
                                                            <CondensedTextField
                                                                    name="fclosing_amount"
                                                                    variant="outlined"
                                                                    value={values.fclosing_amount}
                                                                    onChange={(e) => setFieldValue('fclosing_amount', e.target.value)}
                                                                    onBlur={handleBlur}
                                                                    InputProps={{
                                                                        readOnly: false,
                                                                    }}
                                                                    fullWidth
                                                            />
                                                                <ErrorMessage name="fclosing_amount">
                                                        {msg => <FormHelperText>{msg}</FormHelperText>}
                                                        </ErrorMessage>
                                                    </FormControl>
                                         </Grid>
                                         <Grid item xs={4} sx={{ padding: "1px" }}>
                                                 {/* <CondensedTypography style={{ fontWeight: '900' }}>Total Card Slips:</CondensedTypography>
                                                 <CondensedTextField
                                                            variant="outlined"
                                                            value=""
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            fullWidth
                                                 /> */}
                                         </Grid>
                                         <Grid item xs={4} sx={{ padding: "1px" }}>
                                                 {/* <CondensedTypography style={{ fontWeight: '900' }}>Total cheques:*</CondensedTypography>
                                                 <CondensedTextField
                                                            variant="outlined"
                                                            value=""
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            fullWidth
                                                 /> */}
                                         </Grid>

                                         <Grid item xs={12} sx={{ padding: "1px" }}>
                                         <FormControl variant="standard" style={{ width: '98%',marginTop:'8px' }} error={Boolean(touched.fnote && errors.fnote)}>
                                                <Field
                                                as={TextField}
                                                name="fnote"
                                                id="outlined-multiline-static"
                                                label="Additional notes"
                                                multiline
                                                rows={2}
                                                value={values.fnote}
                                                onChange={(e) => setFieldValue('fnote', e.target.value)}
                                                onBlur={handleBlur}
                                                placeholder="Enter your additional note"
                                                fullWidth
                                                />
                                                <ErrorMessage name="fnote">
                                                {msg => <FormHelperText>{msg}</FormHelperText>}
                                                </ErrorMessage>
                                            </FormControl>
                                         </Grid>


                              </Grid>
                     </Paper>
         </Grid></>
  ) : null}



















<Grid
        container
        spacing={1}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ flexGrow: 1 }} 
      >
        <Grid item xs={12} sx={{ width: '100%', marginTop:'10px' }}>
                            <Paper
                                elevation={3}
                                sx={{
                                padding: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                }}
                            >

                                  <Grid container spacing={1} alignItems="left" style={{padding:'2%'}}>
                                            <Grid item xs={6} sx={{ padding: "1px" }}>
                                                 <Stack direction="row" spacing={0}>
                                                    <CondensedTypography style={{ fontWeight: '900'}}>User:</CondensedTypography>
                                                    <CondensedTypography style={{ fontWeight: 'normal' }}>{user?.name}</CondensedTypography>
                                                </Stack>
                                                <Stack direction="row" spacing={0}>
                                                    <CondensedTypography style={{ fontWeight: '900' }}>Email:</CondensedTypography>
                                                    <CondensedTypography style={{ fontWeight: 'normal'  }}>{user?.email}</CondensedTypography>
                                                </Stack>
                                                <Stack direction="row" spacing={0}>
                                                    <CondensedTypography style={{ fontWeight: '900'  }}>Business :</CondensedTypography>
                                                    <CondensedTypography style={{ fontWeight: 'normal'  }}>{user.business[0]?.businessName}</CondensedTypography>
                                                </Stack>
                                                </Grid>
                                                <Grid item xs={6} sx={{ padding: "1px" }}>

                                                <Stack direction="row" spacing={0}>
                                                    <CondensedTypography style={{ fontWeight: '900'  }}>Business location:</CondensedTypography>                  
                                                    <CondensedTypography style={{ fontWeight: 'normal'  }}>{user.business[0]?.businessLocation}</CondensedTypography>
                                                </Stack>

                                                
                                                <Stack direction="row" spacing={0}>
                                                    <CondensedTypography style={{ fontWeight: '900'  }}>Branch:</CondensedTypography>                  
                                                    <CondensedTypography style={{ fontWeight: 'normal'  }}>{user.branch}</CondensedTypography>
                                                </Stack>

                                                <Stack direction="row" spacing={0}>
                                                    <CondensedTypography style={{ fontWeight: '900'  }}>Counter:</CondensedTypography>                  
                                                    <CondensedTypography style={{ fontWeight: 'normal'  }}>{user.counter}</CondensedTypography>
                                                </Stack>
                                            </Grid>
                                 </Grid>
                          </Paper>
      </Grid>
</Grid>

<Grid
        container
        spacing={1}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ flexGrow: 1 , marginTop:'0px'}}
      >
        <Grid item xs={12} sx={{ width: '100%' }}>
                            <Paper
                                elevation={3}
                                sx={{
                                padding: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                }}
                            >


{type === "close" ? (
<>
        <Grid container spacing={1} alignItems="left" style={{padding:'2%'}}>
                <Grid item xs={9.0} sx={{ padding: "1px" }}></Grid>
                {/* <Grid item xs={1.3} sx={{ padding: "1px" }}>
                    <CondensedButton variant="outlined" >
                        Cancel
                    </CondensedButton>
                </Grid> */}
                <Grid item xs={3} sx={{ padding: "1px" }}>
                    <CondensedButton variant="outlined" style={{width:'150px',float:'right'}} onClick={async () => {
                                                const isValid = await validateForm();
                                                if (Object.keys(isValid).length === 0) {
                                                     handleFinish(validateForm, setTouched, values);
                                                } else {
                                                setTouched({
                                                  fclosing_amount: true,
                                                    fnote:true
                                                });
                                              }
                                            }}>
                        Close Register
                    </CondensedButton>
                </Grid>
        </Grid>
  </>
) : <>
    <Grid container spacing={1} alignItems="left" style={{padding:'2%'}}>
            <Grid item xs={9} sx={{ padding: "1px" }}></Grid>
            <Grid item xs={3} sx={{ padding: "1px" }}>
                <CondensedButton onClick={handlePrint}  variant="outlined" style={{float:'right'}} >
                    Print
                </CondensedButton>
            </Grid>
    </Grid>
</>}


                            </Paper>
        </Grid>
</Grid>



<Modal
    open={ropen}
    onClose={handlerClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    BackdropProps={{
        onClick: handleBackdropClick,
    }}
  >
    <Box sx={{ ...style, backgroundColor: '#ffffffd4', position: 'relative' , width:'40%' , height: 'auto' }}>
        <IconButton
            aria-label="close"
            onClick={handlerClose}
            sx={{
                position: 'absolute',
                top: -20,
                right: -20,
                color: 'red',
                backgroundColor: 'white',
                '&:hover': {
                    backgroundColor: 'rgba(255, 0, 0, 0.1)',
                },
            }}
        >
            <CloseIcon />
        </IconButton>
           <ReportMain report_name={'register'}/>
    </Box>
</Modal>




<Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    BackdropProps={{
        onClick: handleBackdropClick,
    }}
  >
    <Box sx={{ ...style, backgroundColor: '#ffffffd4', position: 'relative' , width:'40%' , height: 'auto' }}>
        <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
                position: 'absolute',
                top: -20,
                right: -20,
                color: 'red',
                backgroundColor: 'white',
                '&:hover': {
                    backgroundColor: 'rgba(255, 0, 0, 0.1)',
                },
            }}
        >
            <CloseIcon />
        </IconButton>
            <AddDrop handleClose={handleClose} user={user} fetchTodaysRegister={fetchTodaysRegister} />
    </Box>
</Modal>


<Modal
    open={mopen}
    onClose={handlemClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    >
    <Box sx={style}>
      {alertInfo.show && (
        <Alert variant="outlined" severity={alertInfo.severity} onClose={() => {
          setAlertInfo({ ...alertInfo, show: false });
          handlemClose();
        }}>
          {alertInfo.message}
        </Alert>
      )}
    </Box>
  </Modal>






         </Form>
        )}
</Formik>

</Container>);
};
export default RegisterDetails;