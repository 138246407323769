import React, { useState , useEffect  } from 'react';
import { Stack, ToggleButton, ToggleButtonGroup,Button, Card, CardContent, Grid, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';

const TableList = ({cart, setCart,furnituredata,setFurnituredata}) => {

    const [floor, setFloor] = useState(furnituredata[0]?.name || '');
    const [isLoaded, setIsLoaded] = useState(false);
    const [tables, setTables] = useState([]);
    const [selectedTables, setSelectedTables] = useState([]);

    useEffect(() => {
        if (!isLoaded && furnituredata.length > 0) {            
            setFloor(furnituredata[0]?.name || '');
            setIsLoaded(true);
            if(cart?.tableids.length > 0){
                setSelectedTables(cart?.tableids);
               }else{
                setSelectedTables([furnituredata[0]?.tables[0]?.id]);
            }
            
        }
    }, [furnituredata, isLoaded]);


    useEffect(() => {
        const selectedTableNames = [];
        let totalGuests = 0;
    
        furnituredata.forEach(floor => {
            floor.tables.forEach(table => {
                if (selectedTables.includes(table.id)) {
                    selectedTableNames.push(table.name);
                    totalGuests += table.seats.length;
                }
            });
        });
    
        setCart(prevCart => ({
            ...prevCart,
            table: selectedTableNames.join(', '),
            tableids:selectedTables,
            guests: totalGuests
        }));
    }, [selectedTables, setCart, furnituredata]);



    useEffect(() => {
        const selectedFloor = furnituredata.find(f => f.name === floor);
        if (selectedFloor) {
            const initializedTables = selectedFloor.tables.map(table => ({
                id: table.id,
                name: table.name,
                seats: table.seats
            }));
            setTables(initializedTables);
        }
    }, [floor]);

    const handleFloorChange = (event, newFloor) => {
        if (newFloor) {
            setFloor(newFloor);
        }
    };

    const addTable = async () => {
        const currentFloor = furnituredata.find(f => f.name === floor);
        if (currentFloor) {
            const floorId = currentFloor.id;
            try {
                const response = await axios.post('/ui/furniture-add-table', {
                    floor_id: floorId
                });
                const updatedTable = response.data.table;
                await setFurnituredata(furnituredata.map(floor => (
                    floor.id === floorId
                        ? {
                            ...floor,
                            tables: [...floor.tables, updatedTable]
                        }
                        : floor
                )));

            setTables([...tables, { id: updatedTable.id, name :updatedTable.name, seats: updatedTable.seats }]);
            setSelectedTables([updatedTable.id]);
    
            } catch (error) {
                console.error('Error adding table:', error);
            }finally{
                
            }
        } else {
            alert('Floor not found');
        }
    };


    const addSeat = async (tableId, count) => {
        try {
            const response = await axios.post('/ui/furniture-add-seat', {
                table_id: tableId,
                count: count
            });
            const updatedTable = response.data.table;
            setTables(tables.map(table =>
                table.id === tableId
                    ? {
                        ...table,
                        seats: [
                            ...table.seats,
                            { id: table.seats.length + 1, name: `Seat ${table.seats.length + 1}` }
                        ]
                    }
                    : table
            ));
            setFurnituredata(furnituredata.map(floor => ({
                ...floor,
                tables: floor.tables.map(table =>
                    table.id === tableId
                        ? updatedTable
                        : table
                )
            })));

            setSelectedTables(prevSelectedTables => {         
                if (prevSelectedTables.includes(tableId)) {
                    return prevSelectedTables; 
                } else {          
                    return [...prevSelectedTables, tableId];
                }
            });

        } catch (error) {
            console.error('Error adding seat:', error);
        }
    };

    const removeSeat = async (tableId) => {
        try {
            const response = await axios.post('/ui/furniture-remove-seat', {
                table_id: tableId
            });
                setTables(tables.map(table =>
                    table.id === tableId && table.seats.length > 0
                        ? {
                            ...table,
                            seats: table.seats.slice(0, -1)
                        }
                        : table
                ));
                const updatedTable = response.data.table;
                setFurnituredata(furnituredata.map(floor => ({
                    ...floor,
                    tables: floor.tables.map(table =>
                        table.id === tableId
                            ? updatedTable
                            : table
                    )
                })));
                setSelectedTables(prevSelectedTables => {         
                    if (prevSelectedTables.includes(tableId)) {
                        return prevSelectedTables; 
                    } else {          
                        return [...prevSelectedTables, tableId];
                    }
                });
        } catch (error) {
            console.error('Error removing seat:', error);
        }
    };


    const removeTable = async (tableId) => {
        try {
            const response = await axios.post('/ui/furniture-remove-table', {
                table_id: tableId
            });
    
            if (response.data.success) {
                setFurnituredata(furnituredata.map(floor => ({
                    ...floor,
                    tables: floor.tables.filter(table => table.id !== tableId)
                })));
                setTables(tables.filter(table => table.id !== tableId));
            } else {
                alert('Failed to remove table');
            }
        } catch (error) {
            console.error('Error removing table:', error);
        }
    };

    const toggleTableSelection = (tableId) => {
        setSelectedTables(prevSelectedTables =>
            prevSelectedTables.includes(tableId)
                ? prevSelectedTables.filter(id => id !== tableId)
                : [...prevSelectedTables, tableId]
        );
    };
    return (
        <div style={{ padding: '10px' }}>
            <Typography variant="h6" gutterBottom>
                Restaurant Table Management
            </Typography>

            <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                style={{ marginBottom: '20px', width: '100%' }}
            >
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<AddIcon />}
                    onClick={addTable}
                >
                    Add Table
                </Button>

                <div style={{ flexGrow: 1 }} />


                <ToggleButtonGroup
                    value={floor}
                    exclusive
                    onChange={handleFloorChange}
                    aria-label="floor selection"
                    style={{ maxHeight: '35px' }}
                >
                    {furnituredata.map((floorData) => (
                        <ToggleButton key={floorData.id} value={floorData.name} aria-label={floorData.name.toLowerCase()}>
                            {floorData.name}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>



            </Stack>
            <Grid container spacing={2}>
                {tables.map(table => (
                    <Grid item xs={12} sm={6} md={4} key={table.id}>
                        <Card
                            style={{
                                position: 'relative',
                                cursor: 'pointer',
                                border: selectedTables.includes(table.id) ? '2px solid #1976d2' : '1px solid #ccc',
                                backgroundColor: selectedTables.includes(table.id) ? '#e3f2fd' : 'white',
                                boxShadow: selectedTables.includes(table.id) ? '0 4px 8px rgba(0,0,0,0.2)' : 'none', borderRadius: '10%'
                            }}
                            onClick={() => toggleTableSelection(table.id)}
                        >
                            <CardContent style={{ position: 'relative', textAlign: 'center',
                                    backgroundImage: 'url(./../images/room.jfif)',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    borderRadius: '10%'
                                 }}>
                                <Typography variant="h6">{table.name}</Typography>
                                <div style={{
                                    position: 'relative',
                                    margin: '20px auto',
                                    width: '150px',
                                    height: '150px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <div style={{
                                        position: 'absolute',
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        {Array.from({ length: table.seats.length }).map((_, index) => (

                                                    <div
                                                        key={index}
                                                        style={{
                                                            position: 'absolute',
                                                            width: '50px',
                                                            height: '50px',
                                                            borderRadius: '50%',
                                                            backgroundImage: 'url(./../images/seat.jfif)',
                                                            backgroundSize: 'cover',
                                                            backgroundPosition: 'center',
                                                            backgroundRepeat: 'no-repeat',
                                                            color: 'white',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            fontSize: '14px',
                                                            transform: `rotate(${(360 / table.seats.length) * index}deg) translateX(75px)`,
                                                            transformOrigin: 'center'
                                                        }}
                                                    >
                                                        {index + 1}
                                                    </div>
                                        ))}
                                    </div>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            width: '80px',
                                            height: '80px',
                                            borderRadius: '50%',
                                            overflow: 'hidden',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                       >
                                        <img
                                            src="./../images/table.jfif"
                                            alt="Table"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                            }}
                                        />
                                    </div>
                                </div>
                                <Button
                                    variant="outlined"
                                    color="success"
                                    startIcon={<AddIcon />}
                                    onClick={() => addSeat(table.id,table.seats.length)}
                                    style={{ margin: '5px' }}
                                >
                                    Add Seat
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    startIcon={<RemoveIcon />}
                                    onClick={() => removeSeat(table.id)}
                                    style={{ margin: '5px' }}
                                >
                                    Remove Seat
                                </Button>
                                <IconButton
                                    color="error"
                                    onClick={() => removeTable(table.id)}
                                    style={{ position: 'absolute', top: '10px', right: '10px' }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};
export default TableList;
