
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { styled } from '@mui/material/styles';
import {List, ListItem, ListItemButton, ListItemIcon, Typography , Grid , InputLabel, FormControl , NativeSelect } from '@mui/material';

const CondensedTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: '900',
  display: 'flex',
  alignItems: 'center',
  height: '30px',
  marginLeft: '5px',
}));
const ShortcutDrawer = React.forwardRef((props, ref  ) => {
  const [sopen, setSOpen] = React.useState(false);
  const toggleSDrawer = (newOpen) => {
    setSOpen(newOpen);
  };
  React.useImperativeHandle(ref, () => ({
    openDrawer: () => toggleSDrawer(true),
    closeDrawer: () => toggleSDrawer(false),
  }));

  const DrawerComponent = (
    <Box sx={{ width: 100, paddingTop: '10%' }} role="presentation">


        <List>    
          <ListItem >
            <ListItemButton>
              {/* <ListItemIcon>                
              </ListItemIcon> */}
              <ListItemText primary="F1" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem >
            <ListItemButton>
              {/* <ListItemIcon>                
              </ListItemIcon> */}
              <ListItemText primary="F2" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem >
            <ListItemButton>
              {/* <ListItemIcon>                
              </ListItemIcon> */}
              <ListItemText primary="F3" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem >
            <ListItemButton>
              {/* <ListItemIcon>                
              </ListItemIcon> */}
              <ListItemText primary="F4" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem >
            <ListItemButton>
              {/* <ListItemIcon>                
              </ListItemIcon> */}
              <ListItemText primary="F5" />
            </ListItemButton>
          </ListItem>
      
      </List>

      <Divider />
      <Box sx={{ width: 380, paddingTop: '2%' }} role="presentation">
       
      </Box>
         
    </Box>
  );

  return (
    <Drawer
     anchor="right"
      open={sopen}
      onClose={() => toggleSDrawer(false)}
    >
      {DrawerComponent}
    </Drawer>
  );
});
export default ShortcutDrawer;
