import React, { useState, useEffect } from 'react';
import {
    Button , MenuItem , Select, InputLabel , FormControl, IconButton, Card, CardContent, CardMedia, Modal, BottomNavigation, BottomNavigationAction, Paper,
    Container, TextField, Typography, Box, CssBaseline, List, Grid
} from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';


import * as XLSX from 'xlsx';
import { DataGrid } from '@mui/x-data-grid';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import  Download  from '@mui/icons-material/Download';
import dayjs from 'dayjs';
import ProductCard from './ProductCard';
import Totals from './Totals';
import { keyframes } from '@mui/system';

import axios from 'axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff2f28c',
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

const formatDate = (date) => {
    return date ? dayjs(date).format('YYYY-MM-DD') : '';  };

const paperStyle = { padding: 20, height: '85vh', width: 'auto', margin: "0px auto", backgroundColor: 'rgb(221 215 201 / 45%)', borderRadius: '12px', boxShadow: '0px 0px 8px rgba(0, 0, 0, 25)' , maxHeight:'85vh',overflowY: 'scroll' };


const ProductSearch = ({searchterm, setSearchterm ,user , cart , setCart}) => {  
    const [data, setData] = useState([]);


    const handleAddToCart = (product) => {
        setCart(prevCart => {
            const existingProduct = prevCart.items.find(item => item.product_name === product.product_name);
    
            if (existingProduct) {
                return {
                    ...prevCart,
                    items: prevCart.items.map(item =>
                        item.product_name === product.product_name
                            ? { ...item, quantity: item.quantity + 1 }
                            : item
                    )
                };
            } else {
                return {
                    ...prevCart,
                    items: [...prevCart.items, { ...product, quantity: 1 }]
                };
            }
        });
    };

    const fetchData = async (term) => {
        try {
          const response = await axios.post(`/ui/products-search`, {
            search_term:term
          });
          setData(response.data);
        } catch (error) {
          console.error('Error fetching products:', error);
        } finally {
        }
    }

    useEffect(() => {
        if(searchterm){
            fetchData(searchterm);
        }       
        setSearchterm("");
      }, [searchterm]);
return (
        <Container maxWidth={true} sx={{ width: '100%'}}>
            <Grid container spacing={1}>
                <Grid item xs={8}>
                    <Item>                  
                        <Paper elavation={12} style={paperStyle}>


                        <div>
                            {data.length === 0 ? (
                                <Typography
                                    variant="h6"
                                    align="center"
                                    color="textSecondary"
                                    style={{ padding: '20px', fontWeight: 'bold' }}
                                >
                                    No products found
                                </Typography>
                            ) : (
                                <Grid container spacing={1} direction="row" alignItems="center">
                                    {data.map(product => (
                                        <Grid item key={product.id}>
                                            <ProductCard
                                                key={product.id}
                                                product={product}
                                                onAddToCart={handleAddToCart}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                          </div>
                        </Paper>
                    </Item>
                </Grid>
                <Grid item xs={4}>
                    <Item><Totals user={user} cart={cart} setCart={setCart} /></Item>
                </Grid>
            </Grid>
        </Container>
    );
};
export default ProductSearch;
