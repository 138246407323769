import React, { useState, useEffect } from 'react';
import {
    IconButton, Card, CardContent, CardMedia, Modal, BottomNavigation, BottomNavigationAction, Paper,
    Container, TextField, Typography, Box, CssBaseline, List, Grid
} from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import TableList from './TableList';
import Totals from './Totals';


import axios from 'axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff2f28c',
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

const Home = ({ user , cart, setCart, furnituredata,setFurnituredata}) => {

    const [value, setValue] = useState(0);
    const [data, setData] = useState([]);
    const [categorydata, setCategorydata] = useState([]);
    const [categorytab, setCategorytab] = useState(0);
    const [open, setOpen] = useState(false);
    const [categoryid, setCategoryId] = useState(null);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleBackdropClick = (event) => {
        event.stopPropagation();
    };

    const mainTabs = async () => {
        try {
            const response = await axios.post('/ui/categories', {
                parent_id: null,
                business_category_id: 3
            });
            setData(response.data);       
            setCategoryId(response.data[0]?.id);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const categoriesTabs = async (categorytab) => {
        try {
            const response = await axios.post('/ui/categories', {
                parent_id: categorytab,
                business_category_id: 3
            });
            setCategorydata(response.data); 
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    useEffect(() => {
        //mainTabs();
        if(categoryid > 0){
            //categoriesTabs(categoryid);
            //setCategoryId(categoryid);
        }        
    }, [categoryid]);


    return (
        <Container maxWidth={true} sx={{ width: '100%' }}>
            <Grid container spacing={1}>
                <Grid item xs={8}>
                    <Item>
                        <Box sx={{ pb: 7 }}>
                            <CssBaseline />
                            <List>
                               <TableList cart={cart} setCart={setCart} furnituredata={furnituredata} setFurnituredata={setFurnituredata}/>
                            </List>
                        </Box>
                    </Item>
                </Grid>
                <Grid item xs={4}>
                    <Item><Totals user={user} cart={cart} setCart={setCart} /></Item>
                </Grid>
            </Grid>
        </Container>
    );
};
export default Home;
