import React, { useEffect , useState  } from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles'; 
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


const CondensedTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '13px',
    padding: '0px 0px',
    height: '30px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '1px',
      textAlign:'right',
    },
  },
  margin: 0,
  '& input': {
    textAlign: 'right',
  },
}));

function createDbData(
  id, 
  product_code, 
  product_name, 
  product_garage, 
  product_image, 
  selling_price,
  quantity,
  sub_total,
  tax
) {
  return { 
    id, 
    product_code, 
    product_name, 
    product_garage, 
    product_image, 
    selling_price,
    quantity,
    sub_total,
    tax
  };
}


function preventDefault(event) {
  event.preventDefault();
}

export default function Orders({ searchValue , setTotalSection , cartValues,  setCartValues }) {

  function calculateTotals(cartValues) {
    let totalQuantity = 0;
    let subTotal = 0;
    let tax = 0.16;
    let taxValue = 0;
    let grandTotal =  0;

    //console.log(cartValues);

    for (let item of cartValues) {
      totalQuantity += item.quantity;
      subTotal += item.sub_total;
      taxValue += parseFloat(item.taxValue);
    }

    grandTotal = parseFloat(taxValue) + parseFloat(subTotal);
    let totalsArray = {
      totalQuantity: totalQuantity,
      subTotal: parseFloat(subTotal).toFixed(2),
      taxValue: taxValue.toFixed(2),
      grandTotal: parseFloat(grandTotal).toFixed(2)
    };
    //console.log(totalsArray);
    return totalsArray;

  }

const handleQuantityChange = async (id, newQuantity) => {
   setCartValues(cartValues.map(row =>
        row.id === id ? {
            ...row,
            quantity: newQuantity,
            sub_total: newQuantity * row.selling_price,
            taxValue : (newQuantity * row.selling_price) * row.tax
        } : row
    ));
};

useEffect(() => {
  let totals = calculateTotals(cartValues);
  setTotalSection(totals);
  setCartValues(cartValues);
}, [cartValues]);

const handleDelete = (id) => {
  setCartValues(cartValues.filter(row => row.id !== id));
};


function sumQuantitiesAndMergeItems(items) {
  const mergedItemsMap = {};
  items.forEach(item => {
      if (mergedItemsMap.hasOwnProperty(item.id)) {
          mergedItemsMap[item.id].quantity += item.quantity;
          mergedItemsMap[item.id].sub_total += item.sub_total;
          mergedItemsMap[item.id].tax += item.tax;
      } else {
          mergedItemsMap[item.id] = {
              ...item
          };
      }
  });
  const mergedItemsArray = Object.values(mergedItemsMap);
  return mergedItemsArray;
}

useEffect(() => {
  const handleNewValue = () => {

    if (searchValue) {
      if(searchValue.length > 0){
          const dataItems = searchValue[0];
          const mergedItems = sumQuantitiesAndMergeItems(dataItems);
          setCartValues(mergedItems);
      }else{
              setCartValues((prevValues) => {
                const existingItem = prevValues.find(item => item.id === searchValue.id);
                          if (existingItem) {
                                return prevValues.map(item =>
                                    item.id === searchValue.id
                                      ? {
                                      ...item,
                                          quantity: item.quantity + 1,
                                          sub_total: ((item.quantity + 1) * item.selling_price),
                                          tax: item.tax
                                    }
                                        : item
                            );
                          } else {
                            return [
                              ...prevValues,
                              createDbData(
                                    searchValue.id,
                                    searchValue.product_code,
                                    searchValue.product_name,
                                    searchValue.product_garage,
                                    searchValue.product_image,
                                    searchValue.selling_price,
                                    1,
                                    (searchValue.selling_price * 1),
                                    searchValue.tax
                                    )
                                ];
                              }
                          });
          }

    }

  };

  handleNewValue();

}, [searchValue]);


return (
    <React.Fragment>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Code</TableCell>
            <TableCell>Product</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Price</TableCell>
            <TableCell align="right">Subtotal</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cartValues.map((row) => (
            <TableRow key={row.id}>
              <TableCell style={{width:'10%'}}>
                 {/* <Avatar style={{height:"35px",width:"35px"}} alt={row.product_name} src={`/storage/products/${row.product_image}?w=248&fit=crop&auto=format`} />  */}
                 <Avatar style={{height:"35px",width:"35px"}} alt={row.product_name} src={row.product_image} />
                </TableCell>
              <TableCell>{row.product_code}</TableCell>
              <TableCell style={{width:'40%'}}>{row.product_name}</TableCell>
              <TableCell>
                    <CondensedTextField
                        id="outlined-number"
                        label=""
                        type="number"
                        value= {row.quantity}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => handleQuantityChange(row.id, parseInt(e.target.value))}
                    />
              </TableCell>
              <TableCell align="right">{row.selling_price?.toFixed(2)}</TableCell>
              {/* <TableCell align="right">{parseFloat(row.sub_total?.toFixed(2))}</TableCell> */}
              <TableCell align="right">
                {row.sub_total ? parseFloat(row.sub_total).toFixed(2) : "0.00"}
              </TableCell>
              <TableCell><DeleteForeverIcon onClick={() => handleDelete(row.id)} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}