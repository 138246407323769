
import React, { useRef , useState , useEffect  } from 'react';

import { useNavigate  } from 'react-router-dom';
import {MenuItem ,Button , Select, InputLabel , FormControl , Modal, Dialog, DialogActions , DialogContent ,DialogTitle,Alert,  Container, TextField, Typography, Box, Paper } from '@mui/material';
import AppAppBar from './AppAppBar';
import Orders from './Orders';
import TotalsForm from './TotalsForm';
import CustomerPanel from './CustomerPanel';
import ScanInputs from './ScanInputs';
import RegisterMode from './RegisterMode';
import  DrawerCart from './DrawerCart';
import  ShortcutDrawer from './ShortcutDrawer';
import Login from './Login';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import './App.css';
import  Download  from '@mui/icons-material/Download';
import * as XLSX from 'xlsx';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { useTreeViewApiRef } from '@mui/x-tree-view/hooks';

const CondensedTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
      fontSize: '18px',
      padding: '4px 8px', 
      height: '30px',    
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderWidth: '1px',
        textAlign:'right',
      },
    },
    margin: 0,
    '& input': {
      textAlign: 'right',
    },
  }));

  const CondensedTypography = styled(Typography)(({ theme }) => ({
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
    height: '30px',
    marginLeft: '5px',
  }));  

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.container,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


function Items() {

    const [userDetails, setUserDetails] = useState(null);
    const [data, setData] = useState();
    const [filteredRows, setFilteredRows] = useState();
    const [selectedFields, setSelectedFields] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedItems, setSelectedItems] = React.useState([]);
    const apiRef = useTreeViewApiRef();
    const toggledItemRef = React.useRef({});


    useEffect(() => {
        axios.get('/ui/categories-fetch')
          .then(response => setCategories(response.data))
          .catch(error => console.error('Error fetching categories:', error));
      }, []);
    
    const handleItemSelectionToggle = (event, itemId, isSelected) => {
        toggledItemRef.current[itemId] = isSelected;
      };

    const getItemDescendantsIds = (item) => {
        const descendants = [];
        const getDescendants = (item) => {
          if (item.children) {
            item.children.forEach((child) => {
              descendants.push(child.id);
              getDescendants(child);
            });
          }
        };
        getDescendants(item);
        return descendants;
      };
      const handleSelectedItemsChange = (event, newSelectedItems) => {
        setSelectedItems(newSelectedItems);
        const itemsToSelect = [];
        const itemsToUnSelect = {};
        Object.entries(toggledItemRef.current).forEach(([itemId, isSelected]) => {
          const item = apiRef.current.getItem(itemId);
          if (isSelected) {
            itemsToSelect.push(...getItemDescendantsIds(item));
          } else {
            getItemDescendantsIds(item).forEach((descendantId) => {
              itemsToUnSelect[descendantId] = true;
            });
          }
        });
        const newSelectedItemsWithChildren = Array.from(
          new Set(
            [...newSelectedItems, ...itemsToSelect].filter(
              (itemId) => !itemsToUnSelect[itemId],
            ),
          ),
        );
        setSelectedItems(newSelectedItemsWithChildren);    
        toggledItemRef.current = {};
      };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleBackdropClick = (event) => {
        event.stopPropagation();
      };
    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
        const filtered = data.filter((row) => {
        return row.product_name.toLowerCase().includes(value);
        })
        setFilteredRows(filtered);
    };

    const handleFieldChange = (event) => {
        setSelectedFields(event.target.value);
    };

    const handleExport = () => {
        const dataxs = data.map(row => ({
          'Id': row.id,
          'Code': row.product_code,
          'Product name': row.product_name,
          'Quantity in store': row.product_store,
          'Buying date': row.buying_date,
          'Expiry date': row.expire_date,
          'Buying price': row.buying_price,
          'Location' : row.product_garage,
          'Category' : row.category?.name,
          'Supplier name' : row.supplier?.name,
          'Supplier phone' : row.supplier?.phone
        }));
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(dataxs);
        XLSX.utils.book_append_sheet(wb, ws, 'Products Data');
        XLSX.writeFile(wb, 'Products_data.xlsx');
      };

    const toggleColorMode = () => {
        setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
      };

      const checkUserDetails = async () => {
        try {
            const response = await axios.get('/ui/check-user-session');
            if (response.data.user) {
                setUserDetails(response.data.user);
                setOpen(false);
            } else {
                setOpen(true);
            }
        } catch (error) {
            console.error('Error checking user session:', error);
            setOpen(true);
        }
      };
  
      useEffect(() => {
        document.body.style.backgroundImage = "url('./images/home-1.jpg')";
        document.body.style.backgroundSize = '100%';
        document.body.style.backgroundPosition = 'top';
        checkUserDetails();
        return () => {
          document.body.style.backgroundImage = '';
          document.body.style.backgroundSize = '';
          document.body.style.backgroundPosition = '';
        };
      }, []);


      const fetchData = async () => {
        try {
          const response = await axios.get(`/ui/products`, {
          });
          setData(response.data);
        } catch (error) {
          console.error('Error fetching sale items:', error);
        } finally {
        }
      }

      const fetchDataPerCategory = async (selectedItems) => {
        try {
          const response = await axios.post(`/ui/products-category`, {
            "categories":selectedItems
          });
          setData(response.data);
        } catch (error) {
          console.error('Error fetching sale items:', error);
        } finally {
        }
      }

      useEffect(() => {
        fetchDataPerCategory(selectedItems);
      }, [selectedItems]);

      useEffect(() => {
        fetchData();
      }, []);

      useEffect(() => {
        setFilteredRows(data);
      }, [data]);

    const columns = [
        { field: 'id', headerName: '#', flex: 0.1 },
        { field: 'product_code', headerName: 'Code' , flex: 1 },

        {
            field: 'category',
            headerName: 'Category',
            flex: 0.8,
            renderCell: (params) => params.row?.category?.name || 'N/A',
        },

        { field: 'product_name', headerName: 'Product name' , flex: 1 },
        { field: 'product_garage', headerName: 'Location' , flex: 0.8 },
        { field: 'product_store', headerName: 'Qty in store' , flex: 1 },

        {
            field: 'buying_price',
            headerName: 'Buying price',
            flex: 0.5,
            renderCell: (params) => {
              return (
                <div style={{ width: '100%', textAlign: 'right' }}>
                  {Number(params?.row?.buying_price).toFixed(2) || '0.00'}
                </div>
              );
            }
          },

          {
            field: 'selling_price',
            headerName: 'Selling price',
            flex: 0.5,
            renderCell: (params) => {
              return (
                <div style={{ width: '100%', textAlign: 'right' }}>
                  {Number(params?.row?.selling_price).toFixed(2) || '0.00'}
                </div>
              );
            }
          },

        {
            field: 'supplier',
            headerName: 'Supplier',
            flex: 0.8,
            renderCell: (params) => params.row?.supplier?.name || 'N/A',
        },
        {
            field: 'supplier',
            headerName: 'Supplier phone',
            flex: 0.8,
            renderCell: (params) => params.row?.supplier?.phone || 'N/A',
        },
        {
          field: 'buying_date',
          headerName: 'Buying date',
          flex: 0.6,
          valueFormatter: (params) => {
            return dayjs(params.value).format('DD MMM YYYY HH:mm:ss');
          }
        },
        {
            field: 'expire_date',
            headerName: 'Expiry date',
            flex: 0.6,
            valueFormatter: (params) => {
              return dayjs(params.value).format('DD MMM YYYY HH:mm:ss');
            }
          },
      ];

      const getRowClassName = (params) => {
        return params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row';
      };







    
    












      const [cartValues, setCartValues] = useState([]);

      const fullScreen = () => {
        const elem = document.documentElement;
        if (!document.fullscreenElement) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            }
            setIsFullscreen(true);
        } else {
  
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
            setIsFullscreen(false);
        }
    };
  
    const closeRegister =  () => {
          setModalContent('close_register');
          handledOpen();
      };
      
      const registerDetails =  () => {
          setModalContent('register_details');
          handledOpen();
      };
      
  



    return (
      <div>
      {/* <AppAppBar mode="light" toggleColorMode={toggleColorMode} userDetails={userDetails} setUserDetails={setUserDetails}/> */}
      <AppAppBar registerDetails={registerDetails} closeRegister={closeRegister} fullScreen={fullScreen} cart={cartValues} mode="light" toggleColorMode={toggleColorMode} userDetails={userDetails} setUserDetails={setUserDetails} handleOpen={handleOpen} />
        <Container maxWidth="" style={{ marginTop: '100px' }}>
          <Box sx={{ flexGrow: 1 }}>
             <Typography variant="h6" component="h6" style={{backgroundColor:'white',marginBottom:'2px',borderRadius:'0%',paddingLeft:'1%'}}>
                Products
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'right', mb: 2 }} style={{background:"white",marginTop:"2px",marginBottom:"2px"}}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              </Box>
                <Box sx={{ display: 'flex', alignItems: 'right', gap: 2 }} style={{background:"white",marginTop:"5px",marginBottom:"5px",marginRight:"5px"}}>
                    <TextField
                    label="Search"
                    variant="outlined"
                    size="small"
                    value={searchTerm}
                    onChange={handleSearch}
                    />
                    <FormControl variant="outlined" size="small" style={{minWidth:'150px'}}>
                        <InputLabel id="fields-select-label">Fields</InputLabel>
                        <Select
                        labelId="fields-select-label"
                        multiple
                        value={selectedFields}
                        onChange={handleFieldChange}
                        label="Fields"
                        >
                        {columns.map((column) => (
                            <MenuItem key={column.field} value={column.field}>
                            {column.headerName}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    <Button variant="contained" startIcon={<Download />} onClick={handleExport}>
                        Export
                    </Button>
                </Box>
                </Box>
                <Grid container spacing={0}>
                    <Grid xs={3} md={3} style={{background:"#f5f5f5"}}>
                            <Typography>Category</Typography>
                                <RichTreeView
                                multiSelect
                                checkboxSelection
                                apiRef={apiRef}
                                items={categories}
                                selectedItems={selectedItems}
                                onSelectedItemsChange={handleSelectedItemsChange}
                                onItemSelectionToggle={handleItemSelectionToggle}
                                />
                    </Grid>
                     <Grid xs={9} md={9}>
                            <DataGrid
                                rows={filteredRows}
                                columns={columns.filter((column) => selectedFields.length === 0 || selectedFields.includes(column.field))}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 20 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                rowHeight={30}
                                getRowClassName={getRowClassName}
                            />
                    </Grid>
                 </Grid>
          </Box>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
              onClick: handleBackdropClick,
            }}
            >
            <Box sx={style} style={{backgroundColor: '#f1dbdb47'}}>
                <Login onLoginSuccess={handleClose} setUserDetails={setUserDetails} checkUserDetails={checkUserDetails} />
            </Box>
          </Modal>
        </Container>
    </div>
    );
}
export default Items;
