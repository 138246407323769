import React, { useEffect , useState ,useRef  } from 'react';
import { Alert , Modal, Divider , Button , Stack , Container, TextField, Typography, Box, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import Add from '@mui/icons-material/Add';
import AttachMoney from '@mui/icons-material/AttachMoney';
import LocalPrintshop from '@mui/icons-material/LocalPrintshop';
import AccessAlarm from '@mui/icons-material/AccessAlarm';
import Grid from '@mui/material/Unstable_Grid2';
import PaymentDialog from './PaymentDialog';
import Receipt from './Receipt';
import axios from 'axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

const CondensedButton = styled(Button)(({ theme }) => ({
  fontSize: '12px',
  margin: '0px',
  padding: '5px 0px',
  width:'500px',
  textTransform: "none"
}));

const CondensedTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '18px',
    padding: '4px 8px', 
    height: '30px',    
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '1px',
      textAlign:'right',
    },
  },
  margin: 0,
  '& input': {
    textAlign: 'right',
  },
}));

const CondensedTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  display: 'flex',
  alignItems: 'center',
  height: '30px',
  marginLeft: '5px',
}));

const TotalsForm = ({totalSection,setTotalSection,cartValues,customerId,setCartValues,setSearchValue,setCustomerId,setSuspendedOrders,userDetails}) => {
  const receiptRef = useRef();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({ totals: 0, customerId: customerId });
  const [cashSaleNo, setCashSaleNo] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [alertInfo, setAlertInfo] = useState({ show: false, severity: '', message: '' });
  const [buttonState, setButtonState] = useState({ disabled: false, text: 'Suspend' });



  const handleOpenPayments = (totals, customerId) => {
    setPaymentDetails({ totals, customerId });
    setDialogOpen(true);
  };

  const handleClosePayments = () => {
    setDialogOpen(false);
  };

  const handleSuspended = async () => {
    try{
      setButtonState({ disabled: true, text: 'Suspending...' });
               if(cartValues.length <= 0){
                        setAlertInfo({
                          show: true,
                          severity: 'error',
                          message: 'No items in your Cart!'
                        });
                        handleOpen();
                      }else{

                            let grandtotal = totalSection['grandTotal'];
                            let totalpaid = 0;
                            let balance = grandtotal - totalpaid;

                            const response = await axios.post(`/ui/suspend-payment`, {
                              customer_id:customerId.id,
                              employee_id:userDetails?.id,
                              cart:cartValues,
                              grand_total:grandtotal,
                              sub_total:totalSection['subTotal'],
                              vat:totalSection['taxValue'],
                              total_products:totalSection['totalQuantity'],
                              total_paid:totalpaid,
                              balance:balance
                            });
                            if(response.data.bool){
                              setCartValues([]);
                              setSearchValue(null);
                              setCustomerId({name: 'Default Customer', id:1});
                              setAlertInfo({
                                show: true,
                                severity: 'success',
                                message: response.data.message
                              });
                              handleOpen();
                              try {
                                const response_sus = await axios.post(`/ui/suspended-orders`, {
                                  params: {}
                                });
                                setSuspendedOrders(response_sus.data);
                              } catch (error) {
                                console.error('Error fetching suspended orders:', error);
                              } finally {
                            }
                    }
              }
      }catch (error) {
        setAlertInfo({
          show: true,
          severity: 'error',
          message: `Error suspending the items :`+ error
        });
        handleOpen();
     }finally {
      setButtonState({ disabled: false, text: 'Suspend' });
     }
  };

  useEffect(() => {
    if (cashSaleNo !== null) {
      handlePrint(cashSaleNo);
    }
  }, [cashSaleNo]);

  const generateUniqueString = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 5; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return "Order-"+result;
  };
  
  const generateCashSale = async () => {
    if(cartValues.length <= 0){
        alert("No items in your Cart!");
     }else{
        setCashSaleNo(generateUniqueString);
     }
  }


  const handlePrint = async () => {
    await setTotalSection((prevState) => ({
      ...prevState,
      is_receipt:false
    }));
    const printContent = receiptRef.current.innerHTML; 
    const printWindowWidth = 400;
    const printWindowHeight = 700;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const left = (screenWidth / 2) - (printWindowWidth / 2);
    const top = (screenHeight / 2) - (printWindowHeight / 2);
    const printWindow = window.open('', '', `height=${printWindowHeight},width=${printWindowWidth},top=${top},left=${left}`);  

    printWindow.document.write('<html><head><title>Print Receipt</title>');
    printWindow.document.write('<style>');
    printWindow.document.write(`
      @media print {  }
        @page { size: 80mm auto; margin: 0; }
        body { font-size: 12px; padding: 10mm; text-transform: uppercase; }
        .centered-box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
          padding: 0;
          margin: 0;
        }
        .condensed-typography {
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 2px;       
        }
        .dotted-hr {
        width: 100%;
        border-top: 2px dotted black;
        border-bottom: none;
        border-left: none;
        border-right: none;
      }
       .two-column-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 10px;
        row-gap: 0px;
        font-size: 12px;
        margin: 0;
        padding: 0;
      }
      .four-column-grid {
        grid-template-columns: 2fr 1fr 1fr 1fr;
        row-gap: 0px;
        font-size: 12px;
        margin: 0;
        padding: 0;
        display: grid;
      }
      .bold-total-row {
        font-weight: bold;
        font-size: 18px;
      }
      .right-aligned-digits {
        text-align: right;
      }
     
    `);
    printWindow.document.write('</style>');
    printWindow.document.write('</head><body>');
    printWindow.document.write(printContent);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  };
  return (
    <Container maxWidth="sm">
      <Box sx={{ flexGrow: 1 }}> 
          <Grid container spacing={0} alignItems="center">
            <Grid xs={6} sx={{ padding: "1px" }}>
              <CondensedTypography>Item count</CondensedTypography>
            </Grid>
            <Grid xs={6} sx={{ padding: "1px" }}>
              <CondensedTextField 
                variant="outlined" 
                type="text"
                value = {totalSection['totalQuantity']}
                defaultValue='0.00' 
                fullWidth 
              />
            </Grid>

            <Grid xs={6} sx={{ padding: "1px" }}>
              <CondensedTypography style={{fontWeight:'900'}}>Sub total</CondensedTypography>
            </Grid>
            <Grid xs={6} sx={{ padding: "1px" }}>
              <CondensedTextField
                variant="outlined"
                value = {totalSection['subTotal']}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth 
              />
            </Grid>


            <Grid xs={6} sx={{ padding: "1px" }}>
              <CondensedTypography>Tax</CondensedTypography>
            </Grid>
            <Grid xs={6} sx={{ padding: "1px" }}>
              <CondensedTextField
                variant="outlined"
                value = {totalSection['taxValue']}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>



            <Grid xs={6} sx={{ padding: "1px" }}>
              <CondensedTypography style={{fontSize:'22px',fontWeight:'900'}}>Total</CondensedTypography>
            </Grid>
            <Grid xs={6} sx={{ padding: "1px" }}>
              <CondensedTextField
                variant="outlined"
                value = {totalSection['grandTotal']}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
          </Grid>

          <Divider style={{ marginTop:'5%' , marginBottom:'5%' }}/>

          <Grid xs={12} sx={{ padding: "1px" , marginTop:'5px' }}>
            <Stack direction="row" spacing={2} style={{justifyContent: 'center',}}>
                    <CondensedButton disabled={buttonState.disabled} variant="outlined" startIcon={<AccessAlarm />} onClick={handleSuspended}>
                        {buttonState.text}
                    </CondensedButton>
                    <CondensedButton variant="outlined" startIcon={<LocalPrintshop />} onClick={generateCashSale}>
                        Print
                    </CondensedButton>
            </Stack>
            <Stack direction="row" spacing={0} style={{justifyContent: 'center',marginTop:'2%'}}>
                    <CondensedButton variant="outlined" startIcon="(KSh)" onClick={() => handleOpenPayments(totalSection['grandTotal'], customerId)}>
                        Pay
                    </CondensedButton>
            </Stack>
          </Grid>
      </Box>

      <PaymentDialog
        open={dialogOpen}
        onClose={handleClosePayments}
        cartValues={cartValues}
        totalSection= {totalSection}
        setTotalSection = {setTotalSection}
        totals={paymentDetails.totals}
        customerId={paymentDetails.customerId}
        setCartValues={setCartValues}
        setSearchValue={setSearchValue}
        setCustomerId={setCustomerId}
        userDetails={userDetails}
      />
      <div style={{ display: 'none' }}>
          <Receipt ref={receiptRef} totalSection={totalSection} cartValues={cartValues} customerId={customerId} userDetails={userDetails} cashSaleNo={cashSaleNo}/>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
          {alertInfo.show && (
            <Alert variant="outlined" severity={alertInfo.severity} onClose={() => {
              setAlertInfo({ ...alertInfo, show: false });
              handleClose();
            }}>
              {alertInfo.message}
            </Alert>
          )}
        </Box>
      </Modal>

    </Container>
  );
};
export default TotalsForm;
