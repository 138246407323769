import React, { useEffect , useRef , useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import { Alert , Modal , IconButton, Container, Typography, Box, Paper, Button, Divider, Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import DeleteIcon from '@mui/icons-material/Delete';
import Receipt from './Receipt';
import KioskBoard from 'kioskboard';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.container,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CondensedButton = styled(Button)(({ theme }) => ({
    fontSize: '12px',
    margin: '0px',
    padding: '5px 0px',
    width: '500px',
}));

const CondensedTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        fontSize: '18px',
        padding: '4px 8px',
        height: '30px',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderWidth: '1px',
            textAlign: 'right',
        },
    },
    margin: 0,
    '& input': {
        textAlign: 'right',
    },
}));

const CondensedTypography = styled(Typography)(({ theme }) => ({
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
    height: '30px',
    marginLeft: '5px',
}));



const ProductCard = ({ product, setCart }) => {
  const [quantity, setQuantity] = useState(product.quantity);
  const inputRef = useRef(null);
  useEffect(() => {
      setQuantity(product.quantity);
  }, [product.quantity]);

  const handleQuantityChange = (event) => {
    const newQuantity = parseInt(event.target.value, 10);
    setQuantity(newQuantity);
    setCart(prevCart => ({
        ...prevCart,
        items: prevCart.items.map(item => 
            item.id === product.id ? { ...item, quantity: newQuantity } : item
        )
      }));
  };

//   const handleClickKeyboard = () => {
//     console.log(inputRef);
//     if (inputRef.current) {
//         inputRef.current.click();
//       }
//   };

  const handleDelete = () => {
    setCart(prevCart => ({
        ...prevCart,
        items: prevCart.items.filter(item => item.id !== product.id)
      }));
  };

  const handleClickKeyBoard =  () => { 
    if (inputRef.current) {
        // Change the value of the input element
        inputRef.current.value = ""; // or any desired value      
        // setQuantity(null);
        // setCart(prevCart => ({
        //         ...prevCart,
        //         items: prevCart.items.map(item =>
        //             item.id === product.id ? { ...item, quantity: 0 } : item
        //         )
        //     }));

        // Display the current value in an alert
        //alert(inputRef.current.value);
    }
    //const newQuantity = parseInt(inputRef.current.value, 1);
    //setQuantity(newQuantity); 
    // await setQuantity(0);
    // await setCart(prevCart => ({
    //     ...prevCart,
    //     items: prevCart.items.map(item =>
    //         item.id === product.id ? { ...item, quantity: 0 } : item
    //     )
    // }));
  };

  const handleQuantityValue = () => {
    if (inputRef.current) {
        const newQuantity = parseInt(inputRef.current.value, 10);     
        setQuantity(newQuantity);
        setCart(prevCart => ({
            ...prevCart,
            items: prevCart.items.map(item =>
                item.id === product.id ? { ...item, quantity: newQuantity } : item
            )
        }));
        inputRef.current.value = "";
    }
  };

  function handleKeyClick(event) {
    console.log(event);
    //alert("clicked");
  }

//   let numericArray = [];
//   function handleClick(event) {
//     const element = event.target;
//     const dataIndex = element.getAttribute('data-index');
//     const dataValue = element.getAttribute('data-value');   
//     // console.log('Element clicked:', element);
//     // console.log('Data Index:', dataIndex);

//     console.log('Data Index:', dataValue);

//     //numericArray.push(dataValue);
//     //const combinedNumber = numericArray.join('');

//     //console.log(dataValue);

//      //setQuantity(combinedNumber);
  
//     // You can add more logic here based on your needs
//   }

//   document.querySelectorAll('.kioskboard-key').forEach(element => {
//     element.addEventListener('click', handleClick);
//   });



  useEffect(() => {
    KioskBoard.init({
    keysArrayOfObjects: null,
    keysJsonUrl: "./keyboard-keys.json",
    keysSpecialCharsArrayOfStrings: null,
    keysNumpadArrayOfNumbers: null,
    language: 'en',
    theme: 'dark',
    autoScroll: false,
    capsLockActive: false,
    allowRealKeyboard: true,
    allowMobileKeyboard: false,
    cssAnimations: false,
    cssAnimationsDuration: 360,
    cssAnimationsStyle: 'slide',
    keysAllowSpacebar: true,
    keysSpacebarText: 'Space',
    keysFontFamily: 'sans-serif',
    keysFontSize: '22px',
    keysFontWeight: 'normal',
    keysIconSize: '25px',
    keysEnterText: 'Enter',
    keysEnterCallback: handleQuantityValue,
    keysEnterCanClose: true,
    keysCallback : handleKeyClick,
    });
    KioskBoard.run(inputRef.current);
}, []);
  return (
      <Box
          sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '10px',
              borderRadius: '12px',
              boxShadow: 2,
              backgroundColor: '#2903034a',
              width: '100%',
              marginTop: '1%',
              marginBottom: '2%',
          }}
      >
          <Box
              component="img"
              src={product.product_image || "https://via.placeholder.com/50"}
              alt={product.product_name}
              sx={{
                  width: 50,
                  height: 50,
                  borderRadius: '4px',
                  objectFit: 'cover',
              }}
          />

          <Box sx={{ textAlign: 'center', flexGrow: 1, mx: 2 }}>
              <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                  {product.product_name}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: '12px', color: 'black', fontWeight: 'bold' }}>
                  {product.selling_price.toFixed(2)}/=
              </Typography>
          </Box>

          <Box sx={{ textAlign: 'right' }}>
              <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'bold' }} >
                  QUANTITY
              </Typography> 
              {/* onInput={handleQuantityChange} value={quantity} onChange={handleQuantityChange}  */}
              <input type={'text'} ref={inputRef}   onClick={handleClickKeyBoard}   style={{border:'0px', zIndex:'1000',paddingLeft:'3%',position:'absolute',backgroundColor:'transparent',width:'60px',height:'55px',textAlign:'center',color:'transparent'}} className="js-kioskboard-input" data-kioskboard-type="numpad" data-kioskboard-placement="bottom"  id="quantityField" />
              <TextField
                  type="number"
                  value={quantity}
                  onChange={handleQuantityChange}
                //   onClick={handleClickKeyboard}
                  sx={{ width: '100px', fontSize: '12px' }}
                  inputProps={{ min: 0 }}
              />
          </Box>

          <IconButton
              onClick={handleDelete}
              sx={{
                  position: 'absolute',
                  top: '-20px',
                  right: '-20px',
                  color: 'red',
                  backgroundColor: 'white',
                  '&:hover': {
                      backgroundColor: '#f0f0f0',
                  },
              }}
          >
              <DeleteIcon />
          </IconButton>
      </Box>
  );
};


const Totals = ({ user, cart = [], setCart }) => {
    const itemCount = cart?.items.reduce((sum, item) => sum + item.quantity, 0);
    // const subtotal = cart?.items.reduce((sum, item) => sum + item.quantity * item.selling_price, 0);
    // const tax = 
    // const grandTotal =
    // const tax_value = subtotal * tax;
    // const grandTotal = subtotal + tax;

    const subtotal = cart?.items.reduce((sum, item) => sum + item.quantity * parseFloat(item.selling_price), 0);
    const tax = cart?.items.reduce((sum, item) => sum + item.quantity * (parseFloat(item.selling_price) * parseFloat(item.tax)), 0);
    const grandTotal = subtotal + tax; 



    const receiptRef = useRef();
    const [totalSection, setTotalSection] = useState([]);
    const [cashSaleNo, setCashSaleNo] = React.useState(null);
    const [cartValues, setCartValues] = useState(() => ({
        grandTotal:0,
        tax:0,
        subtotal:0,
        count: 0,
        cart: []
    }));


    useEffect(() => {
        setCartValues({
            type:'proforma',
            grandTotal: grandTotal,
            tax: tax,
            subtotal: subtotal,
            count: itemCount,
            cart: cart
        });
    }, [cart, grandTotal, tax, subtotal, itemCount]);

    const [isSending, setIsSending] = useState(false);
    const [isCancelling, setIsCancelling] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [alertInfo, setAlertInfo] = useState({ show: false, severity: '', message: '' });

    const generateOrderId = () => {
      const hexPart = Math.floor(Math.random() * 0x100000)
          .toString(16) 
          .padStart(5, '0');
      return `OD-${hexPart.toUpperCase()}`;
    };

    const handlePrint = (invoice_id) => {
        const printContent = receiptRef.current.innerHTML; 
        const printWindowWidth = 400;
        const printWindowHeight = 700;
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const left = (screenWidth / 2) - (printWindowWidth / 2);
        const top = (screenHeight / 2) - (printWindowHeight / 2);
        const printWindow = window.open('', '', `height=${printWindowHeight},width=${printWindowWidth},top=${top},left=${left}`);  
    
        printWindow.document.write('<html><head><title>Print Receipt</title>');
        printWindow.document.write('<style>');
        printWindow.document.write(`
          @media print {  }
            @page { size: 80mm auto; margin: 0; }
            body { font-size: 12px; padding: 10mm; text-transform: uppercase; }
            .centered-box {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              height: 100%;
              padding: 0;
              margin: 0;
            }
            .condensed-typography {
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 2px;       
            }
            .dotted-hr {
            width: 100%;
            border-top: 2px dotted black;
            border-bottom: none;
            border-left: none;
            border-right: none;
          }
           .two-column-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 10px;
            row-gap: 0px;
            font-size: 12px;
            margin: 0;
            padding: 0;
          }
          .four-column-grid {
            grid-template-columns: 2fr 1fr 1fr 1fr;
            row-gap: 0px;
            font-size: 12px;
            margin: 0;
            padding: 0;
            display: grid;
          }
          .bold-total-row {
            font-weight: bold;
            font-size: 18px;
          }
          .right-aligned-digits {
            text-align: right;
          }
        `);
        printWindow.document.write('</style>');
        printWindow.document.write('<script src="https://cdnjs.cloudflare.com/ajax/libs/qrcodejs/1.0.0/qrcode.min.js"></script></head><body>'); 
        printWindow.document.write(printContent);
        printWindow.document.write('<script>var qrcode = new QRCode("qrcode", {text:"'+ invoice_id +'",width: 100,height: 100,colorDark: "#000000",colorLight: "#ffffff",correctLevel: QRCode.CorrectLevel.H});</script>');
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    }

    useEffect(() => {
        if (cashSaleNo !== null) {
            handlePrint(cashSaleNo);
        }
      }, [cashSaleNo]);


    const  handlePrintReceipt = async () => {
        if (typeof cart.items.length === 'undefined'  || cart.items.length <= 0) {
            setAlertInfo({
              show: true,
              severity: 'error',
              message: 'Please select items to Order!'
            });
            handleOpen();
       }else{
        handlePrint(cart.order_id);
       }
    }

    const handleCancel = async () => {
        if (typeof cart.items.length === 'undefined'  || cart.items.length <= 0) {
            setAlertInfo({
              show: true,
              severity: 'error',
              message: 'Please select items to Order!'
            });
            handleOpen();
       }else{
         setIsCancelling(true)
        try {
            const response = await axios.post(`/ui/restaurant-order-cancel`, {
                user:user,
                cart:cart,
                customer_id:1
            });
            if(response.data.bool){
                setAlertInfo({
                    show: false,
                    severity: 'info',
                    message: response.data.message
                });
              }
            } finally {
                setCart({
                    order_id: generateOrderId(),
                    tableids: 0,
                    table: "Table 0",
                    guests: 0,
                    items: []
                });
                setIsCancelling(false);
         }
       }
    };

    const handleOrder = async () => {

      if (typeof cart.items.length === 'undefined'  || cart.items.length <= 0) {
            setAlertInfo({
              show: true,
              severity: 'error',
              message: 'Please select items to Order!'
            });
            handleOpen();
       }else{
        setIsSending(true)
        try {

             await setTotalSection((prevState) => ({
                ...prevState,
                totalpaid: 0,
                balance: 0,
                is_receipt:true
              }));              

            const response = await axios.post(`/ui/restaurant-order`, {
                    user:user,
                    grandTotal : grandTotal,
                    tax : tax,
                    sub_total :subtotal,
                    count : itemCount,
                    cart:cart,
                    customer_id:1
                });
                if(response.data.bool){
                await setCashSaleNo(cart.order_id);
                setAlertInfo({
                    show: false,
                    severity: 'info',
                    message: response.data.message
                });
                setCart({
                    order_id: generateOrderId(),
                    tableids: 0,
                    table: "Table 0",
                    guests: 0,
                    items: []
                });
                }

            } finally {
                setIsSending(false);
            }
       }

    };

    return (
        <Container maxWidth={true} sx={{ width: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={0} alignItems="center">
                    <Grid xs={6} sx={{ padding: "1px" }}>
                        <CondensedTypography style={{ fontSize: '30px', fontWeight: '900' }}>Order #</CondensedTypography>
                    </Grid>
                    <Grid xs={6} sx={{ padding: "1px" }}>
                        <CondensedTypography style={{ fontSize: '22px', fontWeight: '900', justifyContent: 'right' }}>{cart?.order_id}</CondensedTypography>
                    </Grid>

                    <Grid xs={6} sx={{ padding: "1px" }}>
                        <CondensedTypography style={{ fontSize: '18px', fontWeight: '900' }}>GUESTS : {cart?.guests}</CondensedTypography>
                    </Grid>
                    <Grid xs={6} sx={{ padding: "1px" }}>
                        <CondensedTypography style={{ fontSize: '18px', fontWeight: '900', justifyContent: 'right' }}>{cart?.table}</CondensedTypography>
                    </Grid>

                    <Grid xs={12} sx={{ padding: "1px" }}>
                        <Divider />
                    </Grid>
                 
                   <div style={{ maxHeight:'50vh',overflowY: 'auto' ,width:'100%',overflowX: 'hidden',paddingRight:'4%',paddingTop:'4%'}} >
                   
                    {cart?.items.map((product, index) => (
                        <Grid xs={12} sx={{ padding: "1px"}} key={index} >
                            <ProductCard product={product} setCart={setCart} />
                        </Grid>
                    ))}
                    </div>
                    {cart?.items.length > 0 && (
                        <Grid xs={12} sx={{ padding: "1px", marginBottom: '4%' , marginTop: '2%' }}>
                            <Divider />
                        </Grid>
                    )}
           

                    <Grid xs={6} sx={{ padding: "1px" }}>
                        <CondensedTypography>Item count</CondensedTypography>
                    </Grid>
                    <Grid xs={6} sx={{ padding: "1px" }}>
                        <CondensedTextField
                            variant="outlined"
                            type="text"
                            value={itemCount}
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>

                    <Grid xs={6} sx={{ padding: "1px" }}>
                        <CondensedTypography style={{ fontWeight: '900' }}>Sub total</CondensedTypography>
                    </Grid>
                    <Grid xs={6} sx={{ padding: "1px" }}>
                        <CondensedTextField
                            variant="outlined"
                            value={subtotal.toFixed(2)}
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>

                    <Grid xs={6} sx={{ padding: "1px" }}>
                        <CondensedTypography>Tax</CondensedTypography>
                    </Grid>
                    <Grid xs={6} sx={{ padding: "1px" }}>
                        <CondensedTextField
                            variant="outlined"
                            value={tax.toFixed(2)}
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>

                    <Grid xs={6} sx={{ padding: "1px" }}>
                        <CondensedTypography style={{ fontSize: '22px', fontWeight: '900' }}>Total</CondensedTypography>
                    </Grid>
                    <Grid xs={6} sx={{ padding: "1px" }}>
                        <CondensedTextField
                            variant="outlined"
                            value={grandTotal.toFixed(2)}
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <Divider style={{ marginTop: '5%', marginBottom: '5%' }} />

                <Grid xs={12} sx={{ padding: "1px", marginTop: '5px' }}>
                    <Stack direction="row" spacing={2} style={{ justifyContent: 'center', }}>
                        <CondensedButton variant="outlined" onClick={handleCancel} >
                            {isCancelling ? "Cancelling Order..." : "Cancel Order"}
                        </CondensedButton>
                        <CondensedButton variant="outlined" onClick={handleOrder}  disabled={isSending} >
                            {isSending ? "Sending Order..." : "Send Order"}
                        </CondensedButton>
                    </Stack>
                    <Stack direction="row" spacing={0} style={{ justifyContent: 'center', marginTop: '2%' }}>
                        <CondensedButton variant="outlined" onClick={handlePrintReceipt}>
                            Print
                        </CondensedButton>
                    </Stack>
                </Grid>
            </Box>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              >
              <Box sx={style}>
                {alertInfo.show && (
                  <Alert variant="outlined" severity={alertInfo.severity} onClose={() => {
                    setAlertInfo({ ...alertInfo, show: false });
                    handleClose();
                  }}>
                    {alertInfo.message}
                  </Alert>
                )}
              </Box>
            </Modal>
            <div style={{ display: 'none' }}>
                <Receipt user={user} ref={receiptRef} totalSection={totalSection} cartValues={cartValues} cashSaleNo={cashSaleNo}/>
            </div>
        </Container>
    );
};

export default Totals;
