
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import '@mui/material/styles/styled';
import axios from 'axios';
import {Autocomplete, Divider, Typography, Alert, MenuItem, Select, InputLabel, FormControl, Stack, Button, TextField, Box, Paper, createFilterOptions } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { useTreeViewApiRef } from '@mui/x-tree-view/hooks';
import * as yup from 'yup';
import { Field, ErrorMessage, Formik, Form } from 'formik';

const filter = createFilterOptions();


const kenyanbanks = [
    { label: 'ABSA Bank Kenya', bank_id: 1 },
    { label: 'Access Bank Kenya', bank_id: 2 },
    { label: 'African Banking Corporation Limited', bank_id: 3 },
    { label: 'Bank of Africa Kenya Limited', bank_id: 4 },
    { label: 'Bank of Baroda (K) Limited', bank_id: 5 },
    { label: 'Bank of India', bank_id: 6 },
    { label: 'Citibank N.A Kenya', bank_id: 7 },
    { label: 'Consolidated Bank of Kenya Limited', bank_id: 8 },
    { label: 'Co-operative Bank of Kenya Limited', bank_id: 9 },
    { label: 'Credit Bank Limited', bank_id: 10 },
    { label: 'Development Bank of Kenya Limited', bank_id: 11 },
    { label: 'Diamond Trust Bank Kenya Limited', bank_id: 12 },
    { label: 'DIB Bank Kenya Limited', bank_id: 13 },
    { label: 'Ecobank Kenya Limited', bank_id: 14 },
    { label: 'Equity Bank Kenya Limited', bank_id: 15 },
    { label: 'Family Bank Limited', bank_id: 16 },
    { label: 'First Community Bank Limited', bank_id: 17 },
    { label: 'Guaranty Trust Bank (K) Ltd', bank_id: 18 },
    { label: 'Guardian Bank Limited', bank_id: 19 },
    { label: 'Gulf African Bank Limited', bank_id: 20 },
    { label: 'Habib Bank A.G Zurich', bank_id: 21 },
    { label: 'I&M Bank Limited', bank_id: 22 },
    { label: 'Kingdom Bank Limited', bank_id: 23 },
    { label: 'KCB Bank Kenya Limited', bank_id: 24 },
    { label: 'Mayfair CIB Bank Limited', bank_id: 25 },
    { label: 'Middle East Bank (K) Limited', bank_id: 26 },
    { label: 'M-Oriental Bank Limited', bank_id: 27 },
    { label: 'National Bank of Kenya Limited', bank_id: 28 },
    { label: 'NCBA Bank Kenya PLC', bank_id: 29 },
    { label: 'Paramount Bank Limited', bank_id: 30 },
    { label: 'Prime Bank Limited', bank_id: 31 },
    { label: 'SBM Bank Kenya Limited', bank_id: 32 },
    { label: 'Sidian Bank Limited', bank_id: 33 },
    { label: 'Spire Bank Ltd', bank_id: 34 },
    { label: 'Stanbic Bank Kenya Limited', bank_id: 35 },
    { label: 'Standard Chartered Bank Kenya Limited', bank_id: 36 },
    { label: 'UBA Kenya Bank Limited', bank_id: 37 },
    { label: 'Victoria Commercial Bank Limited', bank_id: 38 },
    { label: 'Caritas Microfinance Bank', bank_id: 39 },
    { label: 'Choice Microfinance Bank', bank_id: 40 },
    { label: 'Faulu Micro-Finance Bank', bank_id: 41 },
    { label: 'Kenya Women Microfinance Bank', bank_id: 42 },
    { label: 'Maisha Microfinance Bank Limited', bank_id: 43 },
    { label: 'Rafiki Microfinance Bank', bank_id: 44 },
    { label: 'Salaam Microfinance Bank', bank_id: 45 }
];


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const CondensedTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  display: 'flex',
  alignItems: 'center',
  height: '30px',
  marginLeft: '5px',
}));
const CondensedTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '14px',
    padding: '4px 0px',
    height: '25px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '1px',
      textAlign: 'right',
    },
  },
  margin: 0,
  '& input': {
    textAlign: 'left',
  },
}));
const CondensedButton = styled(Button)(({ theme }) => ({
  fontSize: '12px',
  margin: '0px',
  padding: '5px 0px',
  width: '100px',
}));

const CondensedAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '0.875rem',
    padding: '4px 8px',
    height: '30px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '1px',
    },
  },
  margin: 0,
  '& input': {
    textAlign: 'left',
  },
}));

const validationSchema = yup.object().shape({
    name: yup.string().required('Customer name is required'),
    email: yup.string().required('Email is required'),
    phone: yup.string().required('Phone is required'),
    address: yup.string().required('Customer address/location is required'),
});

export default function AddCustomer({ name, handleCloseItem }) {
  const [selectedItems, setSelectedItems] = React.useState([]);
  const toggledItemRef = React.useRef({});
  const apiRef = useTreeViewApiRef();
  const [alertInfo, setAlertInfo] = useState({ show: false, severity: '', message: '' });
  const [formData, setFormData] = useState({
    email:'',
    name: name,
    phone: '',
    address: '',
    shopname: '',
    photo:'',
    account_holder:'',
    account_number:'',
    bank_name:'',
    bank_branch:'',
    city:'',
  });


  return (
    <Formik
      initialValues={formData}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
           try {
                    setAlertInfo({ show: true, severity: 'info',  message:'Submitting form please wait...'});
                    const response = await axios.post(`/ui/customer-save`,values);
                    if(response.data.bool){
                        setAlertInfo({ show: true, severity: 'success',  message:response.data.message });
                        resetForm();
                        handleCloseItem();               
                  }else{
                      setAlertInfo({ show: true, severity: 'error',  message:response.data.message });
                  }
                } catch (error) {
                  setAlertInfo({ show: true, severity: 'error', message:  "Database error : "+error.message });
                }finally {
              }
      }}
    >
      {({ setFieldValue }) => (
        <Form>
          {alertInfo.show && (
            <Alert variant="outlined" severity={alertInfo.severity} onClose={() => setAlertInfo({ ...alertInfo, show: false })}>
              {alertInfo.message}
            </Alert>
          )}
          <Stack direction="row" spacing={0} style={{ justifyContent: 'right' }}>
            <Item>
              <Box sx={{ flexGrow: 0 }}>
                <Divider style={{ marginTop: '1%', marginBottom: '1%' }} />
                <Grid container spacing={0} alignItems="center">
                  <Grid xs={3} sx={{ padding: "1px" }}>
                    <CondensedTypography>Full Name *</CondensedTypography>
                  </Grid>
                  <Grid xs={9} sx={{ padding: "1px" }}>
                    <Field name="name">
                      {({ field }) => (
                        <CondensedTextField
                          variant="outlined"
                          {...field}
                          fullWidth
                        />
                      )}
                    </Field>
                    <ErrorMessage name="name" component={Alert} severity="error" />
                  </Grid>


                  <Grid xs={3} sx={{ padding: "1px" }}>
                    <CondensedTypography>Email *</CondensedTypography>
                  </Grid>
                  <Grid xs={9} sx={{ padding: "1px" }}>
                    <Field name="email">
                      {({ field }) => (
                        <CondensedTextField
                          variant="outlined"
                          {...field}
                          fullWidth
                        />
                      )}
                    </Field>
                    <ErrorMessage name="email" component={Alert} severity="error" />
                  </Grid>

                  <Grid xs={3} sx={{ padding: "1px" }}>
                    <CondensedTypography>Phone *</CondensedTypography>
                  </Grid>
                  <Grid xs={9} sx={{ padding: "1px" }}>
                    <Field name="phone">
                      {({ field }) => (
                        <CondensedTextField
                          variant="outlined"
                          {...field}
                          fullWidth
                        />
                      )}
                    </Field>
                    <ErrorMessage name="phone" component={Alert} severity="error" />
                  </Grid>


                  <Grid xs={3} sx={{ padding: "1px" }}>
                    <CondensedTypography>Address *</CondensedTypography>
                  </Grid>
                  <Grid xs={9} sx={{ padding: "1px" }}>
                    <Field name="address">
                      {({ field }) => (
                        <CondensedTextField
                          variant="outlined"
                          {...field}
                          fullWidth
                        />
                      )}
                    </Field>
                    <ErrorMessage name="address" component={Alert} severity="error" />
                  </Grid>


  

                  <Grid xs={3} sx={{ padding: "1px" }}>
                    <CondensedTypography>Business</CondensedTypography>
                  </Grid>
                  <Grid xs={9} sx={{ padding: "1px" }}>
                     <Field name="shopname">
                      {({ field }) => (
                        <CondensedTextField
                          variant="outlined"
                          {...field}
                          fullWidth
                        />
                      )}
                    </Field>
                    <ErrorMessage name="shopname" component={Alert} severity="error" />
                  </Grid>


                  <Grid xs={3} sx={{ padding: "1px" }}>
                    <CondensedTypography>Account Holder</CondensedTypography>
                  </Grid>
                  <Grid xs={9} sx={{ padding: "1px" }}> 
                    <Field name="account_holder">
                      {({ field }) => (
                        <CondensedTextField
                          variant="outlined"
                          {...field}
                          fullWidth
                        />
                      )}
                    </Field>
                    <ErrorMessage name="account_holder" component={Alert} severity="error" />
                  </Grid>

                  <Grid xs={3} sx={{ padding: "1px" }}>
                    <CondensedTypography>Account Number</CondensedTypography>
                  </Grid>
                  <Grid xs={9} sx={{ padding: "1px" }}>
                    <Field name="account_number">
                      {({ field }) => (
                        <CondensedTextField
                          variant="outlined"
                          {...field}
                          fullWidth
                        />
                      )}
                    </Field>
                    <ErrorMessage name="account_number" component={Alert} severity="error" />
                  </Grid>


                  <Grid xs={3} sx={{ padding: "1px" }}>
                    <CondensedTypography>Bank Name</CondensedTypography>
                  </Grid>
                    <Grid item xs={9} sx={{ padding: "1px" }}>
                        <Field name="bank_name">
                            {({ field, form }) => (
                                <CondensedAutocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={kenyanbanks}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, value) => form.setFieldValue("bank_name", value?.label || "")}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            fullWidth
                                            label=""
                                            {...field}
                                        />
                                    )}
                                />
                            )}
                        </Field>
                        <ErrorMessage name="bank_name" component={Alert} severity="error" />
                    </Grid>

                  <Grid xs={3} sx={{ padding: "1px" }}>
                    <CondensedTypography>Bank Branch</CondensedTypography>
                  </Grid>
                  <Grid xs={9} sx={{ padding: "1px" }}>
                    <Field name="bank_branch">
                      {({ field }) => (
                        <CondensedTextField
                          variant="outlined"
                          {...field}
                          fullWidth
                        />
                      )}
                    </Field>
                    <ErrorMessage name="bank_branch" component={Alert} severity="error" />
                  </Grid>

                  <Grid xs={3} sx={{ padding: "1px" }}>
                    <CondensedTypography>City / Town</CondensedTypography>
                  </Grid>
                  <Grid xs={9} sx={{ padding: "1px" }}>
                    <Field name="city">
                      {({ field }) => (
                        <CondensedTextField
                          variant="outlined"
                          {...field}
                          fullWidth
                        />
                      )}
                    </Field>
                    <ErrorMessage name="city" component={Alert} severity="error" />
                  </Grid>

                  <Grid xs={12} sx={{ padding: "1px", marginTop: '5px' }}>
                    <Divider style={{ marginTop: '1%', marginBottom: '1%' }} />
                    <Stack direction="row" spacing={2} style={{ justifyContent: 'right', }}>
                      <CondensedButton variant="outlined" type="submit">
                        Save
                      </CondensedButton>
                      <CondensedButton variant="outlined" onClick={handleCloseItem}>
                        Close
                      </CondensedButton>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Item>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}

