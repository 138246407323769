import React, { useState, useEffect } from 'react';
import { Dialog,DialogTitle,DialogContent , createFilterOptions , DialogContentText ,DialogActions, Autocomplete , TextField, Typography, Box, Paper, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Add from '@mui/icons-material/Add';
import Grid from '@mui/material/Unstable_Grid2';
import AddProduct from './AddProduct';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SearchInvoice from './SearchInvoice';

const filter = createFilterOptions();

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

const CondensedTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '0.875rem',
    padding: '4px 8px', 
    height: '30px',    
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '1px',
      textAlign:'right',
    },
  },
  margin: 0,
  '& input': {
    textAlign: 'right',
  },
}));

const CondensedTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: '900',
  display: 'flex',
  alignItems: 'center',
  height: '30px',
  marginLeft: '5px',
}));

const CondensedButton = styled(Button)(({ theme }) => ({
    fontSize: '12px',
    padding: '5px 10px',
    float: 'right',
    textTransform: "none"
  }));

const CondensedAutocomplete = styled(Autocomplete)(({ theme }) => ({
    '& .MuiInputBase-root': {
      fontSize: '0.875rem',
      padding: '4px 8px',
      height: '30px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderWidth: '1px',
      },
    },
    margin: 0,
    '& input': {
      textAlign: 'center',
    },
  }));

const ScanInputs = ({ dloading , dproducts, fetchProductsData ,invoiceId, setInvoiceId , setSearchValue , cartValues ,  setCartValues={setCartValues} }) => {

  const [value, setValue] = React.useState(null);
  const [open, toggleOpen] = React.useState(false);
  const [products, setProducts] = useState([]);


  const [openItem, setOpenItem] = React.useState(false);
  const handleCloseItem = () => {
    setOpenItem(false);
  };
  const handleClickOpen = () => setOpenItem(true);

  const [openSearchItem, setSearchOpenItem] = React.useState(false);
  const handleSearchCloseItem = () => {
    setSearchOpenItem(false);
  };
  const handleSearchClickOpen = () => setSearchOpenItem(true);


  useEffect(() => {
    // fetch('/ui/products')
    //   .then(response => response.json())
    //   .then(data => setProducts(data))
    //   .catch(error => console.error('Error fetching products:', error));
    setProducts(dproducts);

  }, [dproducts]);


  const handleClose = () => {
    setDialogValue({
      title: '',
      year: '',
    });
    toggleOpen(false);
  };

  const handleSearchInvoice  = () => {
    handleSearchClickOpen();
  }

  const [dialogValue, setDialogValue] = React.useState({
    title: '',
    year: '',
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setValue({
      title: dialogValue.title,
      year: parseInt(dialogValue.year, 10),
    });
    handleClose();
  };

  return (
      <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0} alignItems="left">
            <Grid xs={4} sx={{ padding: "1px" }}>
              <CondensedTypography>Find or Scan Item or Receipt :</CondensedTypography>
            </Grid>

            <Grid xs={4} sx={{ padding: "1px" }}>

                    <React.Fragment>
                    <CondensedAutocomplete
                        value={value}
                        onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            setTimeout(() => {
                            toggleOpen(true);
                            setDialogValue({
                                product_name: newValue,
                                year: '',
                            });
                            });
                        } else if (newValue && newValue.inputValue) {
                            toggleOpen(true);
                            setDialogValue({
                                product_name: newValue.inputValue,
                            year: '',
                            });
                        } else {

                          const existingItem = cartValues.find(item => item.id === newValue.id);

                          if (existingItem) {
                            const updatedCartValues = cartValues.map(item => {
                              if (item.id === newValue.id) {
                                const updatedQuantity = item.quantity + 1;
                                return {
                                  ...item,
                                  quantity: updatedQuantity,
                                  sub_total: updatedQuantity * item.selling_price,
                                  taxValue: parseFloat(item.tax) * parseFloat(updatedQuantity * item.selling_price),
                                };
                              }
                              return item;
                            });
                            
                            setCartValues(updatedCartValues);
                          } else {

                            newValue.quantity = 1;
                            newValue.sub_total = newValue.quantity * newValue.selling_price;
                            newValue.taxValue = parseFloat(newValue.tax) * parseFloat(newValue.selling_price);
                            const newValues = [...cartValues, newValue];
                            
                            setCartValues(newValues);
                          }
        
                        }
                        setValue({product_name:''});
                     
                        }}
                        filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        if (params.inputValue !== '') {
                            filtered.push({
                            inputValue: params.inputValue,
                            product_name: `Add "${params.inputValue}"`,
                            });
                        }
                        return filtered;
                        }}
                        id="free-solo-dialog-demo"
                        options={products}
                        getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                            return option;
                        }
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        return option.product_name;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        renderOption={(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                            <li key={option.id} {...optionProps}>
                            {option.product_name}
                            </li>
                        );
                        }}
                        
                        freeSolo
                        renderInput={(params) => <CondensedTextField  {...params} label="" />}
                    />
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Add a product</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                            Did you miss any product in our list? Please, add it!
                            </DialogContentText>
                                <AddProduct fetchProductsData={fetchProductsData} productName={dialogValue.product_name} handleCloseItem={handleClose} />
                           </DialogContent>
                    </Dialog>
               </React.Fragment>


            </Grid>

            <Grid xs={2} sx={{ padding: "1px" }}>
                   <CondensedButton onClick={handleClickOpen} variant="outlined" startIcon={<Add />} >
                        Add Product
                   </CondensedButton>
            </Grid>
            <Grid xs={2} sx={{ padding: "1px" }}>  
                   <CondensedButton onClick={handleSearchInvoice} variant="outlined" startIcon={<ManageSearchIcon />} >
                        Search #No.
                   </CondensedButton>
            </Grid>
            

          </Grid>


          <Dialog open={openItem} onClose={handleCloseItem}>
                  <DialogTitle>New product</DialogTitle>
                  <DialogContent>
                          <AddProduct handleCloseItem={handleCloseItem}/>
                  </DialogContent>
           </Dialog>

           <Dialog open={openSearchItem} onClose={handleSearchCloseItem}>
                  <DialogTitle>Search</DialogTitle>
                  <DialogContent>
                          <SearchInvoice invoiceId={invoiceId} setInvoiceId={setInvoiceId}  handleSearchCloseItem={handleSearchCloseItem}  setSearchValue={setSearchValue}  cartValues={cartValues}  setCartValues={setCartValues}  />
                  </DialogContent>
           </Dialog>

      </Box>
  );
};

export default ScanInputs;

