
import React from 'react';
import { styled } from '@mui/material/styles';
import { FormControl, Grid, Paper, TextField, FormHelperText } from '@mui/material';
import { Field, ErrorMessage } from 'formik';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

export const LicenceDetails = ({ errors, touched }) => (
  <Grid>
    <Item>
      <Grid item xs={12}>
        <FormControl variant="standard" style={{ width: '100%' }} error={Boolean(errors.licence && touched.licence)}>
          <Field
            name="licence"
            as={TextField}
            label="Please enter your POS Licence issued along with the software"
            multiline
            rows={4}
            variant="outlined"
          />
          <ErrorMessage name="licence" component={FormHelperText} />
        </FormControl>
      </Grid>
    </Item>
  </Grid>
);
