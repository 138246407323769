import React, { useRef , useState , useEffect , useCallback   } from 'react';
import { Alert, Button, Stack, Container, 
  TextField, Typography, Box, Paper, Grid 
  ,FormControl,InputAdornment,Divider,
  FormHelperText,Autocomplete,CircularProgress,
  Modal,IconButton 
} from '@mui/material';

import { styled } from '@mui/material/styles';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ReceiptLong from '@mui/icons-material/ReceiptLong';
import HighlightOff from '@mui/icons-material/HighlightOff';
import _ from 'lodash';

import { Formik, Field, Form, ErrorMessage, useField } from "formik";
import * as Yup from "yup";
import axios from 'axios';

import ReportMain from './back-end/reports/Reports';
import CloseIcon from '@mui/icons-material/Close';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  textAlign: 'left',
  color: theme.palette.text.secondary,
  padding: theme.spacing(2),
}));

const CondensedButton = styled(Button)(({ theme }) => ({
  fontSize: '12px',
  margin: '0px',
  padding: '5px 0px',
  width: '100%',
  textTransform: 'none',
}));

const CondensedTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '18px',
    padding: '4px 8px',
    height: '30px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '1px',
    },
  },
  margin: 0,
  '& input': {
    textAlign: 'right',
  },
}));

const CondensedTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  display: 'flex',
  alignItems: 'center',
  height: '30px',
  marginLeft: '5px',
}));

function createDbData(
      id, 
      product_code, 
      product_name, 
      product_garage, 
      product_image, 
      selling_price,
      quantity,
      sub_total,
      taxValue,
      tax
    ) {
      return { 
        id, 
        product_code, 
        product_name, 
        product_garage, 
        product_image, 
        selling_price,
        quantity,
        sub_total,
        taxValue,
        tax
      };
  }

const Return = ({ invoiceId, setInvoiceId , customerId, totalSection , setTotalSection , userDetails , setCartValues , setCustomerId , setSearchValue ,cartValues = [] }) => {
  

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [inputValue, setInputValue] = useState('');


  const fetchOptions = async (query) => {
    if (query) {
      try {
        setLoading(true);
        const response = await axios.get(`/transaction/invoice/search/${query}`);
        setOptions(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setOptions([]);
    }
  };

  const debouncedFetchOptions = useCallback(_.debounce(fetchOptions,1), []);
  useEffect(() => {
    debouncedFetchOptions(inputValue);
  }, [inputValue, debouncedFetchOptions]); 


  const [customerData, setCustomerData] = useState({
    fnotes:""
  });

  const grandTotal = parseFloat(totalSection['grandTotal']);
  const taxValue = parseFloat(totalSection['taxValue']);
  const subTotal = parseFloat(totalSection['subTotal']);

  const [balance, setBalance] = useState(0);

  useEffect(() => {
    setBalance(parseFloat(totalSection['grandTotal']));
  }, [totalSection]);

  const validationSchema = Yup.object().shape({
    fnotes : Yup.string().required("Please add a reason for return!"),
  });


  const handleFinish = async (validateForm, setTouched, values) => {
    try {
      values.invoice = rows;
      const response = await axios.post(`/ui/return/create`, {
        values,
        employee_id:userDetails?.id,
        cart:cartValues,
        grand_total:grandTotal,
        sub_total:subTotal,
        total_products : cartValues.reduce((sum, item) => sum + item?.quantity, 0),
        vat:taxValue,  
      });
      if(response.data.bool){
        setInvoiceId(response.data.id);
        alert(response.data.message);
        setCartValues([]);
        setSearchValue(null);
        setTotalSection([]);
        setCustomerId({name: 'Default Customer', id:1});
        handlePdfPrint(); 
      }

    } catch (error) {
      console.error('Error creating a new account type:', error);
    } finally {
  }
}



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const [dopen, setdOpen] = React.useState(false);
const [modalContent, setModalContent] = useState('');
const handledOpen = () => setdOpen(true);
const handledClose = () => {
  setdOpen(false);
} 
const handlePdfPrint = async () => {
  setModalContent('print_pdf');
  handledOpen();
}
const handleBackdropClick = (event) => {
  event.stopPropagation();
};




return (
    <Container maxWidth="sm">

          <Formik
                        initialValues={customerData}
                        enableReinitialize={true}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                          console.log("Form submitted successfully", values);
                        }}
                      >
                        {({
                          errors,
                          touched,
                          validateForm,
                          setFieldValue,
                          setTouched,
                          handleBlur,
                          values,
                        }) => (
                          <Form>

                <Box sx={{ flexGrow: 1 }}>
                  <Stack spacing={2}>
                    <Item>
                      <Typography variant="h6" gutterBottom>
                        Original Invoice Information
                      </Typography>

                            <Autocomplete
                                          style={{ marginLeft: '0%' }}
                                          id="size-small-standard-multi"
                                          size="large"
                                          options={options}
                                          loading={loading}
                                          getOptionLabel={(option) => option?.invoice_no || ''}
                                          inputValue={inputValue}
                                          value={null}
                                          onInputChange={(event, newInputValue) => {
                                              setInputValue(newInputValue);
                                          }}
                                          onChange={async (event, newValue) => {
                                              if (newValue) {
                                                try {
                                                  setRows(newValue);
                                                  const response = await axios.post(`/ui/fetch-suspended-order-items`, {
                                                      id:newValue.id
                                                  });
                                                  if(response.data){
                                                      let responseData = response.data;
                                                      let formattedData = responseData.map(item => {
                                                          const { product } = item;                                               
                                                          return createDbData(
                                                            item.product_id,
                                                            item.product.product_code,
                                                            item.product.product_name,
                                                            item.product.product_garage,
                                                            item.product.product_image,
                                                            product.selling_price,
                                                            item.quantity,
                                                            parseFloat(item.total)?.toFixed(2),
                                                            item.tax,
                                                            product.tax
                                                        );
                                                      });                                
                                                      const updatedCartValues = formattedData.map(item => {
                                                          const updatedQuantity = item.quantity;
                                                          return {
                                                            ...item,
                                                            quantity: updatedQuantity,
                                                            sub_total: updatedQuantity * item.selling_price,
                                                            tax: parseFloat(item.tax),
                                                            taxValue: parseFloat(item.taxValue),
                                                          };
                                                        return item;
                                                      });                                                      
                                                      setCartValues(updatedCartValues);                                                     
                                                  }
                                                } catch (error) {
                                                  console.error('Error fetching invoice:', error);
                                                } finally {
                                              }
                                            }
                                          }}
                                          renderInput={(params) => (
                                              <CondensedTextField
                                                  {...params}
                                                  variant="standard"
                                                  label="Enter Invoice #"
                                                  placeholder="Enter Invoice #"
                                                  InputProps={{
                                                      ...params.InputProps,
                                                      endAdornment: (
                                                          <>
                                                              {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                              {params.InputProps.endAdornment}
                                                          </>
                                                      ),
                                                  }}
                                              />
                                            )}
                                        />


                    </Item>

                    <Item>
                          <Typography variant="h6" gutterBottom>
                            Totals
                          </Typography>
                          <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
                            <CondensedTypography>Total Items:</CondensedTypography>
                            <CondensedTypography>
                                {cartValues.reduce((sum, item) => sum + item?.quantity, 0)}
                            </CondensedTypography>
                          </Stack>

                          <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
                            <CondensedTypography>Sub total:</CondensedTypography>
                            <CondensedTypography>{subTotal.toFixed(2)}</CondensedTypography>
                          </Stack>

                          <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
                            <CondensedTypography>Tax:</CondensedTypography>
                            <CondensedTypography>{taxValue.toFixed(2)}</CondensedTypography>
                          </Stack>


                          <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
                            <CondensedTypography>Grand Total:</CondensedTypography>
                            <CondensedTypography>{grandTotal.toFixed(2)}</CondensedTypography>
                          </Stack>
                        </Item>

                    <Item>
     
                      <FormControl variant="standard" style={{ width: '100%',marginTop:'2%' }} error={Boolean(touched.fnotes && errors.fnotes)}>
                                      <CondensedTypography>Reason for Return *</CondensedTypography>
                                            <Field
                                            as={TextField}
                                            name="fnotes"
                                            id="outlined-multiline-static"
                                            label=""
                                            multiline
                                            rows={2}
                                            value={values.fnotes}
                                            onChange={(e) => setFieldValue('fnotes', e.target.value)}
                                            onBlur={handleBlur}
                                            placeholder=""
                                            InputProps={{
                                              readOnly: false,
                                            }}
                                            fullWidth
                                            />
                                            <ErrorMessage name="fnotes">
                                            {msg => <FormHelperText>{msg}</FormHelperText>}
                                            </ErrorMessage>
                                      </FormControl>  

                    </Item>

                    <Item>
                      <Stack direction="row" spacing={2} justifyContent="center">
                          <CondensedButton variant="contained" color="primary"
                                                            startIcon={<ArrowBack />}
                                                            onClick={async () => {
                                                                  const isValid = await validateForm();
                                                                  if (Object.keys(isValid).length === 0) {
                                                                        handleFinish(validateForm, setTouched, values);
                                                                  } else {
                                                                  setTouched({
                                                                    fnotes:true
                                                                  });
                                                                  }
                                                              }}
                                                          >
                              Create
                            </CondensedButton>
                            
                            <CondensedButton
                                  variant="outlined"
                                  color="secondary"
                                  startIcon={<ReceiptLong />}
                                  onClick={async () => {
                                    handlePdfPrint();
                                  }}
                                  disabled={!invoiceId}
                                >
                                  Print Sl.Return
                             </CondensedButton>

                      </Stack>
                    </Item>
                  </Stack>
                </Box>

          </Form>
        )}
      </Formik>
      <Modal
                open={dopen}
                onClose={handledClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropProps={{
                    onClick: handleBackdropClick,
                }}
              >
                <Box sx={{ ...style, backgroundColor: '#ffffffd4', position: 'relative' , width:'60%' , height: 'auto' }}>
                <IconButton
                        aria-label="close"
                        onClick={handledClose}
                        sx={{
                            position: 'absolute',
                            top: -20,
                            right: -20,
                            color: 'red',
                            backgroundColor: 'white',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 0, 0, 0.1)',
                            },
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {modalContent === 'print_pdf' && <ReportMain report_name={'sale return'} data={invoiceId}/>}
                </Box>
            </Modal>
    </Container>
  );
};

export default Return;
