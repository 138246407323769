import React from 'react';
import { styled } from '@mui/material/styles';
import { FormControl, Grid, Paper, Input, InputLabel, InputAdornment, FormHelperText } from '@mui/material';
import { Field, ErrorMessage } from 'formik';
import Computer from '@mui/icons-material/Computer';
import ManageAccounts from '@mui/icons-material/ManageAccounts';
import Key from '@mui/icons-material/Key';
import Storage from '@mui/icons-material/Storage';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Password from '@mui/icons-material/Password';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

export const ServerSettings = ({ errors, touched }) => (
  <Grid>
    <Item>
      <Grid item xs={12}>
        <Item>
          <FormControl variant="standard" style={{ width: '100%' }} error={Boolean(errors.server && touched.server)}>
            <InputLabel htmlFor="server">Server (Database host) </InputLabel> 
             {/* {`Error: ${JSON.stringify(errors, null, 2)}`} */}
            <Field
              name="server"
              as={Input}
              id="server"
              startAdornment={
                <InputAdornment position="start">
                  <Computer />
                </InputAdornment>
              }
            />
            <ErrorMessage name="server" component={FormHelperText} />
          </FormControl>
        </Item>

        <Item>
          <FormControl variant="standard" style={{ width: '100%' }} error={Boolean(errors.dbUsername && touched.dbUsername)}>
            <InputLabel htmlFor="db-username">Database username</InputLabel>
            <Field
              name="dbUsername"
              as={Input}
              id="db-username"
              startAdornment={
                <InputAdornment position="start">
                  <ManageAccounts />
                </InputAdornment>
              }
            />
            <ErrorMessage name="dbUsername" component={FormHelperText} />
          </FormControl>
        </Item>

        <Item>
          <FormControl variant="standard" style={{ width: '100%' }} error={Boolean(errors.dbPassword && touched.dbPassword)}>
            <InputLabel htmlFor="db-password">Database password</InputLabel>
            <Field
              name="dbPassword"
              as={Input}
              id="db-password"
              type="password"
              startAdornment={
                <InputAdornment position="start">
                  <Key />
                </InputAdornment>
              }
            />
            <ErrorMessage name="dbPassword" component={FormHelperText} />
          </FormControl>
        </Item>

        <Item>
          <FormControl variant="standard" style={{ width: '100%' }} error={Boolean(errors.database && touched.database)}>
            <InputLabel htmlFor="database">Database</InputLabel>
            <Field
              name="database"
              as={Input}
              id="database"
              startAdornment={
                <InputAdornment position="start">
                  <Storage />
                </InputAdornment>
              }
            />
            <ErrorMessage name="database" component={FormHelperText} />
          </FormControl>
        </Item>

        <Item>
          <FormControl variant="standard" style={{ width: '100%' }} error={Boolean(errors.sysAdminUsername && touched.sysAdminUsername)}>
            <InputLabel htmlFor="sys-admin-username">System admin username (email)</InputLabel>
            <Field
              name="sysAdminUsername"
              as={Input}
              id="sys-admin-username"
              startAdornment={
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              }
            />
            <ErrorMessage name="sysAdminUsername" component={FormHelperText} />
          </FormControl>
        </Item>

        <Item>
          <FormControl variant="standard" style={{ width: '100%' }} error={Boolean(errors.sysAdminPassword && touched.sysAdminPassword)}>
            <InputLabel htmlFor="sys-admin-password">System admin password</InputLabel>
            <Field
              name="sysAdminPassword"
              as={Input}
              id="sys-admin-password"
              type="password"
              startAdornment={
                <InputAdornment position="start">
                  <Password />
                </InputAdornment>
              }
            />
            <ErrorMessage name="sysAdminPassword" component={FormHelperText} />
          </FormControl>
        </Item>
      </Grid>
    </Item>
  </Grid>
);
