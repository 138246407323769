import React, { useState, useEffect } from 'react';
import { Divider,Chip, Container,Stack, TextField, Typography, Box, Paper, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Autocomplete, createFilterOptions } from '@mui/material';
import { styled } from '@mui/material/styles';
import Link from '@mui/icons-material/Link';
import Add from '@mui/icons-material/Add';
import Grid from '@mui/material/Unstable_Grid2';
import AddCustomer from './AddCustomer';

const filter = createFilterOptions();

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const CondensedButton = styled(Button)(({ theme }) => ({
  fontSize: '12px',
  margin: '0px',
  padding: '5px 0px',
  width:'500px'
}));

const CondensedTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '0.875rem',
    padding: '4px 8px',
    height: '30px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '1px',
      textAlign: 'center',
    },
  },
  margin: 0,
  '& input': {
    textAlign: 'center',
  },
}));

const CondensedAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '0.875rem',
    padding: '4px 8px',
    height: '30px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '1px',
    },
  },
  margin: 0,
  '& input': {
    textAlign: 'center',
  },
}));

const CondensedTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '30px',
  marginLeft: '5px',
}));

const CustomerPanel = ({customerId, setCustomerId , onToggleShortcutDrawer }) => {

  const [value, setValue] = React.useState({
    name: customerId.name,
    id:customerId.id,
  });
  const [open, toggleOpen] = React.useState(false);
  const [customers, setCustomers] = useState([]);

  const [openItem, setOpenItem] = React.useState(false);
  const handleCloseItem = () => {
    setOpenItem(false);
  };
  const handleClickOpen = () => setOpenItem(true);


  useEffect(() => {
    fetch('/ui/customers')
      .then(response => response.json())
      .then(data => setCustomers(data))
      .catch(error => console.error('Error fetching customers:', error));
  }, []); 

  const handleClose = () => {
    setDialogValue({
      title: '',
      year: '',
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    title: '',
    year: '',
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setValue({
      title: dialogValue.title,
      year: parseInt(dialogValue.year, 10),
    });
    handleClose();
  };


  return (
    <Container maxWidth="sm" style={{ marginBottom:'5%' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0} alignItems="center">
          <Grid xs={12} sx={{ padding: "1px" }}>
            <CondensedTypography>Choose Client (Required for payment)</CondensedTypography>
          </Grid>
          <Grid xs={12} sx={{ padding: "1px" }}>
            <React.Fragment>
              <CondensedAutocomplete
                value={customerId.name}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    setTimeout(() => {
                      toggleOpen(true);
                      setDialogValue({
                        name: newValue,
                        year: '',
                      });
                    });
                  } else if (newValue && newValue.inputValue) {
                    toggleOpen(true);
                    setDialogValue({
                      name: newValue.inputValue,
                      year: '',
                    });
                  } else {
                    setValue(newValue);
                    setCustomerId({name:newValue,id:newValue.id});
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  if (params.inputValue !== '') {
                    filtered.push({
                      inputValue: params.inputValue,
                      name: `Add "${params.inputValue}"`,
                    });
                  }
                  return filtered;
                }}
                id="free-solo-dialog-demo"
                options={customers}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.name;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(props, option) => {
                  const { key, ...optionProps } = props;
                  return (
                    <li key={key} {...optionProps}>
                      {option.name}
                    </li>
                    );
                }}
                
                freeSolo
                renderInput={(params) => <CondensedTextField  {...params} label="" />}
              />

                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Add a new customer</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                 Did you miss any customer in our list? Please, add it!
                            </DialogContentText>
                                <AddCustomer name={dialogValue.name} handleCloseItem={handleClose} />
                           </DialogContent>
                    </Dialog>
            </React.Fragment>
          </Grid>

          <Grid xs={12} sx={{ padding: "1px" , marginTop:'5px' }}>
            <Stack direction="row" spacing={2} style={{justifyContent: 'center',}}>   
                    <CondensedButton onClick={handleClickOpen} variant="outlined" startIcon={<Add />} >
                        New Customer
                    </CondensedButton>
                    <CondensedButton variant="outlined" startIcon={<Link />} onClick={onToggleShortcutDrawer}>
                        Shortcuts
                    </CondensedButton>
            </Stack>
          </Grid>

        </Grid>
          <Dialog open={openItem} onClose={handleCloseItem}>
                  <DialogTitle>New Customer</DialogTitle>
                  <DialogContent>
                          <AddCustomer handleCloseItem={handleCloseItem}/>
                  </DialogContent>
           </Dialog>
      </Box>

      <Divider>
            <Chip label="Totals" size="small" />
      </Divider>
    </Container>
  );
};
export default CustomerPanel;

