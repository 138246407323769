import React, { useState, useEffect } from 'react';
import {
    Button , MenuItem , Select, InputLabel , FormControl, IconButton, Card, CardContent, CardMedia, Modal, BottomNavigation, BottomNavigationAction, Paper,
    Container, TextField, Typography, Box, CssBaseline, List, Grid
} from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';


import * as XLSX from 'xlsx';
import { DataGrid } from '@mui/x-data-grid';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import  Download  from '@mui/icons-material/Download';
import dayjs from 'dayjs';
import PaymentForm from './PaymentForm';


import axios from 'axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff2f28c',
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

const formatDate = (date) => {
    return date ? dayjs(date).format('YYYY-MM-DD') : '';
  };

  const paperStyle = { padding: 20, height: 'auto', width: 'auto', margin: "0px auto", backgroundColor: 'rgb(221 215 201 / 45%)', borderRadius: '12px', boxShadow: '0px 0px 8px rgba(0, 0, 0, 25)' };
const Payment = ({user, cart , setCart}) => {
    const [value, setValue] = useState(0);
    const [data, setData] = useState([]);
    const [categorydata, setCategorydata] = useState([]);
    const [categorytab, setCategorytab] = useState(0);
    const [open, setOpen] = useState(false);
    const [categoryid, setCategoryId] = useState(null);
    const today = dayjs().startOf('day');
    const tomorrow = dayjs().add(1, 'day').startOf('day');
    const [selectedDate, setSelectedDate] = useState([today, tomorrow]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedFields, setSelectedFields] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [orders, setOrders] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const fetchData = async () => {
        try {
          const response = await axios.post(`/ui/restaurant-orders`, {
            start_date: formatDate(selectedDate[0]),
            end_date: formatDate(selectedDate[1]),
            status:'pending'
          });
          setData(response.data);
        } catch (error) {
          console.error('Error fetching orders:', error);
        } finally {
        }
    }

    useEffect(() => {
        fetchData(selectedDate);
    }, [selectedDate]);

    useEffect(() => {
        setFilteredRows(data);
    }, [data]);
    
    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
        const filtered = data.filter((row) => {
        return row?.invoice_no.toLowerCase().includes(value);
        })
        setFilteredRows(filtered);
      };
    
      const handleRowSelection = async (selectedData) => {
        try {
            const response = await axios.post(`/ui/restaurant-order-items`, {
              id: selectedData.id
            });
            setOrders(response.data);
          } catch (error) {
            console.error('Error fetching order items:', error);
          } finally {
          }
      };
    
    
    const removeSpecificChild = () => {
        const dateRangeCalendar = document.querySelector('.MuiDateRangeCalendar-root');
        if (dateRangeCalendar) {
          const children = dateRangeCalendar.childNodes;
          children.forEach(child => {
            if (child.textContent.includes('MUI X Missing license key')) {
              child.remove();
            }
          });
        }
      };

    useEffect(() => {
        removeSpecificChild();
      }, []);

    const handleOpen = () => {
        removeSpecificChild();
      };

    const handleClose = () => {
    };


    const handleDateChange = (newValue) => {
        removeSpecificChild();
        setSelectedDate(newValue);  
      };

    const handleFieldChange = (event) => {
        setSelectedFields(event.target.value);
    };

    const CustomDateRangePicker = styled(DateRangePicker)(({ theme }) => ({
        '& .MuiInputBase-root': {
          fontSize: '14px',
          padding: '0',
        },
        '& .MuiInputBase-input': {
          padding: '5px 5px',
          textAlign: 'center',
        },
      }));

    const shortcutsItems = [
        {
          label: 'This Week',
          getValue: () => {
            const today = dayjs();
            return [today.startOf('week'), today.endOf('week')];
          },
        },
        {
          label: 'Last Week',
          getValue: () => {
            const today = dayjs();
            const prevWeek = today.subtract(7, 'day');
            return [prevWeek.startOf('week'), prevWeek.endOf('week')];
          },
        },
        {
          label: 'Last 7 Days',
          getValue: () => {
            const today = dayjs();
            return [today.subtract(7, 'day'), today];
          },
        },
        {
          label: 'Current Month',
          getValue: () => {
            const today = dayjs();
            return [today.startOf('month'), today.endOf('month')];
          },
        },
        {
          label: 'Next Month',
          getValue: () => {
            const today = dayjs();
            const startOfNextMonth = today.endOf('month').add(1, 'day');
            return [startOfNextMonth, startOfNextMonth.endOf('month')];
          },
        },
        { label: 'Reset', getValue: () => [null, null] },
      ];
    const handleExport = () => {
        const dta = data.map(row => ({
          'Invoice No.': row.invoice_no,
          'Order Date': row.created_at,
          'Order Status': row.order_status,
          'Total products': row.total_products,
          'Sub Total': row.sub_total,
          'Total': row.total,
          'Customer': row.customer?.name,
          'Customer Email': row.customer?.email,
          'Customer Phone': row.customer?.phone,
          'Employee' : row.user?.name,
          'Employee Email' : row.user?.email
        }));
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(dta);
        XLSX.utils.book_append_sheet(wb, ws, 'Order Data');
        XLSX.writeFile(wb, 'Order_data.xlsx');
      };

      const columns = [
        { field: 'id', headerName: 'Order .#', flex: 0.2 },
        {
            field: 'invoice_no',
            headerName: 'Invoice No.',
            flex: 0.3,
            renderCell: (params) => params.row.invoice_no || 'N/A',
        },
        {
          field: 'order_date',
          headerName: 'Order Date',
          flex: 1,
          valueFormatter: (params) => {
            return dayjs(params.value).format('DD MMM YYYY HH:mm:ss');
          }
        },
        {
          field: 'order_status',
          headerName: 'Status',
          flex: 0.5,
          renderCell: (params) => params.row.order_status || 'N/A',
        },
        {
            field: 'total',
            headerName: 'Total',
            flex: 0.2,
            renderCell: (params) => {
              return (
                <div style={{ width: '100%', textAlign: 'right' }}>
                  {Number(params?.row?.total).toFixed(2) || '0.00'}
                </div>
              );
            }
        },
       {
          field: 'due',
          headerName: 'Due',
          flex: 0.2,
          renderCell: (params) => {
            return (
              <div style={{ width: '100%', textAlign: 'right' }}>
                {Number(params?.row?.due).toFixed(2) || '0.00'}
              </div>
            );
          }
        },
        {
            field: 'user_id',
            headerName: 'Employee Name',
            flex: 0.6,
            renderCell: (params) => params.row?.user?.name || 'N/A',
        },
      ];
      
      const columns_items = [
        {
          field: 'productCode',
          headerName: 'Code',
          flex: 0.5,
          renderCell: (params) => params.row.product?.product_code || 'N/A',
        },
        {
          field: 'productName',
          headerName: 'Product',
          flex: 1,
          renderCell: (params) => params.row.product?.product_name || 'N/A',
        },
        {
          field: 'productCategory',
          headerName: 'Category',
          flex: 0.8,
          renderCell: (params) => params.row.product?.category?.name || 'N/A',
        }, 
        { field: 'quantity', headerName: 'Qty Sold' },
      
        {
          field: 'unitcost',
          headerName: 'Price',
          flex: 0.2,
          renderCell: (params) => {
            return (
              <div style={{ width: '100%', textAlign: 'right' }}>
                {Number(params?.row?.unitcost).toFixed(2) || '0.00'}
              </div>
            );
          }
        },

        {
          field: 'tax',
          headerName: 'Tax',
          flex: 0.2,
          renderCell: (params) => {
            return (
              <div style={{ width: '100%', textAlign: 'right' }}>
                {Number(params?.row?.tax).toFixed(2) || '0.00'}
              </div>
            );
          }
        },
      
        {
          field: 'total',
          headerName: 'Total Inc.',
          flex: 0.3,
          renderCell: (params) => {
            return (
              <div style={{ width: '100%', textAlign: 'right' }}>
                {Number(params?.row?.totalinc).toFixed(2) || '0.00'}
              </div>
            );
          }
        },
  ];
    return (
        <Container maxWidth={true} sx={{ width: '100%' }}>
            <Grid container spacing={1}>
                <Grid item xs={8}>
                    <Item>
                        <Box sx={{ pb: 7 }}>
                            <CssBaseline />
                            <List>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['SingleInputDateRangeField']}>
                                            <CustomDateRangePicker
                                            startText="Start Date"
                                            endText="End Date"
                                            slots={{ field: SingleInputDateRangeField }}
                                            slotProps={{
                                                shortcuts: {
                                                items: shortcutsItems,
                                                },
                                                actionBar: { actions: [] },
                                                field: {
                                                sx: {
                                                    '& .MuiInputBase-root': {
                                                    fontSize: '14px',
                                                    },
                                                    '& .MuiInputBase-input': {
                                                    },
                                                },
                                                },
                                            }}
                                            calendars={2}
                                            name="allowedRange"
                                            value={selectedDate}
                                            onClick={(e) => removeSpecificChild()}
                                            onChange={handleDateChange}
                                            onOpen={handleOpen}
                                            onClose={handleClose}
                                            renderInput={(startProps, endProps) => (
                                            <>
                                                <TextField {...startProps} />
                                                <TextField {...endProps} />
                                            </>
                                            )}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>

                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <TextField
                                        style={{width:'auto',marginLeft:'5%',fontSize:'10px'}}
                                        label="Search"
                                        variant="outlined"
                                        size="small"
                                        value={searchTerm}
                                        onChange={handleSearch}
                                        />
                                        <FormControl variant="outlined" size="small" style={{minWidth:'150px'}}>
                                            <InputLabel id="fields-select-label">Fields</InputLabel>
                                            <Select
                                            labelId="fields-select-label"
                                            multiple
                                            value={selectedFields}
                                            onChange={handleFieldChange}
                                            label="Fields"
                                            >
                                            {columns.map((column) => (
                                                <MenuItem key={column.field} value={column.field}>
                                                {column.headerName}
                                                </MenuItem>
                                            ))}
                                            </Select>
                                        </FormControl>
                                        <Button variant="outline" startIcon={<Download />} onClick={handleExport}>
                                            Export
                                        </Button>
                                    </Box>
                                    </Box>
                                    <DataGrid
                                        rows={filteredRows}
                                        columns={columns.filter((column) => selectedFields.length === 0 || selectedFields.includes(column.field))}
                                        initialState={{
                                        pagination: {
                                        paginationModel: { page: 0, pageSize: 10 },
                                        },
                                        }}
                                        pageSizeOptions={[5, 10]}
                                        rowHeight={30}
                                        selectionModel={selectedRows}
                                        onRowClick={(params) => handleRowSelection(params)}
                                />
                            </List>
                        </Box>
                        <Paper elavation={12} style={paperStyle} >
                        <DataGrid
                                    rows={orders}
                                    columns={columns_items}
                                    initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 10 },
                                    },
                                    }}
                                    pageSizeOptions={[5, 10]}
                                    rowHeight={30}
                                />


                        </Paper>
                    </Item>
                </Grid>
                <Grid item xs={4}>
                    <Item><PaymentForm user={user} cart={orders} setCart={setCart} fetchData={fetchData} /></Item>
                </Grid>
            </Grid>
        </Container>
    );
};
export default Payment;
