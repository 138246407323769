
import React, { useState } from 'react';
import {
  Stack,
  Button,
  FormControl,
  FormHelperText,
  TextField,
  Container,
  Grid,
  Paper,
  Typography,
  Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const CondensedButton = styled(Button)(({ theme }) => ({
  fontSize: '12px',
  margin: '0px',
  padding: '5px 0px',
  width: '100px',
}));

const CondensedTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  display: 'flex',
  alignItems: 'center',
  height: '30px',
  marginLeft: '5px',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '14px',
    padding: '0px 8px',
    height: '30px',
    width: '90%',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '1px',
      textAlign: 'left',
    },
  },
  margin: 0,
  '& input': {
    textAlign: 'left',
  },
}));

const CondensedTextField = (props) => {
  const { name, value, onChange, onBlur, ...otherProps } = props;
  return (
    <StyledTextField
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      {...otherProps}
    />
  );
}

const LicenceExpiry = ({ handleClose, user }) => {
  const [selectedPackage, setSelectedPackage] = useState(null); // State for selected package
  const expiryDate = new Date(user?.business[0].expiration_date).toISOString().split('T')[0];

  const validationSchema = Yup.object().shape({
    fphone_number: Yup.string().required('Mpesa phone number is required!'),
  });

  const [formData, setFormData] = useState({
    fphone_number: user?.business[0].telephone,
  });

  const handleFinish = async (validateForm, setTouched, values) => {
    try {
      values.user_id = user?.id;
      values.status = 1;
      values.selected_package = selectedPackage; // Add the selected package to values
      const response = await axios.post(`/licence/renew`, {
        values
      });
      handleClose();
    } catch (error) {
      console.error('Error creating renewing POS licence:', error);
    } finally {
      // Optionally reset or handle any cleanup here
    }
  }

  return (
    <Container maxWidth={true} sx={{ width: '100%', textAlign: 'center', minHeight: 'auto', height: 'auto', overflowY: 'auto', overflowX: 'none' }}>
      <Formik
        initialValues={formData}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log("Form submitted successfully", values);
        }}
      >
        {({ errors, touched, validateForm, setTouched, values, setFieldValue, handleBlur }) => (
          <Form>
            <Grid container spacing={1} direction="column" alignItems="left" justifyContent="left" sx={{ flexGrow: 1 }}>
              <Grid item xs={12} sx={{ width: '100%' }}>
                <CondensedTypography style={{ fontWeight: '900', width: '100%', fontSize: '20px', color: 'red' }}>
                  Your POS Licence has expired! Expiry date {expiryDate}
                </CondensedTypography>
              </Grid>
            </Grid>

            <Grid container spacing={1} direction="column" alignItems="center" justifyContent="center" sx={{ flexGrow: 1 }}>
              <Grid item xs={12} sx={{ width: '100%' }}>
                <Paper elevation={3} sx={{ padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                  <Grid container spacing={1} alignItems="left" style={{ padding: '2%' }}>
                    <Grid item xs={12} sx={{ padding: "1px" }}>
                      <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
                        <CondensedTypography style={{ fontWeight: '900', width: '20%' }}>Mpesa phone number</CondensedTypography>
                        <FormControl variant="standard" style={{ width: '20%' }} error={Boolean(touched.fphone_number && errors.fphone_number)}>
                          <CondensedTextField
                            name="fphone_number"
                            variant="outlined"
                            value={values.fphone_number}
                            onChange={(e) => setFieldValue('fphone_number', e.target.value)}
                            onBlur={handleBlur}
                            InputProps={{
                              readOnly: false,
                            }}
                            fullWidth
                          />
                          <ErrorMessage name="fphone_number">
                            {msg => <FormHelperText>{msg}</FormHelperText>}
                          </ErrorMessage>
                        </FormControl>
                      </Stack>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12} sx={{ width: '100%' }}>
                <Paper elevation={3} sx={{ padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                  <Grid container spacing={1} alignItems="left" style={{ padding: '2%' }}>
                    <Grid item xs={12} sx={{ padding: "1px" }}>
                      <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
                        {/* <CondensedTypography style={{ fontWeight: '900', width: '20%' }}>Subscriptions</CondensedTypography> */}
                        <Grid container spacing={2}>
                          {/* Basic Package */}
                          <Grid item xs={4}>
                            <Paper
                              elevation={2}
                              sx={{
                                padding: '2%',
                                textAlign: 'center',
                                cursor: 'pointer',
                                backgroundColor: selectedPackage === 'Basic Package' ? '#cfe8fc' : 'inherit',
                                border: selectedPackage === 'Basic Package' ? '2px solid blue' : 'none'
                              }}
                              onClick={() => setSelectedPackage('Basic Package')}
                            >
                              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                Basic Package
                              </Typography>
                              <Divider style={{marginTop:'3%'}}/>
                              <CondensedTypography sx={{ margin: '10px 0' }}>
                                Ideal for individuals just starting out.
                              </CondensedTypography>
                              <CondensedTypography variant="h5" sx={{ fontWeight: 'bold' }}>
                                 KES 1000.00/month
                              </CondensedTypography>
                              <Stack spacing={1} sx={{ marginTop: '10px' }}>
                                <CondensedTypography>✓ Access to basic features</CondensedTypography>
                                <CondensedTypography>✓ Community support</CondensedTypography>
                                <CondensedTypography>✗ Limited integrations</CondensedTypography>
                              </Stack>
                              <div style={{ marginTop: '15px' }}>
                                <img src="../images/pos-software.jpg" alt="Basic Package" style={{ width: '50%', height: 'auto', display: 'block', margin: '0 auto' }} />
                              </div>
                            </Paper>
                          </Grid>

                          <Grid item xs={4}>
                            <Paper
                              elevation={2}
                              sx={{
                                padding: '2%',
                                textAlign: 'center',
                                cursor: 'pointer',
                                backgroundColor: selectedPackage === 'Pro Package' ? '#cfe8fc' : 'inherit',
                                border: selectedPackage === 'Pro Package' ? '2px solid blue' : 'none'
                              }}
                              onClick={() => setSelectedPackage('Pro Package')}
                            >
                              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                Pro Package
                              </Typography>
                              <Divider style={{marginTop:'3%'}}/>
                              <CondensedTypography sx={{ margin: '10px 0' }}>
                                Perfect for growing teams with advanced needs.
                              </CondensedTypography>
                              <CondensedTypography variant="h5" sx={{ fontWeight: 'bold' }}>
                                KES 2000.00/month
                              </CondensedTypography>
                              <Stack spacing={1} sx={{ marginTop: '10px' }}>
                                <CondensedTypography>✓ Everything in Basic</CondensedTypography>
                                <CondensedTypography>✓ Priority support</CondensedTypography>
                                <CondensedTypography>✓ 10 integrations</CondensedTypography>
                              </Stack>
                              <div style={{ marginTop: '15px' }}>
                                <img src="../images/pos-software.jpg" alt="Pro Package" style={{ width: '50%', height: 'auto', display: 'block', margin: '0 auto' }} />
                              </div>
                            </Paper>
                          </Grid>


                          <Grid item xs={4}>
                            <Paper
                              elevation={2}
                              sx={{
                                padding: '2%',
                                textAlign: 'center',
                                cursor: 'pointer',
                                backgroundColor: selectedPackage === 'Enterprise Package' ? '#cfe8fc' : 'inherit',
                                border: selectedPackage === 'Enterprise Package' ? '2px solid blue' : 'none'
                              }}
                              onClick={() => setSelectedPackage('Enterprise Package')}
                            >
                              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                Enterprise Package
                              </Typography>
                              <Divider style={{marginTop:'3%'}}/>
                              <CondensedTypography sx={{ margin: '10px 0' }}>
                                For large organizations with complex requirements.
                              </CondensedTypography>
                              <CondensedTypography variant="h5" sx={{ fontWeight: 'bold' }}>
                                KES 5000.00/month
                              </CondensedTypography>
                              <Stack spacing={1} sx={{ marginTop: '10px' }}>
                                <CondensedTypography>✓ Everything in Pro</CondensedTypography>
                                <CondensedTypography>✓ Custom solutions</CondensedTypography>
                                <CondensedTypography>✓ Dedicated account manager</CondensedTypography>
                              </Stack>
                              <div style={{ marginTop: '15px' }}> {/* Placeholder for image */}
                                <img src="../images/pos-software.jpg" alt="Enterprise Package" style={{ width: '50%', height: 'auto', display: 'block', margin: '0 auto' }} />
                              </div>
                            </Paper>
                          </Grid>
                        </Grid>
                      </Stack>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid container spacing={1} direction="column" alignItems="center" justifyContent="center" sx={{ flexGrow: 1, marginTop: '0.5%' }}>
                <Grid item xs={12} sx={{ width: '100%' }}>
                  <Paper
                    elevation={3}
                    sx={{
                      padding: 1,
                      display: 'flex',
                      justifyContent: 'right',
                      alignItems: 'right',
                      textAlign: 'center',
                    }}
                  >
                    <CondensedButton
                      style={{ width: 'auto', paddingLeft: '1%', paddingRight: '1%' }}
                      variant="outlined"
                      onClick={async () => {
                        const isValid = await validateForm();
                        if (Object.keys(isValid).length === 0 && selectedPackage) {
                          handleFinish(validateForm, setTouched, values);
                        } else {
                          setTouched({
                            fphone_number: true,
                          });
                        }
                      }}
                    >
                      Click to request a new licence
                    </CondensedButton>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default LicenceExpiry;

