import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import { Avatar, Button, Link, TextField, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Unstable_Grid2';
import axios from 'axios';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    textAlign: 'left',
    color: theme.palette.text.secondary,
  }));

const CondensedTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
      fontSize: '14px',
      padding: '5px 0px 0px ',
      height: '25px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderWidth: '1px',
        textAlign:'right',
      },
    },
    margin: 0,
    '& input': {
      textAlign: 'left',
    },
  }));

const Login = ({ onLoginSuccess , setUserDetails , checkUserDetails }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);  // State to manage loading state

    const paperStyle = { padding: 20, height: 'auto', width: 'auto', margin: "0px auto", backgroundColor: 'rgb(221 215 201 / 45%)', borderRadius: '12px', boxShadow: '0px 0px 8px rgba(0, 0, 0, 25)' };
    const avatarStyle = { backgroundColor: '#D9D9D9' };
    const btnstyle = { backgroundColor: '#1B6DA1', margin: '12px 0' };
    const logoStyle = { backgroundColor: '#D9D9D9', margin: '10px 0', width: 70, height: 70 };





    const handleLogin = async (event) => {
        event.preventDefault();
        setError('');
        setLoading(true);
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
        try {
            const response = await axios.post('/ui/login', {
                username,
                password,
            }, {
                headers: {
                    'X-CSRF-TOKEN': csrfToken,
                }
            });
            if (response.data.bool) {
                //localStorage.setItem('userDetails', JSON.stringify(response.data.user));
                setUserDetails(response.data.user);
                if (onLoginSuccess) {
                    checkUserDetails();
                    onLoginSuccess();
                }
            } else {
                setError('Invalid credentials. Please try again.');
            }
        } catch (error) {
            setError('An error occurred while logging in. Please try again.');
        }
        setLoading(false);
    };

    return (
        <Grid>
            <Grid align='center'>
                <Avatar style={logoStyle} src="./assets/images/Logo.png"></Avatar>
                <h2>Company Name</h2>
            </Grid>
            <Paper elavation={12} style={paperStyle} >
                <Grid align='center'>
                    <Avatar style={avatarStyle}><LockOutlinedIcon style={{ color: '#002A57' }} /></Avatar>
                    <h2>Login</h2>
                </Grid>
                <form onSubmit={handleLogin}>
                    <CondensedTextField
                        label="Username"
                        variant="standard"
                        placeholder='Enter Your Username'
                        fullWidth
                        required
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <CondensedTextField
                        label="Password"
                        variant="standard"
                        placeholder='Enter Your Password'
                        type='password'
                        fullWidth
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <FormControlLabel control={<Checkbox defaultChecked />} label="Remember Me" />
                    {error && <Typography color="error">{error}</Typography>}
                    <Button
                        style={btnstyle}
                        type='submit'
                        color='primary'
                        variant="contained"
                        fullWidth
                        disabled={loading}
                    >
                        {loading ? 'Logging in...' : 'Login'}
                    </Button>
                </form>
            </Paper>
        </Grid>
    );
}

export default Login;
