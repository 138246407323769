import React, { useState } from 'react';
import { Card, CardContent, CardMedia , Box , Typography  } from '@mui/material';
import { keyframes } from '@mui/system';

const ProductCard = ({ product, onAddToCart }) => {
    const [isHighlighted, setIsHighlighted] = useState(false);
    const [isFlying, setIsFlying] = useState(false);

    const handleAddToCart = () => {
        setIsHighlighted(true);
        setIsFlying(true);
        onAddToCart(product);
        setTimeout(() => {
            setIsHighlighted(false);
            setIsFlying(false);
        }, 1000);
    };

    const flyToCart = keyframes`
        0% {
            transform: scale(1) translate(0, 0);
            opacity: 1;
        }
        100% {
            transform: scale(0.1) translate(300px, -300px); /* Adjust translate values based on cart position */
            opacity: 0;
        }
    `;

    return (
        <Card
            sx={{
                maxWidth: 140,
                mb: 2,
                position: 'relative',
                textAlign: 'center',
                border: isHighlighted ? '2px solid #1976d2' : '1px solid transparent',
                boxShadow: isHighlighted ? '0 0 10px rgba(25, 118, 210, 0.5)' : 'none',
                cursor: 'pointer',
            }}
            onClick={handleAddToCart}
        >
            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    animation: isFlying ? `${flyToCart} 1s ease-in-out` : 'none',
                }}
            >
                <CardMedia
                    component="img"
                    alt={product.product_name}
                    image={product.product_image || "./images/default-product-image.png"}
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "./images/default-product-image.png";
                    }}
                    sx={{ objectFit: 'contain', height: '80px',}}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        top: 5,
                        right: 5,
                        backgroundColor: '#00050a',
                        color: '#fff',
                        borderRadius: '20%',
                        padding: '5px 10px',
                        fontSize: '12px',
                        fontWeight: 'bold',
                    }}
                >
                    {product.selling_price.toFixed(2)} /=
                </Box>
            </Box>
            <CardContent sx={{ padding: '10px 0' }}>
                <Typography
                    variant="h6"
                    component="div"
                    sx={{
                        fontSize: '14px',
                        marginBottom: '8px',
                        height: '40px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                    }}
                >
                    {product.product_name}
                </Typography>
                <Typography variant="body2" sx={{ fontSize: '12px', marginBottom: '4px' }}>
                    {product.category?.name}
                </Typography>
                <Typography variant="body2" sx={{ fontSize: '10px' }}>
                    Supplier: {product.supplier?.name}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default ProductCard;

