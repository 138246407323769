import React, { useState , useEffect } from 'react';
import { useNavigate  } from 'react-router-dom';
import { Alert, Box , Stepper, Step, StepLabel, StepContent, Button, Paper, Typography, Container, Link } from '@mui/material';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import  BusinessInfo  from './BusinessInfo';
import { CategoryInfo } from './CategoryInfo';
import { LicenceDetails } from './LicenceDetails';
import { ServerSettings } from './ServerSettings';
import './App.css';
import axios from 'axios';

const steps = [
  { label: 'Business information', component: BusinessInfo },
  { label: 'Category', component: CategoryInfo },
  { label: 'Licence details', component: LicenceDetails },
  { label: 'Server settings', component: ServerSettings },
];

const validationSchema = yup.object().shape({
  customerName:yup.string().required('Customer name is required'),
  businessName: yup.string().required('Business name is required'),
  businessLocation: yup.string().required('Business location is required'),
  pin: yup.string().required('PIN is required'),
  telephone: yup.string().required('Telephone is required'),
  email: yup.string().email('Invalid email format').required('Email is required'),
});

const initialData = [
  {
    id: 'branch_1',
    label: 'Default Branch',
    children: [
      { id: 'counter_1', label: 'Counter 1' }
    ],
  },
];

export default function Install() {
  const [loading, setLoading] = useState(false); 
  const [defaultContinue, setDefaultContinue] = useState("Continue"); 
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    customerName:'',
    businessName: '',
    businessLocation: '',
    pin: '',
    telephone: '',
    email: '',
    businessCategory: '1',
    licence: '',
    server: '',
    dbUsername: '',
    dbPassword: '',
    database: '',
    sysAdminUsername: '',
    sysAdminPassword: '',
    branches:[]
  });
  const [treeData, setTreeData] = useState(initialData);


  const navigate = useNavigate();

  const readInstallConfig = async () => {
      try {
          const response = await fetch('/install.conf');
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          const text = await response.text();
          const lines = text.split('\n');
          const systemInstalledLine = lines.find(line => line.startsWith('SYSTEM_INSTALLED'));
          if (systemInstalledLine) {
              const value = systemInstalledLine.split('=')[1].trim();
              return value;
          } else {
              throw new Error('SYSTEM_INSTALLED not found in the config file');
          }
      } catch (error) {
          console.error('Error reading install.conf:', error);
          return null;
      }
    };

    useEffect(() => {
      const fetchConfig = async () => {
          const value = await readInstallConfig();
          if (value === '1') {
                navigate('/');
          }
      };
      fetchConfig();
    }, [navigate]);

  // useEffect(() => {
  //   if (import.meta.env.VITE_SYSTEM_INSTALLED === '1') {
  //     navigate('/');
  //   }
  // }, [navigate]);

  const handleNext = async (validateForm, setTouched, values) => {
    const errors = await validateForm();
    setTouched(errors);
    if (Object.keys(errors).length === 0) {
      setFormData((prevData) => ({ ...prevData, ...values }));
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = (values) => {
    setFormData((prevData) => ({ ...prevData, ...values }));
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setFormData({
      customerName:'',
      businessName: '',
      businessLocation: '',
      pin: '',
      telephone: '',
      email: '',
      businessCategory:'1',
      licence: '',
      server: '',
      dbUsername: '',
      dbPassword: '',
      database: '',
      sysAdminUsername: '',
      sysAdminPassword: '',
      branches:[]
    });
  };


const dynamicValidationSchema = yup.object().shape({
    server: yup.string().required('Please give URL where you are hosting the POS System!'),
    licence: yup.string().required('POS Licence is required!'),
    dbUsername: yup.string().required('Database username is required!'),
    database: yup.string().required('Please specify a database for your POS installation!'),
    sysAdminUsername: yup.string().required('Please specify a system admin username!'),
    sysAdminPassword: yup.string().required('Please specify a system admin password!'),
    businessCategory: yup.string().required('Your POS Business category is required!'),
  });

  const handleLicenceCheck = async (validateForm, setTouched, values) => {
    try {
      setLoading(true);
        if(values.licence == ""){
          setAlertInfo({ show: true, severity: 'error',  message: "Please fill in POS License  details!" });
          setLoading(false);
        }else{
            setAlertInfo({ show: false, severity: 'info',  message: "" });
            try {
              setDefaultContinue("Checking POS License  details....");
              const response = await axios.post('/ui/licence',
                { licence: values.licence },
                {
                  referrerPolicy: 'no-referrer',
                  withCredentials: true,
                }
              );

              let error_message = response?.data?.error;
              if(error_message){
                setAlertInfo({ show: true, severity: 'error',  message: error_message });
              }else{
                setDefaultContinue("Continue");
                handleNext(validateForm, setTouched, values);
                //9D8E-7F6A-5B4C-3D2E-6BDA-49EC
                values.approved_license = response.data;
                setLoading(false);
                return response.data;
              }
               setLoading(false);
              return response.data;
            } catch (error) {
              setAlertInfo({ show: true, severity: 'error',  message: error.response.data.error });
              setLoading(false);
              setDefaultContinue("Continue");
              return null;
           }
        }
        } catch (e) {
          setLoading(false);
          setDefaultContinue("Continue");
    }
  };

  const handleFinish = async (validateForm, setTouched, values) => {
    try {
      setLoading(true);
      await dynamicValidationSchema.validate(values, { abortEarly: false });
    
      setFormData((prevData) => ({ ...prevData, ...values }));
      setAlertInfo({ show: true, severity: 'info',  message: "POS system installation in progress.Please wait.."  });
        try {
                const response = await axios.post(`/ui/install`,values);
                if(response.data.bool){
                    setAlertInfo({ show: true, severity: 'success',  message:response.data.message });
                    setActiveStep(4);
               }else{
                   setAlertInfo({ show: true, severity: 'error',  message:response.data.message });
               }
            } catch (error) {
              setAlertInfo({ show: true, severity: 'error', message:  "Database credentials error : "+error.message });
            }finally {
              setLoading(false);
        }
    } catch (validationErrors) {
      const errors = validationErrors.inner.reduce((acc, error) => {
        acc[error.path] = error.message;
        setAlertInfo({ show: true, severity: 'error',  message: error.message  });
        return acc;
      }, {});
      setTouched(
        Object.keys(errors).reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {})
      );
      setLoading(false);
    }
  };
  const [alertInfo, setAlertInfo] = useState({ show: false, severity: '', message: '' });


  useEffect(() => {
    document.body.style.backgroundImage = "url('./images/install-background-1.png')";
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundPosition = 'center';
    return () => {
      document.body.style.backgroundImage = '';
      document.body.style.backgroundSize = '';
      document.body.style.backgroundPosition = '';
    };
  }, []);



  return (
    <Container maxWidth="lg" style={{ marginTop: '100px' }} className={"install-container"}>
         {alertInfo.show && (<><br/>
            <Alert style={{ backgroundColor: '#f1e5e5', marginTop:'2px'}} variant="outlined" severity={alertInfo.severity} onClose={() => setAlertInfo({ ...alertInfo, show: false })}>
              {alertInfo.message}
            </Alert>
            </>)}
      <Box sx={{ maxWidth: '100%' }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel optional={index === steps.length - 1 ? <Typography variant="caption">Last step</Typography> : null}>
                {step.label}
              </StepLabel>
              <StepContent>
                <Formik
                  initialValues={formData}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    if (index === steps.length - 1) {
                      console.log(values);
                      /////handleFinish(values);
                    } else {
                      handleNext(() => {}, () => {}, values);
                    }
                  }}
                >
                  {({ errors, touched, validateForm, setTouched, values }) => (
                    <Form>
                      <step.component errors={errors} touched={touched} treeData={treeData} setTreeData={setTreeData} />
                      <Box sx={{ mb: 2 }}>
                        <div>

                        <Button
                            type="button"
                            variant="contained"
                            disabled={loading}
                            sx={{ mt: 1, mr: 1 }}
                            onClick={() => {
                              if(index == 2){
                                      handleLicenceCheck(validateForm, setTouched, values);                               
                              }else{
                                if (index === steps.length - 1) {
                                      values.branches = treeData;
                                      handleFinish(validateForm, setTouched, values);
                                  } else {
                                      handleNext(validateForm, setTouched, values)
                                  }
                              }  
                            }}
                            >
                              {loading ? 'Submitting details.. Please wait...' : (index === steps.length - 1 ? 'Finish' : defaultContinue)}
                            {/* {index === steps.length - 1 ? 'Finish' : 'Continue'} */}
                            </Button>
                          <Button
                            disabled={index === 0}
                            onClick={() => handleBack(values)}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            Back
                          </Button>
                        </div>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>All steps completed - you&apos;re finished.</Typography>
            <Typography variant="body1" gutterBottom>
              <Link href="/" underline="hover">Go to your Point of sale Front end</Link>
            </Typography>
            <Typography variant="body1" gutterBottom>
              <Link href="/login" underline="hover">Go to the System admin Back end</Link>
            </Typography>
            <Button onClick={handleReset} variant="outlined" sx={{ mt: 2 }}>Reset</Button>
          </Paper>
        )}
      </Box>
    </Container>
  );
}
