import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import AddCard from '@mui/icons-material/AddCard';
import NotInterested from '@mui/icons-material/NotInterested';
import AccountBalanceWallet from '@mui/icons-material/AccountBalanceWallet';
import axios from 'axios';
import {MenuItem , FormControl ,InputLabel , Select   , Box , TextField , IconButton, Button, Slide } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Delete as DeleteIcon, Undo as UndoIcon } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import SaveAltIcon from '@mui/icons-material/Save';
import * as XLSX from 'xlsx';

import dayjs from 'dayjs';
//import utc from 'dayjs-plugin-utc';
//import timezone from 'dayjs-timezone-iana-plugin';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import  Download  from '@mui/icons-material/Download';

import AttachMoney from '@mui/icons-material/AttachMoney';

const CondensedButton = styled(Button)(({ theme }) => ({
  fontSize: '12px',
  margin: '15px',
  padding: '5px 10px 5px',
  width: 'auto'
}));

const CondensedTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontSize: '18px',
    padding: '4px 8px', 
    height: '30px',    
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '1px',
      textAlign:'right',
    },
  },
  margin: 0,
  '& input': {
    textAlign: 'right',
  },
}));


const CustomDateRangePicker = styled(DateRangePicker)(({ theme }) => ({
  // General DatePicker styling
  '& .MuiInputBase-root': {
    fontSize: '14px',
    padding: '0',
  },
  '& .MuiInputBase-input': {
    padding: '5px 5px',
    textAlign: 'center',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function createDbData(
    id, 
    product_code, 
    product_name, 
    product_garage, 
    product_image, 
    selling_price,
    quantity,
    sub_total
  ) {
    return { 
      id, 
      product_code, 
      product_name, 
      product_garage, 
      product_image, 
      selling_price,
      quantity,
      sub_total 
    };
  }

export default function SalesDialog({ open, onClose , setCartValues ,salesItems,setSalesItemsItems}) {
  const [alertInfo, setAlertInfo] = useState({ show: false, severity: '', message: '' });
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredRows, setFilteredRows] = useState(salesItems);
  const [selectedFields, setSelectedFields] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.post(`/ui/sale-items`, {
        start_date: formatDate(selectedDate[0]),
        end_date: formatDate(selectedDate[1])
      });
      setSalesItemsItems(response.data);
    } catch (error) {
      console.error('Error fetching sale items:', error);
    } finally {
    }
  }

  const removeSpecificChild = () => {
    const dateRangeCalendar = document.querySelector('.MuiDateRangeCalendar-root');
    if (dateRangeCalendar) {
      const children = dateRangeCalendar.childNodes;
      children.forEach(child => {
        if (child.textContent.includes('MUI X Missing license key')) {
          child.remove();
        }
      });
    }
  };

  const today = dayjs().startOf('day');
  const tomorrow = dayjs().add(1, 'day').startOf('day');
  const [selectedDate, setSelectedDate] = useState([today, tomorrow]);

  useEffect(() => {
    fetchData(selectedDate);
  }, [selectedDate]);
  
  useEffect(() => {
    removeSpecificChild();
  }, []);

  useEffect(() => {
    setFilteredRows(salesItems);
  }, [salesItems]);

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = salesItems.filter((row) => {
    return row.product?.product_name.toLowerCase().includes(value);
    })
    setFilteredRows(filtered);
  };

  const handleOpen = () => {
    removeSpecificChild();
  };

  const formatDate = (date) => {
    return date ? dayjs(date).format('YYYY-MM-DD') : '';
  };

  const handleClose = () => {
    //alert('DatePicker closed' + selectedDate);
    //fetchData();
  };

  const handleDateChange = (newValue) => {
    removeSpecificChild();
    setSelectedDate(newValue);  
  };


  const shortcutsItems = [
    {
      label: 'This Week',
      getValue: () => {
        const today = dayjs();
        return [today.startOf('week'), today.endOf('week')];
      },
    },
    {
      label: 'Last Week',
      getValue: () => {
        const today = dayjs();
        const prevWeek = today.subtract(7, 'day');
        return [prevWeek.startOf('week'), prevWeek.endOf('week')];
      },
    },
    {
      label: 'Last 7 Days',
      getValue: () => {
        const today = dayjs();
        return [today.subtract(7, 'day'), today];
      },
    },
    {
      label: 'Current Month',
      getValue: () => {
        const today = dayjs();
        return [today.startOf('month'), today.endOf('month')];
      },
    },
    {
      label: 'Next Month',
      getValue: () => {
        const today = dayjs();
        const startOfNextMonth = today.endOf('month').add(1, 'day');
        return [startOfNextMonth, startOfNextMonth.endOf('month')];
      },
    },
    { label: 'Reset', getValue: () => [null, null] },
  ];
  

  const handleFieldChange = (event) => {
    setSelectedFields(event.target.value);
   };


  const handleExport = () => {
    const data = salesItems.map(row => ({
      'Order Id': row.order_id,
      'Order Date': row.created_at,
      'Product Code': row.product?.product_code,
      'Product Name': row.product?.product_name,
      'Category': row.product?.category?.name,
      'Qty in Store' : row.product?.product_store,
      'Qty Sold' : row.quantity,
      'Selling Price' : row?.unitcost,
      'Total Tax Inc.': row?.total,
      'Supplier' : row.product?.supplier?.name,
      'Buying Price' : row.product?.buying_price,
      'Marked Price' : row.product?.selling_price
    }));
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);  
    XLSX.utils.book_append_sheet(wb, ws, 'Sales Data');
    XLSX.writeFile(wb, 'Sales_data.xlsx');
  };

const columns = [
  { field: 'order_id', headerName: 'Order .#', flex: 0.3 },
  {
    field: 'created_at',
    headerName: 'Transaction Date',
    flex: 1,
    valueFormatter: (params) => {
      return dayjs(params.value).format('DD MMM YYYY HH:mm:ss');
    }
  },
  {
    field: 'productCode',
    headerName: 'Code',
    flex: 0.5,
    renderCell: (params) => params.row.product?.product_code || 'N/A',
  },
  {
    field: 'productName',
    headerName: 'Product',
    flex: 1,
    renderCell: (params) => params.row.product?.product_name || 'N/A',
  },
  {
    field: 'productCategory',
    headerName: 'Category',
    flex: 0.8,
    renderCell: (params) => params.row.product?.category?.name || 'N/A',
  },
  {
    field: 'productStore',
    headerName: 'Qty in Store',
    flex: 0.5,
    renderCell: (params) => params.row.product?.product_store || '0',
  },

  { field: 'quantity', headerName: 'Qty Sold' },

  {
    field: 'unitcost',
    headerName: 'Selling Price',
    flex: 0.5,
    renderCell: (params) => {
      return (
        <div style={{ width: '100%', textAlign: 'right' }}>
          {Number(params?.row?.unitcost).toFixed(2) || '0.00'}
        </div>
      );
    }
  },

  {
    field: 'total',
    headerName: 'Total Inc.',
    flex: 0.5,
    renderCell: (params) => {
      return (
        <div style={{ width: '100%', textAlign: 'right' }}>
          {Number(params?.row?.total).toFixed(2) || '0.00'}
        </div>
      );
    }
  },

  {
    field: 'SupplierName',
    headerName: 'Supplier',
    flex: 1,
    renderCell: (params) => params.row.product?.supplier?.name || 'N/A',
  },

  {
    field: 'productBPrice',
    headerName: 'Buying Price',
    flex: 0.5,
    renderCell: (params) => {
      return (
        <div style={{ width: '100%', textAlign: 'right' }}>
          {Number(params.row.product?.buying_price).toFixed(2) || 'N/A'}
        </div>
      );
    }
  },
  {
    field: 'productMPrice',
    headerName: 'Marked Price',
    flex: 0.5,
    renderCell: (params) => {
      return (
        <div style={{ width: '100%', textAlign: 'right' }}>
          {Number(params.row.product?.selling_price).toFixed(2) || 'N/A'}
        </div>
      );
    }
  },
];


  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{
        '& .MuiDialog-paper': {
          width: '80%',
          maxWidth: 'none',
        }
      }}
      keepMounted
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
        },
      }}
    >
     <DialogTitle>
        <Box display="flex" alignItems="center">
          <AttachMoney style={{ marginRight: '8px' }} />
          Sales
        </Box>
      </DialogTitle>
      <DialogContent>
        {alertInfo.show && (
                <Alert variant="outlined" severity={alertInfo.severity} onClose={() => setAlertInfo({ ...alertInfo, show: false })} style={{marginBottom:'1%'}}>
                {alertInfo.message}
                </Alert>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['SingleInputDateRangeField']}>
                        <CustomDateRangePicker
                        startText="Start Date"
                        endText="End Date"
                        slots={{ field: SingleInputDateRangeField }}
                        slotProps={{
                            shortcuts: {
                            items: shortcutsItems,
                            },
                            actionBar: { actions: [] },
                            field: {
                              sx: {
                                '& .MuiInputBase-root': {
                                  fontSize: '14px',
                                },
                                '& .MuiInputBase-input': {
                                },
                              },
                            },
                        }}
                        calendars={2}
                        name="allowedRange"
                        value={selectedDate}
                        onClick={(e) => removeSpecificChild()}
                        onChange={handleDateChange}
                        onOpen={handleOpen}
                        onClose={handleClose}
                        renderInput={(startProps, endProps) => (
                          <>
                            <TextField {...startProps} />
                            <TextField {...endProps} />
                          </>
                        )}
                        />
                    </DemoContainer>
                </LocalizationProvider>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <TextField
                      label="Search"
                      variant="outlined"
                      size="small"
                      value={searchTerm}
                      onChange={handleSearch}
                    />
                    <FormControl variant="outlined" size="small" style={{minWidth:'150px'}}>
                        <InputLabel id="fields-select-label">Fields</InputLabel>
                        <Select
                        labelId="fields-select-label"
                        multiple
                        value={selectedFields}
                        onChange={handleFieldChange}
                        label="Fields"
                        >
                        {columns.map((column) => (
                            <MenuItem key={column.field} value={column.field}>
                            {column.headerName}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    <Button variant="contained" startIcon={<Download />} onClick={handleExport}>
                        Export
                    </Button>
                </Box>
        </Box>
        <DataGrid
          rows={filteredRows}
          columns={columns.filter((column) => selectedFields.length === 0 || selectedFields.includes(column.field))}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10]}
          rowHeight={30}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" startIcon={<NotInterested />} onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
